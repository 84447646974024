
import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Agent_Login.css';
import Image2 from './img/Image2.png'
import { useNavigate } from 'react-router-dom';
const Agent_Login = ({ isloggedIn }) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { loggedin, setloggedin } = useState(false);
    const [emailError, setEmailError] = useState('');
    const [ErrorMsg, SetErroMsg] = useState("");
    // const handleLogin = () => {

    //     console.log('Login clicked with email:', email, 'and password:', password);
    //    isloggedIn('true')
    //    navigate('/');
    //    sessionStorage.setItem('isloggedin','true')
    // };
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleLogin = async () => {
        // isloggedIn('true')
        // navigate('/');
        // sessionStorage.setItem('isloggedin','true')
        console.log('Login clicked with email:', email, 'and password:', password);
        if (!sessionStorage.getItem('agentID')) {
            if (!validateEmail(email)) {
                setEmailError('*Please enter a valid email address.');
                return;
            }
            try {
                const response = await fetch('https://www.mypropertypal.in/administrator/postlogin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, password }),
                });
                const data = await response.json();
                console.log('Response:', data);

                if (data.message === 'Login Successful.') {
                    sessionStorage.setItem('agentID', data.profiledet.agentId);
                    // You can also set other data to sessionStorage if needed
                    //  alert("loggedin ");
                    // alert(data.profiledet.agentId)
                    console.log('Login clicked with email:', email, 'and password:', password);
                    isloggedIn('true')
                    navigate('/');
                    sessionStorage.setItem('isloggedin', 'true')

                } else {
                    // Handle other responses like incorrect password or email not found
                    // console.error('Login Failed:', data.message);
                    // alert(`Login Failed:', ${data.message}`);
                    // SetErroMsg(`*${data.message}`)
                    SetErroMsg(`*Couldn't find your MyPropertyPal Acc \n Please double-check try again.`);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            alert('already logged in')
        }
    };


    const handleForgotPassword = () => {
        console.log('Forgot Password clicked');
        navigate('/Agent_forgot')
    };

    const handleSignUp = () => {
        navigate('/Sign_Up');
    };
    // useEffect(() => {

    //   }, [loggedin]); 

    return (
        <div className='Agent_signup' style={{ backgroundImage: `url(${Image2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
            <div className="userlogin_form">
                <h1>Consultant Login</h1>
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError(''); // Clear email error
                        SetErroMsg(''); // Clear general error message
                    }}
                    className="input-field-2"

                />
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field-2"

                />

                <div className='text-left'>
                    <p onClick={handleForgotPassword}>Forgot Password?</p>
                </div>
                <button onClick={handleLogin} className="find_button button-3-padding">
                    Login
                </button>
                {ErrorMsg && (
                    <div style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}>
                        {ErrorMsg.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                )}


                <div className="signup">
                    <p>Not a member yet?</p>
                    <p style={{ cursor: 'pointer' }} onClick={handleSignUp} className="signup-link">Sign Up</p>
                </div>
            </div>
        </div>
    );
};




export default Agent_Login;
