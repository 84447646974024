// Contact.js
import React from "react";
import { useRef, useState, useEffect } from "react";
import "./Home.css";
import location from "./img/location.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import agent1 from "./img/agent1.png";
import agent2 from "./img/agent2.png";
import agent3 from "./img/agent3.png";
import agent4 from "./img/agent4.png";
import agent5 from "./img/agent5.png";
import bgdiv from "./img/bgdiv.png";
import carous1 from "./img/carous1.png";
import carous2 from "./img/carous2.png";
import carous3 from "./img/carous3.png";
import carous4 from "./img/carous4.png";
import search from "./img/search.svg";

import topic1 from "./img/topic1.png";
import expbg from "./img/expbg.png";
import ruppe from "./img/ruppe.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const Home = ({ jsonData }) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("city");

  const [selectedCategory, setSelectedCategory] = useState("category");
  const [carousel_data, setcarousel_data] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [fetchedreviews, setfetchedreviews] = useState([]);
  const [cities, setcities] = useState([]);
  const [agents, setagents] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");


  var type1 = location.state?.consultationType;
  var city = location.state?.city;
  console.log(location)
  const [agentsdata, setagentsdata] = useState([]);
  console.log(type1, 'type')
  useEffect(() => {
    console.log("on fetch data")
    const post = {
      "cityname": city,
      "consid": type1
    }
    const fetchData = async () => {

      try {
        const response = await fetch('https://www.mypropertypal.in/administrator/findagents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(post),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData, 'jsondata_location_agents')
        if (jsonData.length > 0)
          setagentsdata(jsonData)

      } catch (error) {
        console.error('Error fetching data:', error);

      }
    };



    fetchData();

  }, [type1]);

  const handleSelectChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedOption(selectedCity);

    // Clear error message if both category and city are selected
    if (selectedCity !== "city" && selectedCategory !== "category" && selectedCategory) {
      setErrorMessage("");
    }
  };

  const handleSelectCategory = (e) => {
    const selectedCat = e.target.value;
    setSelectedCategory(selectedCat);
    // Clear error message if both category and city are selected
    if (selectedOption !== "city" && selectedOption && selectedCat !== "category") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    console.log("on fetch data");
    const fetchreviews = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/listuserreviews"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData.userreviews, "jsondata");
        if (jsonData) {
          setfetchedreviews(jsonData.userreviews);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchreviews();
    // const fetchcategories = async () => {

    //   try {
    //     const response = await fetch('https://www.mypropertypal.in/administrator/ator/listcategory/');
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     const jsonData = await response.json();
    //     console.log(jsonData.categories,'jsondata')
    //     setcarousel_data(jsonData.categories)

    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };

    // fetchcategories();
    const fetchagents = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/ator/ator/homeagents"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData.userreviews, "jsondata");
        if (jsonData) {
          setagents(jsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchagents();
    
    const fetchblogs = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/getblogs"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();

        if (jsonData) setblogs(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchblogs();

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/homecities"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setcities(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setcarousel_data(jsonData);
  }, [jsonData]);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleCardHover = (index) => {
    setHoveredIndex(index);
  };

  const handleCardLeave = () => {
    setHoveredIndex(null);
  };
  console.log(fetchedreviews, "reviews");
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const words = ["Real Estate", "Property Purchase", "Legal Property Advisor"];

  const handlesearch = () => {
    if (!selectedCategory || selectedCategory === "category" || !selectedOption || selectedOption === "city") {
      setErrorMessage("*Please select both a city and a category to proceed.");
    } else {
      setErrorMessage(""); // Clear any previous error message
      navigate("/location_agent", {
        state: { consultationType: selectedCategory, city: selectedOption },
      });
    }
  };

  function truncateWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }
  const [userInput, setUserInput] = useState("");
  const [suggestedWords, setSuggestedWords] = useState([
    "apple",
    "banana",
    "cherry",
  ]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");

  const handleInputChange = (event) => {
    const input = event.target.value;
    setUserInput(input);

    // Filter suggested words based on the input
    const filteredWords = suggestedWords.filter((word) =>
      word.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestedWords(filteredWords);
  };

  const handleSuggestionClick = (word) => {
    setUserInput(word);
    setSelectedSuggestion(word);
    setSuggestedWords([]);
  };
  return (
    <div className="parent div">
      <div
        className="=imgdiv"
        style={{
          backgroundImage: `url(${bgdiv})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="wrapper-1">
          <div className="home-sec-1-wrap">
            <div className="home-sec-1-1">
              <h3>
                <span>Query Regarding</span>
                Land,Apartment,
                <br />
                Selling or Renting?
              </h3>
              <button>Consult Today</button>
            </div>
            <div className="home-sec-1-2">
              <div className="home_container">
                {/* <Carousel responsive={responsive}>
                  {carousel_data.map((item) => (
                    <div key={item.id} className="category_grid-item">
                      <div className="category_image-container">
                        <img
                          src={`https://www.mypropertypal.in/administrator/uploads/${item.consultingImage}`}
                          alt={`Image ${item.id}`}
                          className="category_img"
                        />
                      </div>
                      <Link
                        to="/category_agent"
                        state={{
                          consultationType: item.id,
                          category: item.consultingType,
                        }}
                      >
                        <div
                          className="word-container"
                          style={{ margin: "0px" }}
                        >
                          <p>{item.consultingType}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel> */}

                <Carousel responsive={responsive} >
                  {carousel_data.map((item) => (
                    <div key={item.id} className="category_grid-item" >
                      <div className="category_image-container" >
                        <img src={`https://www.mypropertypal.in/administrator/uploads/${item.consultingImage}`} alt={`Image ${item.id}`} className="category_img" />
                      </div>
                      <Link to={`/category/${item.id}`} state={{ consultationType: item.id, category: item.consultingType }}>
                        <div className="word-container" style={{ margin: "0px" }}>
                          <p style={{ fontSize: "18px", textDecoration: "underline", fontStyle: "italic" }}>{item.consultingType}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        {/* maincontainer ends */}
        <div className="topagent">
          <div className="wrapper-1">
            <h2>Book an online appointment with out expert Consultants</h2>

            <div className="top-agents-p-main">
              {agents.map((item) => (
                <div key={item.id} className="top-agents-p-1">
                  <div className="profile-img-1-1"><img
                    src={`https://www.mypropertypal.in/administrator/uploads/${item.profilePic}`}
                    alt={`Image ${item.id}`}
                    className="img"
                  />
                  </div>
                  <h4>{item.agentfName} {item.agentlName}</h4>
                  <h6>{item.areaPreference} </h6>
                  {/* <h5>{item.membertype}   555</h5>
                <h6>111{item.cityname}</h6> */}
                  {/* <p><img src={ruppe} alt="ruppe" /> {item.consultingFee}</p> */}


                  {/* <Link to= '/booking' state= {{ agent:item.agentId,fee:item.consultingFee,categoryid:type1 }} >
            <button>Consult Now</button>
          </Link> */}


                </div>
              ))}
            </div>
          </div>






        </div>
      </div>
      {/* bgdiv ends */}
      <div className="find_div">
        <div className="wrapper-1">
          <div className="find_border">
            <h2>Find Consultants</h2>

            <div className="find-agents-box-wrap">
              <div className="find-agents-box-1">
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  className="location-icon"
                >
                  <option value="city" disabled hidden>
                    Select City
                  </option>
                  {cities.map((value) => (
                    <option key={value.city_id} value={value.city_name}>
                      {value.city_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="find-agents-box-2">
                <select
                  id="dropdown"
                  value={selectedCategory}
                  onChange={handleSelectCategory}
                  className="search-icon"
                >
                  <option value="category" disabled hidden>
                    Select Category
                  </option>
                  {carousel_data.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.consultingType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="find-agents-box-3">
                <button onClick={handlesearch} className="button-a-1">
                  Search
                </button>
              </div>
            </div>
            {errorMessage && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {errorMessage}
              </div>
            )}

            {/* <div className="find_secdiv">
            <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "5%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                style={{ border: "none" }}
              >
                <option value="city" disabled hidden>
                  Select City
                </option>
                {cities.map((value) => (
                  <option key={value.city_id} value={value.city_name}>
                    {value.city_name}
                  </option>
                ))}
              </select>
            </div>
           <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "5%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedCategory}
                onChange={handleSelectCategory}
                style={{ border: "none" }}
              >
                <option value="category" disabled hidden>
                  Select Category
                </option>
                {carousel_data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.consultingType}
                  </option>
                ))}
              </select>
            </div>
          
            <div className="find_buttondiv">
              <button onClick={handlesearch} className="find_button">
                Search
              </button>
            </div>
           
          </div>
          */}

            <div className="find_thirddiv">
              <p className="find_hint">
                Popular Searches:{" "}
                {words.map((word, index) => (
                  <React.Fragment key={index}>
                    <span>{word}</span>
                    {index !== words.length - 1 && <span> </span>}
                  </React.Fragment>
                ))}{" "}
                Etc..
              </p>
            </div>
            {/* find third div */}
          </div>
        </div>
        {/* find border */}
      </div>
      {/*  find_div */}

      <div className="topics">
        <h2>Read Topics from Experts</h2>
        <div className="topic_secdiv">
          <Carousel responsive={responsive}>
            {blogs.map((item) => (
              <div key={item.postId} className="topic_grid_item">
                <div className="topic_image_container">
                  <img
                    src={`https://www.mypropertypal.in/administrator/uploads/${item.postImage}`}
                    alt={`Image ${item.id}`}
                    className="topic_img"
                  />
                </div>
                <div className="topic_word_container">
                  <div className="topic_heading">
                    <h6>{item.postTitle}</h6>
                  </div>
                  <div className="topic_text">
                    <p>{truncateWords(item.postContent, 50)}</p>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        {/* <Link to={`/blog`}>
          <div className="topic_buttondiv">
            <button className="button-a-2">
              View All
            </button>
          </div>
        </Link> */}
      </div>
      {/* topics div */}

      <div className="experiences-bg-1">
        <div className="wrapper-1">
          <div className="real-experiences-wrap">
            <div className="real-experiences-1">
              <h2>
                Real Experiences with <br /> MypropertyPal
              </h2>
              <p>
                Embark on a real estate journey that’s backed by success
                stories. Here’s what our clients have to say about their
                experiences with MypropertyPal.{" "}
              </p>
            </div>
            <div className="real-experiences-2">
              {fetchedreviews && (
                <div>
                  {fetchedreviews.map((review) => (
                    <div className="card-wrap">
                      <div className="profile-photo-wrap">
                        <div className="profile-photo-1">
                          <img
                            src={`https://www.mypropertypal.in/administrator/uploads/${review.userpic}`}
                            alt={`Profile ${review.username}`}
                          />
                        </div>
                        <div className="profile-photo-2">{review.username}</div>
                      </div>
                      <p>{truncateWords(review.review, 15)}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="exp"
        style={{
          backgroundImage: `url(${expbg})`,

          wdith: "100%",
          height: "100%",
          backgroundSize: "cover",

          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="exp_leftdiv">
          <div className="exp_heading" style={{ marginBottom: "5%" }}>
            <p style={{ fontSize: "36px", fontFamily: "poppins_reg" }}>
              {" "}
              Real Experiences with <br />
              MypropertyPal
            </p>
          </div>
          <div className="exp_text" style={{ marginBottom: "5%" }}>
            <p style={{ fontSize: "16px", fontFamily: "poppins_reg" }}>
              Embark on a real estate journey that’s backed by success stories.
              Here’s what our clients have to say about their experiences with
              MypropertyPal.
            </p>
          </div>
          <div className="exp_button">
           
          </div>
        </div>
      
        {fetchedreviews && (
          <div className="exp_rightdiv">
            {fetchedreviews.map((review) => (
              <div
                key={review.reviewId}
                className={`card ${hoveredIndex === review ? "hovered" : ""}`}
                onMouseEnter={() => handleCardHover(review)}
                onMouseLeave={handleCardLeave}
              >
                <div className="profile-info">
                  <img
                    src={`https://www.mypropertypal.in/administrator/uploads/${review.userpic}`}
                    alt={`Profile ${review.username}`}
                  />
                  <p style={{ fontSize: "18px", fontFamily: "poppins_b" }}>
                    {" "}
                    {review.username}
                  </p>
                </div>
                <div className="additional-info">
                  <p>{truncateWords(review.review, 15)}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div> */}
      {/* exp div ends */}
    </div>
    // parentdiv ends
  );
};

export default Home;
