// import React, { useEffect, useState } from "react";

// export default function Cities() {
//   const [dumdata] = useState({
//     agentid: '2',
//     cityname: 'Jagtial',
//     cityid: '15'
//   });

//   console.log("Component Rendered with dumdata:", dumdata);

//   useEffect(() => {
//     console.log("useEffect called with dumdata:", dumdata);

//     const apiUrl = "https://www.mypropertypal.in/administrator/postcities";
//     const options = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json"
//       },
//       body: JSON.stringify(dumdata)
//     };

//     console.log("Options for fetch:", options);

//     const saveData = async () => {
//       try {
//         const response = await fetch(apiUrl, options);
//         console.log("Fetch response:", response);
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         console.log("Response data:", data);
//         if (data) {
//           console.log("Cities updated successfully");
//         } else {
//           console.log("Cities not updated successfully");
//         }
//       } catch (error) {
//         console.error("Error saving data:", error);
//       }
//     };

//     saveData();
//   }, []); // Empty dependency array means this useEffect will run once on mount

//   return (
//     <div className='wrapper-2'>
//       <h1>Serviceable Cities</h1>
//       <div className='serviceablecities'>
//         <h1>{JSON.stringify(dumdata)}</h1>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect } from 'react';

// Define the Component
export default function Cities() {
  const [data, setdata] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCityids, setSelectedCityids] = useState([]);
  const [selectedCityid, setSelectedCityid] = useState('');
  const [selectedCities, setSelectedCities] = useState([]);
  const [postids, setPostids] = useState([]);
  const agentid = sessionStorage.getItem('agentID');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.mypropertypal.in/administrator/getstates');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setdata(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchSelectedCities = async () => {
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/agentcities/${agentid}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();

        const cities = jsonData.mycities || [];
        const cityIds = cities.map(city => city.cityid);

        setSelectedCities(cities.map(city => city.cityname));
        setSelectedCityids(cityIds);
        setPostids(cityIds);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSelectedCities();
  }, [agentid]);

  // Dummy data for Indian states and cities
  const indianStates = Object.keys(data);

  // Event handler to handle state selection
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  // Event handler to handle city selection
  const handleCityChange = (event) => {
    const { id, name } = JSON.parse(event.target.value);
    setSelectedCity(name);
    setSelectedCityid(id);
  };

  const handlesave = async () => {
    const postdata = selectedCities.map((city, index) => ({
      agentid: agentid,
      cityname: city,
      cityid: postids[index]
    }));

    //alert(postdata.cityname)
    console.log('postdata', postdata);

    const apiUrl = "https://www.mypropertypal.in/administrator/postcities";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(postdata)
    };

    console.log('options', options);

    try {
      const response = await fetch(apiUrl, options);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("data:", data);
      if (data) {
        console.log("Cities updated successfully");
      } else {
        console.log("Cities not updated successfully");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Event handler to add city to selected cities
  const handleAddCity = () => {
    if (selectedCity && !selectedCities.includes(selectedCity)) {
      setSelectedCities([...selectedCities, selectedCity]);
      setPostids([...postids, selectedCityid]);
      setSelectedCityids([...selectedCityids, selectedCityid]);
    }
  };

  // Event handler to remove city from selected cities
  const handleRemoveCity = (city) => {
    setSelectedCities(selectedCities.filter(c => c !== city));
    console.log(selectedCityids[selectedCities.indexOf(city)])
    const removeData = async () => {
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/deleteagentcity/${selectedCityids[selectedCities.indexOf(city)]}/${agentid}`, {
          headers: {
            'Content-Type': 'application/json'
          },
          // Your request body here
        });
        const data1 = await response.json();
        console.log('resposnse from post', data1)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    removeData();
  };

  return (
    <div className='wrapper-2'>
      <h1>Serviceable Cities</h1>

      <div className='serviceablecities'>
        <div className='serviceablecities-main'>
          <select
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="">Select State</option>
            {indianStates.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
          <select
            value={JSON.stringify({ id: selectedCityid, name: selectedCity })}
            onChange={handleCityChange}
          >
            <option value="">Select City</option>
            {data[selectedState] && data[selectedState].map(city => (
              <option key={city.city_id} value={JSON.stringify({ id: city.city_id, name: city.city_name })}>{city.city_name}</option>
            ))}
          </select>
          <button
            onClick={handleAddCity}
            disabled={!selectedCity || selectedCities.includes(selectedCity)}
          >
            Add City
          </button>
        </div>
        {selectedCities &&
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '1rem' }}>
            {selectedCities.map((city, index) => (
              <Badge key={index} onRemove={() => handleRemoveCity(city)}>
                {city}
              </Badge>
            ))}
          </div>
        }
        <div style={{ borderTop: '1px solid #ccc', paddingTop: '1rem', marginTop: '5%' }}>
          <button onClick={handlesave} className='find_button' style={{ marginTop: '1%', marginBottom: '2%' }}>Save</button>
        </div>
      </div>
    </div>
  );
}

// Badge Component
function Badge({ children, onRemove }) {
  return (
    <div style={{ background: '#ffffff', color: '#233B58', border: '1px solid #707070', padding: '0.25rem 0.75rem', borderRadius: '1rem', display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '0.25rem' }}>{children}</span>
      <button onClick={onRemove} style={{ background: 'none', border: 'none', color: 'black', cursor: 'pointer' }}>
        &#x2715;
      </button>
    </div>
  );
}

