import React, { useState, useMemo,useEffect } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import './feedback.css';
import {format} from 'date-fns'
import up_down from './img/up&down.svg'
const CardTable = ({ columns, data }) => {
  const[sort,setsort]=useState(false);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    state: { pageIndex,pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0,  pageSize: 5 , },
     
    },
    useSortBy,
    usePagination
  );

  const totalPages = pageOptions.length;
const handlesort =()=>{
  setsort(true);
  if(sort)
  setsort(false)
}
const renderPageButtons = () => {
  let startPage = Math.max(0, pageIndex - 2);
  let endPage = Math.min(totalPages - 1, pageIndex + 2);

  if (endPage - startPage < 4) {
    if (startPage === 0) {
      endPage = Math.min(4, totalPages - 1);
    } else {
      startPage = Math.max(0, totalPages - 5);
    }
  }

  return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
};
  return (
   <div className="fb_container">
  <div className="fb_sort-section">
    <div className='sort-main-wrap'>
      <div className='sort-main-1'><h2>Feedbacks & Rating</h2></div>
      <div className='sort-main-2'>

      <div className='fb_sort'>
    <div className="fb_cards-list1">
    
    <div className='sort-btn'  onClick={handlesort}>
        sort by
      </div>
     
      {sort &&
      <div className='feedbacks-rating'>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <div
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                
                  display: 'flex',
                
                  
                
                  border: 'none',
                  padding: '0px',
                  
                  fontSize:'14px'
               
              
             
                }}
              >
                {column.render('Header')}
                {column.id ==="date" ? 
               ( <span>{column.isSorted ? (column.isSortedDesc ? '🔽 newer first' : '🔼 older first') : < img src={up_down} style={{ marginLeft: '5px' }}/>}</span>)
               :( <span>{column.isSorted ? (column.isSortedDesc ? '🔽 higher rating' : '🔼 lower rating') : < img src={up_down} style={{ marginLeft: '5px' }}/>}</span>)}
              </div>
            ))}
          </div>
        ))}
      </div>
}
    </div>
    </div>

      </div>

    </div>
   
    
    {/* Move the table headers inside the fb_sort-section */}
    
  </div>
  <div className='fb_card-section'>
    <table {...getTableProps()} className="fb_cards-list">
      <tbody {...getTableBodyProps()} style={{ width: '100%' }}>
        {page.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="fb_rows">
              <td className="fb_card-content"  >
                <div className="fb_card">
                  <div className="fb_card-header">
                    <div className="fb_avatar">
                      <img className="fb_avatar-image" alt={row.original.name} src={'https://randomuser.me/api/portraits/men/1.jpg'} />
                      <div className="fb_avatar-fallback">{row.original.avatarFallback}</div>
                    </div>
                    <div>
                      <p className="fb_card-title">{row.original.title}</p>
                      <p className="fb_card-date">{row.cells[0].render('Cell')}</p>
                    </div>
                    <div className="fb_rating-section">
                      {[...Array(5)].map((_, i) => (
                        <span key={i} className={`fb_star-icon ${i < row.original.rating ? 'yellow' : 'gray'}`}>★</span>
                      ))}
                    </div>
                  </div>
                  <div className="fb_card-content">
                    <div className="fb_card-description">{row.original.description}</div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>

  <div className='fb_pagination'>
        <div>
          <span>
            Showing{' '}
            <strong>
              {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length}
            </strong>{' '}
          </span>
        </div>
        <div>
          <button onClick={() => previousPage()} disabled={!canPreviousPage || pageIndex === 0} className="fb_page-btn prev-next">
            Prev
          </button>
          <button onClick={() => gotoPage(0)} disabled={pageIndex === 0} className="fb_page-btn min-max">
            {'<<'}
          </button>
          {renderPageButtons().map(pageNumber => (
            <button
              key={pageNumber}
              onClick={() => gotoPage(pageNumber)}
              className={`fb_page-btn ${pageIndex === pageNumber ? 'active' : ''}`}
            >
              {pageNumber + 1}
            </button>
          ))}
          <button onClick={() => gotoPage(totalPages - 1)} disabled={pageIndex === totalPages - 1} className="fb_page-btn min-max">
            {'>>'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage || pageIndex === totalPages - 1}
            className="fb_page-btn prev-next"
          >
            Next
          </button>
        </div>
      </div>
 {/* fb_pagination ends */}





  
    </div>
  );
};

const App = () => {
  const [fetchedData,setfetchedData]=useState(null);
  
  useEffect(() => {
    console.log("on fetch data")
    console.log(sessionStorage.getItem('agentId'))
    const fetchData = async () => {
     
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/listreviews/${sessionStorage.getItem('agentID')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData,'jsondata')
        setfetchedData(jsonData)
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
   

    fetchData();
 
  }, []);
  
  useEffect(() => {
    generateData();
    console.log('got data')
    generateData();
 
  }, [fetchedData]);
  
  // console.log(fetchedData)
  function getRandomDate(startDate, endDate) {
    const start = startDate.getTime();
    const end = endDate.getTime();
    const randomTime = start + Math.random() * (end - start);
    const randomDate = new Date(randomTime);
  
    return randomDate;
  }
  
  const startDate = new Date('2022-01-01');
  const endDate = new Date('2022-12-31');
  console.log(getRandomDate(startDate, endDate).toISOString())
  const generateData = count => {
    const data = [];
    // for (let i = 1; i <= count; i++) {
    //   data.push({
    //     title: `Card ${i}`,
    //     description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ${i}`,
    //     date: getRandomDate(startDate, endDate).toISOString(),
    
    //     rating: Math.floor(Math.random() * 5) + 1,
    //   });
    // }
    // return data;
 if(fetchedData !==null){
    return (fetchedData?.myreviews).map(review => ({
      title: `${review.userFname} ${review.userLname}`,
      description: review.review,
      date: new Date(review.addedOn).toISOString(),
      rating: parseFloat(review.agentrating),
      userId: review.userid
    }));
  }
  };
  // console.log(fetchedData)
  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        Footer:'Date',
        accessor: 'date',
       Cell  :({ value }) => {
          const date = new Date(value);
          return format(date, 'dd/MM/yyyy');
        },
      },
      {
        Header: 'Rating',
        accessor: 'rating',
      },
    ],
    []
  );



  const data = useMemo(() => {
    if (fetchedData) {
      return generateData(50);
    }
    return [];
  }, [fetchedData]);
  
  if (fetchedData) {
    return <CardTable columns={columns} data={data} />;
  }
}


export default App;
