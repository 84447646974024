import React, { useState, useEffect } from "react";
import "./Agent_Consultation.css";
import edit from "./img/edit.svg";
import delete1 from "./img/delete.svg";
import close from "./img/close.svg";
import ruppe from "./img/ruppe.png";
function Agent_Consultations() {
  const [consultations, setConsultations] = useState([
    // { id: 1, type: 'Real Estate', fee: '500', status: true, editing: false },
    // { id: 2, type: 'Home/Villa', fee: '500', status: true, editing: false },
    // ... other rows
  ]);
  const [consultingTypes, setConsultingTypes] = useState([]);
  const [consultings, setConsultings] = useState([]);
  const [newConsultation, setNewConsultation] = useState({
    type: "",
    fee: "",
    status: "active",
    consid: "",
  });
  useEffect(() => {
    const fetchConsultingTypes = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/consultingtypes"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        setConsultingTypes(data.consultingtypes);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchConsultingTypes();
    const fetchConsulting = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/agentconsultings/${sessionStorage.getItem(
            "agentID"
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.myconsultings) {
          const transformedData = data.myconsultings.map((item) => ({
            id: item.consId,
            type: item.consultingType,
            fee: item.consultingFee,
            status: item.conStatus,
          }));

          setConsultations(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchConsulting();
  }, []);

  const [isAdding, setIsAdding] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [consultationToDelete, setConsultationToDelete] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setNewConsultation((prev) => ({ ...prev, [name]: value }));
  };
  const handlecatInputChange = (event) => {
    const id = JSON.parse(event.target.value);
    //  console.log(event.target)
    console.log(id);

    setNewConsultation((prev) => ({
      ...prev,
      type: id.consultingType,
      consid: id.id,
    }));
  };
  // console.log(newConsultation)
  function getConsultingTypeId(consultingType) {
    const type = consultingTypes.find(
      (type) => type.consultingType === consultingType
    );
    return type ? type.id : null;
  }
  const handleAddConsultation = () => {
    const newId =
      consultations.length > 0
        ? parseInt(consultations[consultations.length - 1].id) + 1
        : 1;
    const isActive = newConsultation.status === "active";
    const newConsultationItem = {
      id: newId,
      type: newConsultation.type,
      fee: newConsultation.fee,
      status: isActive,
      editing: false,
    };
    const postdata = {
      consultid: getConsultingTypeId(newConsultation.type),
      // consulttype:newConsultation.type,
      agentid: sessionStorage.getItem("agentID"),
      consultfee: newConsultation.fee,
      // consultstatus:newConsultation.status
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/postconsulting",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postdata), // Your request body here
          }
        );
        const data1 = await response.json();
        console.log("resposnse from post", data1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    setConsultations([...consultations, newConsultationItem]);

    setIsAdding(false);
    setEditingRow(null);
    setEditingRow("new");
  };

  const handleEditStatus = (id) => {
    setConsultations((prev) =>
      prev.map((consultation) =>
        consultation.id === id
          ? { ...consultation, status: !consultation.status }
          : consultation
      )
    );
  };

  const handleEditConsultation = (id) => {
    setEditingRow(id);
    setNewConsultation({
      type: consultations.find((c) => c.id === id)?.type || "",
      fee: consultations.find((c) => c.id === id)?.fee || "",
      status: consultations.find((c) => c.id === id)?.status
        ? "active"
        : "inactive",
    });
  };

  const handleSaveConsultation = (id) => {
    setConsultations((prev) =>
      prev.map((consultation) =>
        consultation.id === id
          ? {
              ...consultation,
              type: newConsultation.type,
              fee: newConsultation.fee,
              status: newConsultation.status === "active",
              editing: false,
            }
          : consultation
      )
    );
    const handleedit = async () => {
      const post = {
        consid: id,
        consultfee: newConsultation.fee,
      };

      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/updateconsultings`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(post),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const data = await response.json();
        console.log("Data edited  successfully:", data);
        if (data.message) {
          alert(data.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };
    handleedit();
    // setNewConsultation({ type: '', fee: '', status: 'active' }); edited
    setEditingRow(null);
  };

  const handleCancelEdit = () => {
    setNewConsultation({ type: "", fee: "", status: "active" });
    setEditingRow(null);
    setIsAdding(false);
  };

  const handleDeleteConsultation = (id) => {
    setConsultationToDelete(id);
    setShowDeleteAlert(true);
  };

  const handleDeleteConsultationConfirm = () => {
    setConsultations((prev) =>
      prev.filter((consultation) => consultation.id !== consultationToDelete)
    );
    setShowDeleteAlert(false);
  };

  // /////////////////////////////////////////////////
  // const handleDeleteConsultationConfirm = async () => {
  //   // Call the backend API to delete the consultation
  //   try {
  //     const response = await fetch(
  //       `https://www.mypropertypal.in/administrator/deleteconsulting/${consultationToDelete}`,
  //       {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to delete consultation");
  //     }
  //     const data = await response.json();
  //     console.log("Consultation deleted:", data);

  //     // Update the local state after successful deletion
  //     setConsultations((prev) =>
  //       prev.filter((consultation) => consultation.id !== consultationToDelete)
  //     );
  //   } catch (error) {
  //     console.error("Error deleting consultation:", error);
  //   }
  //   setShowDeleteAlert(false);
  //   setConsultationToDelete(null); // Clear the consultation to delete
  // };

  // const handleCancelDelete = () => {
  //   setShowDeleteAlert(false);
  // };
  const handleCancelDelete = () => {
    setShowDeleteAlert(false);
    setConsultationToDelete(null);
  };


  return (

<div className="wrapper-2 d-heading-1">
<h1>Consultations</h1>


<div className="add-consultation-container">
        {isAdding ? (
          <>
            {/* <input
              type="text"
              placeholder="Consultation Type"
              name="type"
              value={newConsultation.type}
              onChange={handleInputChange}
            /> */}
            <select
              name="type"
              value={newConsultation.type}
              onChange={handleInputChange}
              className="edit-input"
              style={{ width: "30%", height: "100%", border: "1px solid #ccc" }}
            >
              <option value="">Select Consulting Type</option>
              {consultingTypes.map((type) => (
                <option key={type.id} value={type.consultingType}>
                  {type.consultingType}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Per Consultation Fee"
              name="fee"
              value={newConsultation.fee}
              onChange={handleInputChange}
            />
            <select
              name="status"
              value={newConsultation.status}
              onChange={handleInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
            <button
              className="action-button save"
              onClick={handleAddConsultation}
            >
              Save
            </button>
            <button className="action-button cancel" onClick={handleCancelEdit}>
              <img src={close} alt="Cancel" />
            </button>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <button className="find_button" onClick={() => setIsAdding(true)}>
              Add New
            </button>
          </div>
        )}
      </div>



      <div className="table-container">
        <table className="consultations-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Consultation Type</th>
              <th>Per Consultation Fee</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {consultations.map((consultation, index) => (
              <tr key={consultation.id}>
                <td>{consultation.id}</td>
                <td>
                  {editingRow === consultation.id
                    ? // <input
                      //   type="text"
                      //   name="type"
                      //   value={newConsultation.type}
                      //   onChange={handleInputChange}
                      //   placeholder={consultation.type}
                      //   className="edit-input"
                      // />
                      //                        <select
                      //   name="type"
                      //   value={newConsultation.type}
                      //   onChange={handleInputChange}
                      //   className="edit-input"

                      // >
                      // <option value="">{consultation.type}</option>
                      //       {consultingTypes.map(type => (
                      //         <option key={type.id} value={type.consultingType}>
                      //           {type.consultingType}
                      //         </option>
                      //       ))}
                      //     </select>
                      consultation.type
                    : consultation.type}
                </td>
                <td>
                  {editingRow === consultation.id ? (
                    <input
                      type="text"
                      name="fee"
                      value={newConsultation.fee}
                      onChange={handleInputChange}
                      placeholder={consultation.fee}
                      className="edit-input"
                    />
                  ) : (
                    <p>
                      <img src={ruppe} /> {consultation.fee}
                    </p>
                  )}
                </td>
                <td>
                  {editingRow === consultation.id ? (
                    <select
                      name="status"
                      value={newConsultation.status}
                      onChange={handleInputChange}
                      className="edit-input"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  ) : (
                    <button
                      className={`status-button ${
                        consultation.status ? "active" : "inactive"
                      }`}
                      // onClick={() => handleEditStatus(consultation.id)}
                    >
                      {consultation.status ? "Active" : "Inactive"}
                    </button>
                  )}
                </td>
                <td>
                  {editingRow === consultation.id ? (
                    <div style={{ display: "flex" }}>
                      <button
                        style={{ marginRight: "8%" }}
                        className="action-button save"
                        onClick={() => handleSaveConsultation(consultation.id)}
                      >
                        Save
                      </button>
                      <button
                        className="action-button cancel"
                        onClick={handleCancelEdit}
                      >
                        <img src={close} alt="Cancel" />
                      </button>
                    </div>
                  ) : (
                    <>
                      <button
                        className="action-button edit"
                        onClick={() => handleEditConsultation(consultation.id)}
                      >
                        <img src={edit} alt="Edit" />
                      </button>
                      <button
                        className="action-button delete"
                        onClick={() =>
                          handleDeleteConsultation(consultation.id)
                        }
                      >
                        <img src={delete1} alt="Delete" />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>


    
      {showDeleteAlert && (
        <div className="blur-background">
          <div className="popup">
            <div className="popup-content">
              <p>Are you sure you want to delete this consultation?</p>
              <div className="popup_button">
                <button
                  style={{ marginRight: "10%" }}
                  className="action-button save"
                  onClick={handleDeleteConsultationConfirm}
                >
                  Yes
                </button>
                <button
                  className="action-button save"
                  onClick={handleCancelDelete}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Agent_Consultations;
