import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import up_down from "./img/up&down.svg";
import sort_arrow from "./img/icon_remarks.svg";
import reschedule from "./img/icon_reschedule.svg";
import remarks from "./img/icon_remarks.svg";
import Delete from "./img/user_delete.svg";
import User_view from "./img/user_view.svg";
import "./User_Consultations.css";
import User_Feedback from "./img/user_feedback.svg";
import Meeting from "./img/agent_metting.svg";
import { isBefore } from "date-fns";

const User_Consultations = () => {
  const [userConsults, setuserConsults] = useState([]);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    console.log("on fetch data");
    const fetchUserConsults = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/mybookings/${sessionStorage.getItem(
            "userID"
          )}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData, "jsondata");
        if (jsonData) {
          const transformedData = jsonData.map((item) => ({
            slno: item.postId,
            name: {
              profileImage: item.profilePic,
              name: `${item.agentfName} ${item.agentlName}`,
            },
            email: item.emailId,
            category: item.consultingType,
            date: item.bookingDate,
            timeSlot: item.bookingTime,
            action: item.postId,
            agentRemarks: item.agentRemarks,
            agentId: item.agentId,
            meetingId: item.meetingId,
          }));
          setSortedData(transformedData);
          setTableData(transformedData);
          console.log(transformedData, "transforamed dat");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserConsults();
  }, []);
  const [showSortArrow, setShowSortArrow] = useState(false);
  const [sortedData, setSortedData] = useState(tableData);
  const handleDelete = (index) => {
    console.log("delete", index);
    const updatedData = sortedData.filter((i) => i.slno !== index);
    console.log(updatedData);
    setSortedData(updatedData);
    const handleSubmit = async (event) => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/cancelbooking/${index}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const data = await response.json();
        console.log("Data deleted  successfully:", data);
        if (data.message) {
          alert(data.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };
    handleSubmit();
  };
  const data = React.useMemo(() => sortedData, [sortedData]);
  const getStatusClass = (dateStr) => {
    const currentDate = new Date();
    const appointmentDate = new Date(dateStr);

    // console.log(appointmentDate);
    return currentDate > appointmentDate ? "Completed" : "Upcoming";
  };

  console.log("in table", data.length);
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "slno",
        // (row, index) => index + 1,
        disableSortBy: true,
      },
      {
        Header: "Agent Name",
        accessor: "name",
        Cell: UserProfileCell,
        disableSortBy: true,
      },
      // { Header: 'Email', accessor: 'email', disableSortBy: true },
      { Header: "Category", accessor: "category", disableSortBy: true },
      {
        Header: "",
        accessor: "agentRemarks",
        disableSortBy: true,
        Cell: ({ value }) => { },
      },
      {
        Header: "",
        accessor: "agentId",
        disableSortBy: true,
        Cell: ({ value }) => { },
      },
      {
        Header: ({ column }) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            Date
            {column.isSorted ? (
              <span style={{ marginLeft: "5px" }}>
                {column.isSortedDesc ? "🔼" : "🔽"}
              </span>
            ) : (
              <img
                src={up_down}
                style={{ marginLeft: "5px", marginTop: "2%" }}
              />
            )}
          </div>
        ),
        accessor: "date",
        Cell: ({ value }) => {
          const date = new Date(value);
          const formattedDate = format(date, "dd/MM/yyyy");
          const statusClass = getStatusClass(value);

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "flex-end",
              }}
            >
              <div style={{ marginRight: "10px" }}>{formattedDate}</div>
              <div
                className={`status-capsule ${statusClass}`}
                style={{ fontSize: "14px" }}
              >
                {statusClass}
              </div>
            </div>
          );
        },
      },
      { Header: "Time Slot", accessor: "timeSlot", disableSortBy: true },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          const referringPageUrl = encodeURIComponent(window.location.pathname);
          const rowData = row.values;
          // console.log(rowData)
          return (
            <div>
              {getStatusClass(row.original.date) === "Upcoming" ? (
                <>
                  <button
                    style={{ backgroundColor: "white", border: "none" }}
                    onClick={() => handleDelete(row.original.slno)}
                  >
                    <img src={Delete} />
                  </button>
                  {/* <Link to= '/User_Remarks' state= {{ rowData, url: referringPageUrl }}>
        <button style={{backgroundColor:'white',border:'none'}}>
          <img src={User_view} alt="Remarks" />
        </button>
      </Link> */}
                  <Link
                    to="/User_Reschedule"
                    state={{ rowData, url: referringPageUrl }}
                  >
                    <button
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <img src={reschedule} alt="Reschedule" />
                    </button>
                  </Link>
                </>
              ) : (
                <div>
                  <Link
                    to="/User_Remarks"
                    state={{ rowData, url: referringPageUrl }}
                  >
                    <button
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <img src={User_view} alt="Remarks" />
                    </button>
                  </Link>
                  <Link
                    to="/User_Feedback"
                    state={{ rowData, url: referringPageUrl }}
                  >
                    <button
                      style={{ backgroundColor: "white", border: "none" }}
                    >
                      <img src={User_Feedback} alt="Reschedule" />
                    </button>
                  </Link>
                </div>
              )}
            </div>
          );
        },

        disableSortBy: true,
      },
      // {
      //   Header: "Meeting",
      //   className: "text-center",
      //   accessor: "meetingId",
      //   Cell: ({ value, row }) =>
      //     value ? (
      //       <div className="text-center">
      //         {getStatusClass(row.original.date) !== "Completed" ? (
      //           <div className="text-center">
      //             <Link
      //               to={`/meeting?meetingId=${value}`}
      //               style={{ margin: "0 5px" }}
      //               className="link-4"
      //             >
      //               <img src={Meeting} alt="Meeting" /> {value}
      //             </Link>
      //             <div>
      //         Date: {row.original.date}, Time: {row.original.timeSlot}
      //       </div>
      //           </div>
      //         ) : (
      //           <div className="link-5 ">Meeting Ended</div>
      //         )}
      //       </div>
      //     ) : (
      //       <span className="link-5" style={{ margin: "0 5px" }}>
      //         No Meeting
      //       </span>
      //     ),
      // },
      {
        Header: "Meeting",
        className: "text-center",
        accessor: "meetingId",
        Cell: ({ value, row }) => {
          const getStatusClass = () => {
            const currentDate = new Date();
            const appointmentDate = new Date(row.original.date);
            return currentDate > appointmentDate ? "Completed" : "Upcoming";
          };
      
          const isMeetingUpcoming = () => {
            const currentDateTime = new Date();
            const [startHour, startMinute] = row.original.timeSlot.split(":").map(Number);
            const [day, month, year] = row.original.date.split("/").map(Number);
            
            const meetingDateTime = new Date(year, month - 1, day, startHour, startMinute); // month - 1 because JavaScript months are 0-indexed
      
            return isBefore(currentDateTime, meetingDateTime);
          };
      
          const isDateTimeMatched = () => {
            const currentDateTime = new Date();
            const [startHour, startMinute] = row.original.timeSlot.split(":").map(Number);
            const [day, month, year] = row.original.date.split("/").map(Number);
            
            const meetingDateTime = new Date(year, month - 1, day, startHour, startMinute); // month - 1 because JavaScript months are 0-indexed
      
            // Compare only date parts
            return (
              currentDateTime.getFullYear() === meetingDateTime.getFullYear() &&
              currentDateTime.getMonth() === meetingDateTime.getMonth() &&
              currentDateTime.getDate() === meetingDateTime.getDate()
            );
          };
      
          return (
            <div className="text-center">
              {getStatusClass() === "Upcoming" && isMeetingUpcoming() ? (
                <div>
                  {isDateTimeMatched() ? (
                    <Link
                      to={`/meeting?meetingId=${value}`}
                      style={{ margin: "0 5px" }}
                      className="link-4"
                    >
                      <img src={Meeting} alt="Meeting" />
                      {value}
                    </Link>
                  ) : (
                    <div className="link-5">Meeting Yet</div>
                  )}
                </div>
              ) : getStatusClass() === "Completed" ? (
                <div className="link-5">Meeting Ended</div>
              ) : null}
            </div>
          );
        },
      }
      
      

    ],
    [showSortArrow, sortedData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 3 },
    },
    useSortBy,
    usePagination
  );

  const visiblePageCount = Math.min(10, pageCount);
  const renderPageButtons = () => {
    let startPage = Math.max(0, pageIndex - 2);
    let endPage = Math.min(totalPages - 1, pageIndex + 2);

    if (endPage - startPage < 4) {
      if (startPage === 0) {
        endPage = Math.min(4, totalPages - 1);
      } else {
        startPage = Math.max(0, totalPages - 5);
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };

  const totalPages = pageOptions.length;
  return (
    <div className="wrapper-2 style-1">
      <h1>Consultations</h1>
      <div className="usertable_div">
        <table {...getTableProps()} className="usertable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {/* {column.isSorted ? (
  <span style={{marginBottom:'-500px' }}>
    {column.isSortedDesc ? '🔼' : ' 🔽'}
  </span>
) : ''} */}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className='user_pagination'>
        <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {visiblePageCount}
          </strong>{' '}
        </span>
        </div>
        <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="user_page-btn min-max">
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}  className="user_page-btn prev-next">
          {'Prev'}
        </button>{' '}
        {pageOptions.map(pageNumber => (
    <button
      key={pageNumber}
      onClick={() => gotoPage(pageNumber)}
      className={`user_page-btn ${pageIndex === pageNumber ? 'active' : ''}`}
    >
      {pageNumber + 1}
    </button>
  ))}
        <button onClick={() => nextPage()} disabled={!canNextPage} className="user_page-btn prev-next">
          {'Next'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}  className="user_page-btn min-max">
          {'>>'}
        </button>{' '}
        {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            value={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
         
          />
        // </span>{' '} */}
        {/* </div> */}
        {/* </div> */}
        <div className="user_pagination" style={{ marginLeft: "1%" }}>
          <div>
            <span>
              Showing{" "}
              <strong>
                {pageIndex * pageSize + 1}-
                {Math.min((pageIndex + 1) * pageSize, data.length)} of{" "}
                {data.length}
              </strong>{" "}
            </span>
          </div>
          <div>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage || pageIndex === 0}
              className="fb_page-btn prev-next"
            >
              Prev
            </button>
            <button
              onClick={() => gotoPage(0)}
              disabled={pageIndex === 0}
              className="fb_page-btn min-max"
            >
              {"<<"}
            </button>
            {renderPageButtons().map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => gotoPage(pageNumber)}
                className={`fb_page-btn ${pageIndex === pageNumber ? "active" : ""
                  }`}
              >
                {pageNumber + 1}
              </button>
            ))}
            <button
              onClick={() => gotoPage(totalPages - 1)}
              disabled={pageIndex === totalPages - 1}
              className="fb_page-btn min-max"
            >
              {">>"}
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage || pageIndex === totalPages - 1}
              className="fb_page-btn prev-next"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserProfileCell = ({ cell: { value } }) => {
  return (
    <div className="user-profile-cell">
      {value.profileImage && (
        <img
          src={`https://www.mypropertypal.in/administrator/uploads/${value.profileImage}`}
          alt={value.agentfName}
        />
      )}
      <span>{value.name}</span>
    </div>
  );
};
// const Userdate = ({ cell: { value } }) => <div>{value.date}</div>;
// const ActionCell = ({ row }) => {
//   const referringPageUrl = encodeURIComponent(window.location.pathname);
//   const rowData = row.values
//   // console.log(referringPageUrl)
//   // console.log(rowData)
//   return (
//     <div>
//       <Link to="/Agent_Remarks" state={{ rowData, url: referringPageUrl }}>
//         <button style={{backgroundColor:'white',border:'none'}}>
//           <img src={remarks} alt="Agent Remarks" />
//         </button>
//       </Link>
//       <Link to= '/Reschedule' state= {{ rowData, url: referringPageUrl }}>
//         <button style={{backgroundColor:'white',border:'none'}}>
//           <img src={reschedule} alt="Reschedule" />
//         </button>
//       </Link>
//     </div>
//   );
// };

export default User_Consultations;
