// Contact.js
import React from "react";
import { useRef, useState, useEffect } from "react";
import agent1 from "./img/agent2.png";
import bgdiv from "./img/bgdiv.png";
import ruppe from "./img/ruppe.png";
import Icon from "react-multi-date-picker/components/icon";
import DatePicker, { DateObject, toDateObject } from "react-multi-date-picker";
import "./booking.css";
import date from "./img/date.svg";
import { weekdays } from "moment";
import downarrow from "./img/downarrow.svg";
import { useLocation, Link } from "react-router-dom";
import { format, formatDate } from "date-fns";
import {  useNavigate } from 'react-router-dom';


// const availabilityData = {
//   0: {
//     week: {},
//     except: [],
//     selected: {}
//   },
//   1: {
//     // ...
//   },
//   // ...
// };

// Function to add or update the selected object

// Function to retrieve values by month index and date

// // Example usage
// addOrUpdateSelected(0, "2023-03", 0); // Add value 0 to the "2023-03" key for month index 0
// addOrUpdateSelected(0, "2023-03", 1); // Add value 1 to the "2023-03" key for month index 0

// console.log(getValuesByMonthIndexAndDate(0, "2023-03")); // Should log [0, 1]

// { profileData, appointmentData, dateOptions, timeOptions }

const Booking = () => {
  const navigate = useNavigate();
  const datePickerRef = useRef();
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState();
  const [availabilityData, setAvailabilityData] = useState({});
  const [opened, setopened] = useState();
  const [timeslots, settimeslots] = useState([]);
  const [agentsdata, setagentsdata] = useState([]);
  const [profileData, setprofileData] = useState({});
  const [fetchData1, setfetchData1] = useState({});
  const location = useLocation();
  const [categories, setcategories] = useState([]);
  const [user, setuser] = useState(false);
  const agent = location.state?.agent;
  console.log(agent, "agent");
  let fee = location.state?.fee;
  let selectedcategory = location.state?.categoryid;
  console.log(selectedcategory, "category22");
  useEffect(() => {
    // const storedData = sessionStorage.getItem('availabilityData');
    // if (storedData) {
    //   setAvailabilityData(JSON.parse(storedData));
    // }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/viewagent/${agent}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData, "jsondata");
        if (jsonData) setfetchData1(jsonData);
        setprofileData(jsonData.agentdetails);
        setAvailabilityData(JSON.parse(jsonData.getrecord.availability));
        console.log("hi");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (sessionStorage.getItem("userID")) {
      if (sessionStorage.getItem("userID") !== "") {
        console.log(user, "user");
        setuser(true);
        console.log(user, "user");
      }
    }
    fetchData();
  }, []);

  console.log(availabilityData);
  useEffect(() => {
    if (typeof selectedDates !== "undefined") {
      setIsOpen(true);
      // console.log('clicked ')
    }

    settimeslots(
      availabilityData[selectedDates?.month.index]?.week[
        selectedDates?.weekDay.index
      ]
    );
    //  .week?selectedDates?.weekDay.index:[]
  }, [selectedDates]);
  useEffect(() => {
    const cat = fetchData1?.agentconsultings;
    console.log(fetchData1.agentconsultings);
    cat?.forEach((c) => {
      console.log(c.consultingType);
      if (!categories.includes(c.consultingType)) {
        setcategories((prevCategories) => [
          ...prevCategories,
          c.consultingType,
        ]);
      }
    });

    console.log(categories, "categories", cat);
  }, [fetchData1]);
  console.log(timeslots);
  const handledate = () => {
    datePickerRef.current.openCalendar();
    if (!shouldCloseCalendar) {
      setShouldCloseCalendar(true);
    }
  };
  const postavailData = async () => {
    console.log(availabilityData);
    try {
      const response = await fetch(
        `https://www.mypropertypal.in/administrator/agentavailability/${agent}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(availabilityData), // Your request body here
        }
      );
      const data1 = await response.json();
      console.log("resposnse from post", data1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log(agent);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState();
  const CustomSelect = ({ options, onSelect, timeslots }) => {
    const handleSelect = (option) => {
      setSelectedOption(option);

      onSelect(option);
      // setIsOpen(false);
      console.log(options[option]);
      console.log("selectedoptiom", selectedOption);
    };

    // Call getValuesByDate outside of the JSX
    var selectedarr = [];
    if (timeslots && timeslots.length > 0)
      selectedarr = getValuesByDate(selectedDates?.month.index, selectedDates);
    // console.log(selectedarr)
    console.log(categories);
    return (
      <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
        <div
          className="booking_date"
          style={{ paddingInline: "6%", marginRight: "30px" }}
        >
          <div>
            {selectedOption !== null
              ? options[selectedOption]
              : "Select Time Slot"}
          </div>
          <img src={downarrow} alt="downarrow" />
        </div>
        {isOpen && (
          <div className="options_div">
            <div className="options">
              {timeslots && timeslots.length > 0 ? (
                timeslots
                  .filter((option) => !selectedarr.includes(option))
                  .map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleSelect(option)}
                    >
                      {options[option]}
                    </div>
                  ))
              ) : (
                <div
                  style={{
                    fontSize: "18px",
                    color: "#011D70",
                  }}
                >
                  Slots not Available
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  console.log(user, "user");
  console.log(sessionStorage.getItem("userID"));
  const handleproceed = () => {
    if (sessionStorage?.getItem("userID")) {
      console.log("i have id");
      UpdateSelected(selectedDates?.month.index, selectedDates, selectedTime);
      setSelectedDates();
      setSelectedOption(null);
      console.log(selectedTime);
      console.log(selectedDates);
      console.log(selectedcategory, "category");
      let year = selectedDates.year;
      let month = (selectedDates.monthIndex + 1).toString().padStart(2, "0");
      let day = selectedDates.day.toString().padStart(2, "0");
      //     const dateParts = selectedDates.split('-');
      //     console.log(dateParts,'jdlsdjlk') // Assuming date is in 'yyyy-mm-dd' format
      // const year = dateParts[0];
      // const month = dateParts[1];
      // const day = dateParts[2];
      let data = {
        userid: sessionStorage.getItem("userID"),
        agentid: agent,
        consultingid: selectedcategory,
        // "bookingdate": `${selectedDates.year}-${selectedDates.monthIndex+1}-${selectedDates.day}`,
        bookingdate: `${year}-${month}-${day}`,

        bookingtime: timeOptions[selectedTime],
        bookingfee: fee,
      };
      //   let data={
      //     "userid":"2",
      //     "agentid":"1",
      //     "consultingid":"1",
      //     "bookingdate":"2024-03-24",
      //     "bookingtime":"12 PM to 1 PM",
      //     "bookingfee":"500"
      //     }
      //     console.log(selectedDates,'dates')
      console.log(data);
      const postData = async () => {
        try {
          const response = await fetch(
            "https://www.mypropertypal.in/administrator/postbooking",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data), // Your request body here
            }
          );
          const data1 = await response.json();
          console.log("resposnse from post", data1);
          //alert(data1.message);
          navigate ('/Thankyou');
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      postData();
      postavailData();
      //
      //   const myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Cookie", "PHPSESSID=48ccc971b2dbbb08d259c9fe203134b5d230302e");

      // const raw = JSON.stringify({
      //   "userid": "2",
      //   "agentid": "1",
      //   "consultingid": "1",
      //   "bookingdate": "2024-03-24",
      //   "bookingtime": "12 PM to 1 PM",
      //   "bookingfee": "500"
      // });

      // const requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: "follow"
      // };

      // fetch("https://www.mypropertypal.in/administrator/postbooking", requestOptions)
      //   .then((response) => response.text())
      //   .then((result) => console.log(result))
      //   .catch((error) => console.error(error));
    } else {
      console.log("dont have id");
      alert("pls login");
    }
  };
  function UpdateSelected(monthIndex, date, value) {
    // Check if the selected key exists for the month index, if not, create it
    if (!availabilityData[monthIndex]?.selected) {
      availabilityData[monthIndex].selected = {};
    }

    // Check if the date key exists in selected
    if (availabilityData[monthIndex].selected[date]) {
      // Check if the value already exists in the array
      if (!availabilityData[monthIndex].selected[date].includes(value)) {
        // Add value to the existing array
        availabilityData[monthIndex].selected[date].push(value);
      }
    } else {
      // Create a new array and assign it to the date key
      availabilityData[monthIndex].selected[date] = [value];
    }
    sessionStorage.setItem(
      "availabilityData",
      JSON.stringify(availabilityData)
    );
  }

  function getValuesByDate(monthIndex, date) {
    if (
      availabilityData[monthIndex] &&
      availabilityData[monthIndex].selected &&
      availabilityData[monthIndex].selected[date]
    ) {
      return availabilityData[monthIndex].selected[date];
    }
    return [];
  }
  const [selecteddate, setSelecteddate] = useState("default");

  const handleSelectdate = (event) => {
    setSelecteddate(event.target.value);
  };
  const [selectedtime, setSelectedtime] = useState("default");

  const handleSelecttime = (event) => {
    setSelectedtime(event.target.value);
  };

  //   const profileData = {
  //   name: 'JOHN DOE',
  //   cost: '500',
  //   membershipStatus: 'Gold Member',
  //   domain: ['Land', 'Real Estate', 'Property Advisor'],
  //   matter: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ',
  //   imageSrc: 'path/to/agent1.png',
  //   currencyIcon:ruppe,
  // };

  const appointmentData = {
    title: "Book an Appointment",
    datePlaceholder: "Select Date",
    timePlaceholder: "Select Time Slot",
  };

  const [selectedTime, setSelectedTime] = useState(null);
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const dateOptions = [
    "Hyderabad",
    "Andhra Pradesh",
    "Delhi",
    "Mumbai",
    "Bangalore",
  ];
  const timeOptions = [
    "9 AM to 10 AM",
    "10 AM to 11 AM",
    "11 AM to 12 PM",
    "12 PM to 1 PM",
    "1 PM to 2 PM",
    "2 PM to 3 PM",
    "3 PM to 4 PM",
    "4 PM to 5 PM",
    "5 PM to 6 PM",
    "6 PM to 7 PM",
    "7 PM to 8 PM",
    "8 PM to 9 PM",
  ];
  return (
    <div
      className="booking"
      style={{
        backgroundImage: `url(${bgdiv})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="wrapper-1">
        <div className="booking-profile-p-main">
          <div className="booking-profile-p-1">
            <img
              src={`https://www.mypropertypal.in/administrator/uploads/${profileData.profilePic}`}
              alt={`Image ${agent1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginTop: "0px",
              }}
            />
          </div>
          <div className="booking-profile-p-2">
            <div className="booking-profile-p-2-1">
              <h3>
                {profileData.agentfName} {profileData.agentlName}
              </h3>
              <div className="booking_member"></div>

              <h6>Gold Member</h6>
              <p style={{ color: "black" }}>{profileData.areaPreference}</p>
              <h5>{categories?.join(" | ")}</h5>
              {/* <p>{`ReraId : ${agentfName}`}</p> */}
              <p>{`Rera_Id : ${profileData.reraId}`}</p>
              <p>{`NAR_No : ${profileData.associationmember}`}</p> 
              <p>{`No_Of_Deals : ${profileData.noofdealsclosed}`}</p>
              <p>{profileData.briefIntro}</p>
      
            </div>
            <div className="booking-profile-p-2-2">
              <h3>
                <img
                  src={ruppe}
                  alt="Currency Icon"
                  className="booking_cost_icon"
                />{" "}
                &nbsp;{fee}
              </h3>
            </div>

            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="booking_profile">
        <div className="booking_pic">
          <img
            src={`https://www.mypropertypal.in/administrator/uploads/${profileData.profilePic}`}
            alt={`Image ${agent1}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              marginTop: "0px",
            }}
          />
        </div>
        <div className="booking_info">
          <div className="booking_name">
            <p>
              {profileData.agentfName} {profileData.agentlName}
            </p>
            <span className="booking_cost">
              <img
                src={ruppe}
                alt="Currency Icon"
                className="booking_cost_icon"
              />

              <p className="booking_cost">&nbsp;{fee}</p>
            </span>
          </div>
          <div className="booking_member">
            <p>Gold Member</p>
            <p style={{ color: "black" }}>111{profileData.areaPreference}</p>
          </div>
          <div className="booking_domain">
            <p>{categories?.join(" | ")}</p>
          </div>
          <div className="booking_matter">
            <p>{profileData.briefIntro}</p>
          </div>
          <p></p>
        </div>
      </div> */}

      {/* <div className='appointment_date'>
    <select id="dropdown" value={selecteddate} onChange={handleSelectdate} className='app_select'>
    <option value="default" disabled>Select Date</option>
        {dateOptions.map((option, index) => (
          <option key={index} value={option} >
            {option}
          </option>
        ))}
      </select>
    </div> */}

      <div className="wrapper-1">
        <div className="bookingpage-sec1-wrap">
          <div className="bookingpage-sec-1-3">
            <h2>Book an Appointment</h2>
          </div>
          <div className="bookingpage-sec-1-1">
            <div className="booking_date" onClick={handledate}>
              <DatePicker
                className="custom_date"
                placeholder="for usertesting"
                ref={datePickerRef}
                fixMainPosition="bottom-start"
                onOpen={() => setShouldCloseCalendar(false)}
                onClose={() => shouldCloseCalendar}
                onChange={setSelectedDates}
                render={<img src={date} />}
                // render={<Icon/>}
                minDate={new Date()}
                highlightToday={false}
                mapDays={({
                  date,
                  currentMonth,
                  selectedDate,
                  isSameDate,
                  weekNumber,
                }) => {
                  let props = {};

                  props.style = {
                    fontSize: "13px",
                  };

                  //   if(selectedMonthData.except){
                  //  hi=selectedMonthData.except.map(date => +date.substring(8))||[];
                  // }
                  // if ([0,6].includes(date.weekDay.index)) return  props.className= "highlight highlight-green"
                  let isWeekend = Object.keys(
                    availabilityData[currentMonth.index]?.week || {}
                  )
                    .map(Number)
                    .includes(date.weekDay.index);
                  // let isWeekend = [0,4,5,6].includes(date.weekDay.index)
                  // settimeslots(availabilityData[currentMonth.index]?.week?[weekNumber]:[])
                  if (
                    availabilityData[currentMonth.index]?.except?.includes(
                      date.format("YYYY-MM-DD")
                    )
                  ) {
                    return { disabled: true };
                  }

                  if (isWeekend)
                    props.style = {
                      ...props.style,
                      color: "green",
                      fontWeight: "bold",
                    };
                  else {
                    return { disabled: true };
                  }
                  if (isSameDate(date, selectedDate))
                    props.style = {
                      ...props.style,
                      color: "#FFFFFF",
                      backgroundColor: "#011D70",

                      borderRadius: "8px",
                    };
                  //     // console.log(hi)
                  // if (hi.includes(date.day))  return {
                  //   disabled: true,
                  //   style: { color: "#ccc" },
                  //   onClick: () => alert("sorry that date is reserved")
                  // }
                  // else props.className = "highlight highlight-green"

                  return props;
                }}
              />
              <div>
                {selectedDates ? selectedDates.format() : "Select Date"}
              </div>
            </div>
          </div>
          <div className="bookingpage-sec-1-1">
            <CustomSelect
              options={timeOptions}
              onSelect={handleTimeSelect}
              timeslots={timeslots}
            />
          </div>
          <div className="bookingpage-sec-1-2">
            <div>
              {user ? (
                <button
                  onClick={handleproceed}
                  className="find_button"
                  style={{ minwidth: "8vw" }}
                >
                  Proceed
                </button>
              ) : (
                <button
                  onClick={handleproceed}
                  className="find_button"
                  style={{ minwidth: "8vw" }}
                >
                  Login to book slot
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* appointment */}
    </div>
    // booking
  );
};

export default Booking;
