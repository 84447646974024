import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './User_Remarks.css'

function User_Remarks() {
  
  const location = useLocation();
  console.log(location.state)
  const referringPageUrl = location.state?.url || '/';
  const rowData = location.state?.rowData || {};
  
  console.log(referringPageUrl);
  console.log(rowData);
  
  const { slno, name:{name,profileImage}, email, category, date, timeSlot,agentRemarks } = rowData;
  console.log(rowData)

  return (

    <div className="userdash d-heading-1">
    <div className="wrapper-2">
    <div className="two-col-main">
          <div className="two-col-1">
            <h1>Consultations - Remarks</h1>
          </div>
          <div className="two-col-2">
            <button className="button-small-1">
            <Link to={decodeURIComponent(referringPageUrl)}>Cancel</Link>
            </button>
          </div>
        </div>
        <div className="border-box-main">
          <div className="tabel-scroll">
          <table className="table-white-1">
        <thead>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>Category</th>
            <th>Date</th>
            <th>Time Slot</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="user-profile-cell">
                <div className="User_Remarks_avatar"><img src={`https://www.mypropertypal.in/administrator/uploads/${profileImage}`}/></div>
                <span>{name}</span>
              </div>
            </td>
            <td>{email}</td>
            <td>{category}</td>
            <td>{date}</td>
            <td>{timeSlot}</td>
          </tr>
        </tbody>
      </table>
          </div>

          <h3>Remarks</h3>
          <div className='remarks_user' style={{display:"flex",flexDirection:'column',justifyContent:"space-between",marginInline:'5%',}}> 
        
        <div style={{ fontSize:'14px',marginTop:'2%'}}>
          {agentRemarks}
        {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. */}
        </div>
      </div>



          </div>
 
  </div>
   </div>
);
}

export default User_Remarks;




