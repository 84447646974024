import {Component} from "react";


class TermsandConditons extends Component{
    render(){
        return(
            <div className="agent_terms_and_conditons">
               <style>
                    {`
                        .agent_terms_and_conditons {
                            display : flex;
                            flex-direction:coluymn;
                            justify-content : center;
                            align-items:center;
                            min-height : 80vh;
                        }
                    `}
                </style>
                Content Will Be Posted Soon...........
            </div>
        )
    }
}


export default TermsandConditons