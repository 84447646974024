import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import "./User_Feedback.css";

function User_feedback() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");

  const handleStarClick = (star) => {
    setRating(star);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = () => {
    // const data = {
    //   userid: sessionStorage.getItem('userID'),
    //   agentid: rowData.agentId,

    //   userreview: feedback,
    //   agentrating: rating
    // }
    const data = {
      agentid: agentId,
      userid: sessionStorage.getItem("userID"),
      userreview: feedback,
      agentrating: rating,
    };

    fetch("https://www.mypropertypal.in/administrator/postagentreview", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("Feedback submitted:", result);
        // Handle success, e.g., show a success message
        alert(result.message);
        setRating("");
        setFeedback("");
      })
      .catch((error) => {
        console.error("Error submitting feedback:", error);
        // Handle error, e.g., show an error message
      });
  };

  const location = useLocation();
  const referringPageUrl = location.state?.url || "/";
  const rowData = location.state?.rowData || {};

  const {
    name: { name, profileImage } = {},
    email,
    category,
    date,
    timeSlot,
    agentId,
  } = rowData;
  // console.log(agentId,'agentid');
  const profileImageUrl = `https://www.mypropertypal.in/administrator/uploads/${profileImage}`;
  console.log(profileImageUrl);

  return (
    <div className="userdash d-heading-1">
      <div className="wrapper-2">
        <div className="two-col-main">
          <div className="two-col-1">
            <h1>Consultations - Feedback</h1>
          </div>
          <div className="two-col-2">
            <button className="button-small-1">
              <Link to={decodeURIComponent(referringPageUrl)}>Cancel</Link>
            </button>
          </div>
        </div>
        <div className="border-box-main">
          <div className="tabel-scroll">
            <table className="table-white-1">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Category</th>
                  <th>Date</th>
                  <th>Time Slot</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="user-profile-cell">
                      <div className="User_feedback_avatar">
                      <img
                          src={profileImageUrl}
                          alt={name}
                          onError={(e) => e.target.src = 'path-to-default-image.jpg'} // Handle missing image
                        />
                      </div>
                      <span>{name}</span>
                    </div>
                  </td>
                  <td>{email}</td>
                  <td>{category}</td>
                  <td>{date}</td>
                  <td>{timeSlot}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="textarea-2">
            <textarea
              placeholder="Feedback"
              value={feedback}
              onChange={handleFeedbackChange}
            ></textarea>
          </div>
          <div className="star-feedback">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={star <= rating ? "star_selected" : "star"}
                onClick={() => handleStarClick(star)}
              >
                ★
              </span>
            ))}
          </div>

          <div className="submit-btn-wrap">
            <button className="button-a-2" onClick={handleSubmit}>
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User_feedback;
