import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./user_login.css";
import Image2 from "./img/userbg.png";
import { useNavigate } from "react-router-dom";
const User_forgot = ({ isloggedIn }) => {
  const navigate = useNavigate();
  const [emailid, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [load, setIsLoading] = useState(false);
  const { loggedin, setloggedin } = useState(false);

  // const handleLogin = () => {

  //     console.log('Login clicked with email:', email, 'and password:', password);
  //    isloggedIn('true')
  //    navigate('/');
  //    sessionStorage.setItem('isloggedin','true')
  // };


  // const handleLogin = async () => {
  //   setload(true);
  //   console.log(JSON.stringify({ emailid }));
  //   try {
  //     const response = await fetch(
  //       "https://www.mypropertypal.in/administrator/forgotpassword",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ emailid }),
  //       }
  //     );
  //     const data = await response.json();
  //     console.log("Response:", data);
  //     if (data?.message) {
  //       setload(false);
  //       console.log("got message", data.message);
  //       setmessage(data.message);
  //     }
  //     // if (data.message === 'Login Successful.') {
  //     //     sessionStorage.setItem('agentID', data.profiledet.agentID);
  //     //     // You can also set other data to sessionStorage if needed
  //     //     alert("loggedin ");

  //     //     isloggedIn('true')
  //     //     navigate('/');
  //     //     sessionStorage.setItem('isloggedin','true')

  //     // } else {
  //     //     // Handle other responses like incorrect password or email not found
  //     //     console.error('Login Failed:', data.message);
  //     // }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };


  const handleLogin = async () => {
    setIsLoading(true);

    // // getting userId from session storage :
    // const userId = sessionStorage.getItem('userID');
    // if (!userId) {
    //   console.error('User ID not found in sessionStorage');
    //   return;
    // }


    try {
        // Send a request to your backend API to handle forgot password
        const apiUrl =
(`https://www.mypropertypal.in/administrator/userforgotpassword`);
        const options = {
            method : "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body : JSON.stringify({emailid : emailid}),
        }

        const response = await fetch(apiUrl, options);
        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setMessage(data.message);
        } else {
            setMessage('Failed to process the request. Please try again later.');
        }

    } catch (error) {
        console.error('Error:', error);
        setMessage('An error occurred. Please try again later.');
    } finally {
        setIsLoading(false);
    }
};

  const handleForgotPassword = () => {
    console.log("Forgot Password clicked");
    navigate("/user_login");
  };

  const handleSignUp = () => {
    navigate("/Sign_Up");
  };
  // useEffect(() => {

  //   }, [loggedin]);

  return (
    <div
      className="user_Login"
      style={{
        backgroundImage: `url(${Image2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="userlogin_form">
        <div className="login_heading">
          <p>Forgot Password</p>
        </div>
        <input
          type="email"
          placeholder="Email"
          value={emailid}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field-2"
        />
        {/* <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field"
                    style={{ height: '2vw' }}
                /> */}

        <div className="forgot-password-1" onClick={handleForgotPassword}>
          Login?
        </div>

        {load ? (
          <p>loading....</p>
        ) : (
          <div style={{ color: "#6083F7" }}>
            {message}
          </div>
        )}

        <button onClick={handleLogin} className="find_button button-3-padding">
          Get Password
        </button>

        <div className="signup">
          <p>Not a member yet?</p>{" "}
          <p
            style={{ color: "red", cursor: "pointer" }}
            onClick={handleSignUp}
            className="signup-link"
          >
            Sign Up
          </p>
        </div>
      </div>
      {/* login-form */}
    </div>
  );
};

export default User_forgot;
