// App.js
import React, { useEffect, useState } from "react";
// Assuming Card component is in Card.js
// Assuming you have a separate CSS file

import up_down from "./img/up&down.svg";
import sort_arrow from "./img/icon_remarks.svg";
import reschedule from "./img/user_reschedule.svg";
import Delete from "./img/user_delete.svg";
import { Link } from "react-router-dom";
import bigruppe from "./img/bigruppe.svg";
import { useNavigate } from "react-router-dom";
import "./User_Dash.css";
import User_view from "./img/user_view.svg";
import { format } from "date-fns";
import Marquee from "react-fast-marquee";
import User_Feedback from "./img/user_feedback.svg";
//import Meeting from "./img/user_feedback.svg";

import Meeting from "./img/agent_metting.svg";
const Dash = () => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    console.log("on fetch data");
    const fetchUserConsults = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/mybookings/${sessionStorage.getItem(
            "userID"
          )}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData, "jsondata");
        if (jsonData) {
          const transformedData = jsonData.slice(0, 5).map((item) => ({
            slno: item.postId,
            name: {
              profileImage: item.profilePic,
              name: `${item.agentfName} ${item.agentlName}`,
            },
            email: item.emailId,
            category: item.consultingType,
            date: item.bookingDate,
            timeSlot: item.bookingTime,
            action: item.postId,
            agentId: item.agentId,
            meetingId: item.meetingId,
          }));
          setSortedData(transformedData);
          setTableData(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserConsults();
  }, []);
  const navigate = useNavigate();
  const handleDelete = (slno) => {
    const updatedData = sortedData.filter((row) => row.slno !== slno);
    setSortedData(updatedData);
    const handleSubmit = async (event) => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/ator/cancelbooking/${slno}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit data");
        }

        const data = await response.json();
        console.log("Data deleted  successfully:", data);
        if (data.message) {
          alert(data.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    };
    handleSubmit();
  };
  const getStatusClass = (dateStr) => {
    const currentDate = new Date();
    // const dateParts = dateStr.split('/');
    // console.log(dateParts)
    // const appointmentDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
    // console.log(appointmentDate)
    const appointmentDate = new Date(dateStr);
    console.log(currentDate > appointmentDate);
    return currentDate > appointmentDate ? "Completed" : "Upcoming";
  };
  const [sortedData, setSortedData] = useState(tableData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });

    // Sort the data based on the key and direction
    let sorted;
    if (direction === "ascending") {
      sorted = [...tableData].sort(
        (a, b) => new Date(a[key]) - new Date(b[key])
      );
    } else if (direction === "descending") {
      sorted = [...tableData].sort(
        (a, b) => new Date(b[key]) - new Date(a[key])
      );
    } else {
      sorted = tableData;
    }
    setSortedData(sorted);
  };

  const handleviewall = () => {
    navigate("/userconsultations");
  };
  const getStatusClassForRow = (row) => {
    return getStatusClass(row.date);
  };
  const referringPageUrl = encodeURIComponent(window.location.pathname);

  const onClickLunch = () => {
    window.open("/VideoNew", "_blank");
  };

  return (
    <div className="userdash d-heading-1">
      <div className="wrapper-2">
        <h1>Dashboard</h1>

        <div className="userdash_cards">
          <div className="userdash_card">
            <div className="userdash_card-title">Total Appointments So Far</div>
            {/* <div className="userdash_card-word1">1st Jan 24 to Till Now</div> */}
            <div className="userdash_card-word2">{tableData.length}</div>
          </div>
        </div>
      </div>

      <div className="wrapper-2">
        <div
          className="userdash_view_all"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 className="p-tb-0">Recent Appointments</h3>
          <button
            style={{ width: "128px" }}
            onClick={handleviewall}
            className="find_button"
          >
            View All
          </button>
        </div>
        <div className="usertable_div">
          <div>{/* userdashboard content */}</div>
          <table className="usertable">
            <thead>
              <tr>
                <th>#</th>
                <th>Agent Name</th>
                {/* <th>Email</th> */}
                <th>Category</th>
                <th onClick={() => requestSort("date")}>
                  Date
                  {sortConfig &&
                    sortConfig.key === "date" &&
                    sortConfig.direction !== null && (
                      <>
                        {sortConfig.direction === "ascending" ? (
                          <span style={{ marginLeft: "5px" }}>🔼</span>
                        ) : (
                          <span style={{ marginLeft: "5px" }}>🔽</span>
                        )}
                      </>
                    )}
                  {sortConfig && sortConfig.direction === null && (
                    <img
                      src={up_down}
                      alt="Sort"
                      style={{
                        marginLeft: "5px",
                        height: "15px",
                        height: "12px",
                      }}
                    />
                  )}
                </th>
                <th>Time Slot</th>
                <th>Action</th>

                <th>Meeting</th>
              </tr>
            </thead>
            <tbody>
              {sortedData.map((row, index) => {
                const statusClass = getStatusClassForRow(row);
                return (
                  <tr key={row.slno}>
                    <td>{row.slno}</td>
                    <td>
                      <div className="user-profile-cell">
                        <img
                          // src={`https://www.mypropertypal.in/administrator/ator/ator/uploads/${row.name.profileImage}`}
                          src={`https://www.mypropertypal.in/administrator/uploads/${row.name.profileImage}`}
                          // alt={row.name.name}
                        />
                        <span>{row.name.name}</span>
                      </div>
                    </td>
                    {/* <td>{row.email}</td> */}
                    <td>{row.category}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "flex-end",
                        }}
                      >
                        {format(row.date, "dd/MM/yyyy")}
                        <div className={`status-capsule ${statusClass}`}>
                          <span>{statusClass}</span>
                        </div>
                      </div>
                    </td>
                    <td>{row.timeSlot}</td>
                    <td>
                      {statusClass !== "Completed" ? (
                        <div>
                          <button
                            style={{ backgroundColor: "white", border: "none" }}
                            onClick={() => handleDelete(row.slno)}
                          >
                            <img src={Delete} alt="Agent Remarks" />
                          </button>
                          <Link
                            to="/User_Reschedule"
                            state={{
                              rowData: row,
                              url: window.location.pathname,
                            }}
                          >
                            <button
                              style={{
                                backgroundColor: "white",
                                border: "none",
                              }}
                            >
                              <img src={reschedule} alt="Reschedule" />
                            </button>
                          </Link>
                        </div>
                      ) : (
                        <div>
                          <Link
                            to="/User_Remarks"
                            state={{ rowData: row, url: referringPageUrl }}
                          >
                            <button
                              style={{
                                backgroundColor: "white",
                                border: "none",
                              }}
                            >
                              <img src={User_view} alt="Remarks" />
                            </button>
                          </Link>
                          <Link
                            to="/User_Feedback"
                            state={{ rowData: row, url: referringPageUrl }}
                          >
                            <button
                              style={{
                                backgroundColor: "white",
                                border: "none",
                              }}
                            >
                              <img src={User_Feedback} alt="Reschedule" />
                            </button>
                          </Link>
                        </div>
                      )}
                    </td>

                    <td>
                      {/* Start */}
                      {statusClass !== "Completed" ? (
                        <div>
                          {row.meetingId ? (
                            <Link
                              to={`/meeting?meetingId=${row.meetingId}`}
                              style={{ margin: "0 10px" }}
                              className="link-4"
                            >
                              <img src={Meeting} alt="Meeting" />
                              {row.meetingId}
                            </Link>
                          ) : (
                            <span
                              className="link-5"
                              style={{ margin: "0 10px" }}
                            >
                              No Meeting
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="link-5">Meeting Ended</div>
                      )}
                      {/* End */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Dash;
