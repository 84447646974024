// Sidebar.js
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./Sidebar.css"; // Import your styling
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Dash from "./dash";
import dashboard from "./img/dashboard.svg";
import consultations from "./img/consultations.svg";
import feedback from "./img/feedback.svg";
import profile from "./img/profile.svg";
import availability from "./img/avaliability.svg";
import servicablecities from "./img/servicablecities.svg";
import sidebar_ruppe from "./img/sidebar_ruppe.svg";
import sidebar_logo from "./img/sidebar_logo.svg";
import logout from "./img/logout.svg";
import appointments from "./img/appointments_grey.svg";
import DataTable from "./Appointments.js";
import Agent_Consultations from "./Agent_Consultation";
import Agent_Earnings from "./Agents_Earnings";
import Agent_Remarks from "./Agent_Remarks";
import Reschedule from "./Reschedule";
import Appointments from "./Appointments";
import Availability from "./Agents_Avaliabillity.js";
import dashboard_white from "./img/dashboard_white.svg";
import appointments_white from "./img/appointments_white.svg";
import consultations_white from "./img/consultations_white.svg";
import feedback_white from "./img/feedback_white.svg";
import profile_white from "./img/profile_white.svg";
import availability_white from "./img/avaliabillity_white.svg";
import servicablecities_white from "./img/servicable_white.svg";
import earning_white from "./img/earning_white.svg";
import Cities from "./cities.js";
import Personalinfo from "./personalinfo.js";
import Feedback from "./feedback.js";

const Sidebar = ({ prop_logout }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const [isloggedout, setisloggedout] = useState(false);
  const handlelogout = () => {
    setisloggedout("true");
    prop_logout("true");
    sessionStorage.setItem("isloggedout", "true");
    sessionStorage.setItem("isloggedin", "false");
    sessionStorage.setItem("agentID", "");
    sessionStorage.setItem("showsidebar", "false");
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  // Define paths where the sidebar should not be displayed
  const noSidebarPaths = [
    "/agent_login",
    "/user_login",
    "/blog",
    "/terms_&_conditons",
    "/privacy_&_policy",
    "/",
    "/find_agents",
    "/about",
    "/contact",
    "/disclamer"
    // "/",
  ];

  // Determine if the sidebar should be visible
  const shouldDisplaySidebar = !noSidebarPaths.includes(location.pathname);

  return shouldDisplaySidebar ? (
    <div className={`usersidebar ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <div className="usersidebar_left">
        <div className="cross-mark" onClick={toggleSidebar}>
          <div
            className={`menu-icon ${isSidebarOpen ? "open" : ""}`}
            onClick={toggleSidebar}
          >
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
          </div>
        </div>
        <NavLink
          to="/dashboard"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={isActive("/dashboard") ? dashboard_white : dashboard}
              alt="Dashboard"
            />
          </span>
          Dashboard
        </NavLink>

        {/* Appointments */}
        <NavLink
          to="/appointments"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={
                isActive("/appointments") ? appointments_white : appointments
              }
              alt="Appointments"
            />
          </span>
          Appointments
        </NavLink>

        {/* Availability */}
        <NavLink
          to="/availability"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={
                isActive("/availability") ? availability_white : availability
              }
              alt="Availability"
            />
          </span>
          Availability
        </NavLink>

        {/* Earnings */}
        <NavLink
          to="/earnings"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={isActive("/earnings") ? earning_white : sidebar_ruppe}
              alt="Earnings"
            />
          </span>
          Earnings
        </NavLink>

        {/* Consultations */}
        <NavLink
          to="/consultations"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={
                isActive("/consultations") ? consultations_white : consultations
              }
              alt="Consultations"
            />
          </span>
          Consultations
        </NavLink>

        {/* Feedback */}
        <NavLink
          to="/feedback"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={isActive("/feedback") ? feedback_white : feedback}
              alt="Feedback"
            />
          </span>
          Feedback & Ratings
        </NavLink>

        {/* Servicable Cities */}
        <NavLink
          to="/servicablecities"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={
                isActive("/servicablecities")
                  ? servicablecities_white
                  : servicablecities
              }
              alt="Servicable Cities"
            />
          </span>
          Servicable Cities
        </NavLink>

        {/* Profile */}
        <NavLink
          to="/profile"
          className="sidebar-option"
          activeclassname="active"
          onClick={closeSidebar}
        >
          <span className="sidebar_icon">
            <img
              src={isActive("/profile") ? profile_white : profile}
              alt="Profile"
            />
          </span>
          Profile
        </NavLink>

        {/* Logout */}
        <NavLink
          to="/"
          className="sidebar-option"
          activeclassname="active"
          onClick={handlelogout}
        >
          <span className="sidebar_icon">
            <img src={logout} alt="Logout" />
          </span>
          Logout
        </NavLink>
      </div>
      <div className="usersidebar_right">
        <Routes>
          <Route path="/dashboard" exact element={<Dash />} />
          <Route path="/appointments" element={<Appointments />} />
          <Route path="/availability" element={<Availability />} />
          <Route path="/table" element={<DataTable />} />
          <Route path="/consultations" element={<Agent_Consultations />} />
          <Route path="/earnings" element={<Agent_Earnings />} />
          <Route path="/Agent_Remarks" element={<Agent_Remarks />} />
          <Route path="/Reschedule" element={<Reschedule />} />
          <Route path="/servicablecities" element={<Cities />} />
          <Route path="/profile" element={<Personalinfo />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </div>
    </div>
  ) : null;
};

export default Sidebar;


// Layout.js
// import React from 'react';
// import { NavLink, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import Home from './Home';
// import Profile from './Profile';
// import Settings from './Settings';

// const Layout = () => {
//   return (
//     <Router>
//       <div style={{ display: 'flex' }}>
//         {/* Left Menu */}
//         <div style={{ width: '200px', background: '#eee', padding: '10px' }}>
//           <NavLink to="/home">Home</NavLink>
//           <NavLink to="/profile">Profile</NavLink>
//           <NavLink to="/settings">Settings</NavLink>
//         </div>

//         {/* Right Content */}
//         <div style={{ flex: 1, padding: '10px' }}>
//           <Switch>
//             <Route path="/home" component={Home} />
//             <Route path="/profile" component={Profile} />
//             <Route path="/settings" component={Settings} />
//           </Switch>
//         </div>
//       </div>
//     </Router>
//   );
// };

// export default Layout;
