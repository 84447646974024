import React from "react";
import { useRef, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./User_Reschedule.css";
import DatePicker, { DateObject, toDateObject } from "react-multi-date-picker";
import dateimg from "./img/date.svg";
import downarrow from "./img/downarrow.svg";
import { format } from "date-fns";
//import { type } from "@testing-library/user-event/dist/type";

function User_Reschedule() {
  const location = useLocation();
  console.log(location.state);
  const referringPageUrl = location.state?.url || "/";
  const rowData = location.state?.rowData || {};
  const [shouldCloseCalendar, setShouldCloseCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState();
  const [availabilityData, setAvailabilityData] = useState({});
  const [opened, setopened] = useState();
  const [timeslots, settimeslots] = useState();
  console.log('referringPageUrl', referringPageUrl);
  // console.log(rowData);
  const datePickerRef = useRef();
  const {
    slno,
    name: { name, profileImage },
    email,
    category,
    date,
    timeSlot,
    agentId,
  } = rowData;

  console.log('Profile Image URL:', profileImage);

  console.log(rowData, "rowData");
  console.log(date, "date");
  //   useEffect(() => {

  //     const storedData = sessionStorage.getItem('availabilityData');
  //     if (storedData) {
  //       setAvailabilityData(JSON.parse(storedData));
  //     }

  // }, []);
  useEffect(() => {
    // Load data from sessionStorage when the component mounts

    // const storedData = sessionStorage.getItem('availabilityData');
    // if (storedData) {
    //   setAvailabilityData(JSON.parse(storedData));
    // }
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/getagentavailability/${agentId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData, "availiablilitydata");

        if (jsonData.availability !== null) {
          setAvailabilityData(JSON.parse(jsonData));
          console.log(jsonData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // postData();
  }, []);
  console.log(availabilityData);
  useEffect(() => {
    settimeslots(
      availabilityData[selectedDates?.month.index]?.week[
        selectedDates?.weekDay.index
      ]
    );
    //  .week?selectedDates?.weekDay.index:[]

    if (typeof selectedDates !== "undefined") {
      setIsOpen(true);
      // console.log('clicked ')
    }
  }, [selectedDates]);

  const handledate = () => {
    datePickerRef.current.openCalendar();
    if (!shouldCloseCalendar) {
      setShouldCloseCalendar(true);
    }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState();
  const postavailData = async () => {
    console.log(availabilityData);
    try {
      const response = await fetch(
        `https://www.mypropertypal.in/administrator/agentavailability/${agentId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(availabilityData), // Your request body here
        }
      );
      const data1 = await response.json();
      console.log("resposnse from post", data1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const postData = async (date, time) => {
    console.log(availabilityData);
    const data2 = {
      postid: slno,
      bookingdate: date,
      bookingtime: time,
    };
    console.log(data2);
    try {
      const response = await fetch(
        `https://www.mypropertypal.in/administrator/postupdatebooking`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(data2), // Your request body here
        }
      );
      const data1 = await response.json();
      console.log("resposnse from post", data1);
      if (data1.message) {
        alert(data1.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error.json());
    }
  };
  const handleproceed = () => {
    UpdateSelected(selectedDates?.month.index, selectedDates, selectedTime);
    removeIndexFromMonth(
      parseInt(date.substring(5, 7), 10) - 1,
      format(date, "yyyy/MM/dd"),
      getIndexForValue(timeSlot)
    );
    console.log(timeOptions[selectedTime], "selectedtime");

    postavailData();
    let year = selectedDates.year;
    let month = (selectedDates.monthIndex + 1).toString().padStart(2, "0");
    let day = selectedDates.day.toString().padStart(2, "0");

    postData(`${year}-${month}-${day}`, timeOptions[selectedTime]);

    setSelectedOption(null);
    setSelectedDates();
  };
  const getIndexForValue = (value) => {
    return timeOptions.findIndex((option) => option === value);
  };
  const removeIndexFromMonth = (monthIndex, date, index) => {
    console.log(typeof monthIndex, "dattype");
    console.log("monthIndex", monthIndex, "date", date, "timeslot", index);
    const updatedData = { ...availabilityData };
    const selected = updatedData[monthIndex]?.selected;

    if (selected && selected[date]) {
      console.log(selected[date], index);
      const updatedDateArray = selected[date].filter(
        (i) =>
          // console.log(i,index)
          i !== index
      );

      if (updatedDateArray.length === 0) {
        // If the updated date array is empty, remove the entire selected object
        // delete selected[date];
      } else {
        // Otherwise, update the selected object with the updated date array
        availabilityData[monthIndex].selected[date] = updatedDateArray;
        console.log("updatedarray", updatedDateArray);
      }

      // setRemovedData(data[monthIndex].selected[date][index]); // Save the removed data
      setAvailabilityData(updatedData);
      // Update the state with the modified data
    } else {
      console.log(
        `Invalid index or data not found for month ${monthIndex}, date ${date}, index ${index}`
      );
    }
    console.log(availabilityData, "updated availability data");
  };
  function UpdateSelected(monthIndex, date, value) {
    // Check if the selected key exists for the month index, if not, create it
    if (!availabilityData[monthIndex].selected) {
      availabilityData[monthIndex].selected = {};
    }

    // Check if the date key exists in selected
    if (availabilityData[monthIndex].selected[date]) {
      // Check if the value already exists in the array
      if (!availabilityData[monthIndex].selected[date].includes(value)) {
        // Add value to the existing array
        availabilityData[monthIndex].selected[date].push(value);
      }
    } else {
      // Create a new array and assign it to the date key
      availabilityData[monthIndex].selected[date] = [value];
    }
    sessionStorage.setItem(
      "availabilityData",
      JSON.stringify(availabilityData)
    );
  }

  function getValuesByDate(monthIndex, date) {
    if (
      availabilityData[monthIndex] &&
      availabilityData[monthIndex].selected &&
      availabilityData[monthIndex].selected[date]
    ) {
      return availabilityData[monthIndex].selected[date];
    }
    return [];
  }
  const [selectedTime, setSelectedTime] = useState(null);
  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  const dateOptions = [
    "Hyderabad",
    "Andhra Pradesh",
    "Delhi",
    "Mumbai",
    "Bangalore",
  ];
  const timeOptions = [
    "9 AM to 10 AM",
    "10 AM to 11 AM",
    "11 AM to 12 PM",
    "12 PM to 1 PM",
    "1 PM to 2 PM",
    "2 PM to 3 PM",
    "3 PM to 4 PM",
    "4 PM to 5 PM",
    "5 PM to 6 PM",
    "6 PM to 7 PM",
    "7 PM to 8 PM",
    "8 PM to 9 PM",
  ];

  const CustomSelect = ({ options, onSelect, timeslots }) => {
    const handleSelect = (option) => {
      setSelectedOption(option);

      onSelect(option);
      // setIsOpen(false);
      console.log(options[option]);
      console.log("selectedoptiom", selectedOption);
    };

    // Call getValuesByDate outside of the JSX
    var selectedarr = [];
    if (timeslots && timeslots.length > 0)
      selectedarr = getValuesByDate(selectedDates?.month.index, selectedDates);
    // console.log(selectedarr)

    return (
      <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="booking_date">
          <div style={{ marginLeft: "1%" }}>
            {selectedOption !== null
              ? options[selectedOption]
              : "Select Time Slot"}
          </div>
          <img src={downarrow} alt="downarrow" />
        </div>
        {isOpen && (
          <div className="options_div">
            <div className="options">
              {timeslots && timeslots.length > 0 ? (
                timeslots
                  .filter((option) => !selectedarr.includes(option))
                  .map((option, index) => (
                    <div
                      key={index}
                      className="option"
                      onClick={() => handleSelect(option)}
                    >
                      {options[option]}
                    </div>
                  ))
              ) : (
                <div style={{ fontSize: "18px", color: "#011D70" }}>
                  Slots not Fixed
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="userdash d-heading-1">
      <div className="wrapper-2">
        <div className="two-col-main">
          <div className="two-col-1">
            <h1>Consultations - Reschedule</h1>
          </div>
          <div className="two-col-2">
            <button className="button-small-1">
              <Link to={decodeURIComponent(referringPageUrl)}>Cancel</Link>
            </button>
          </div>
        </div>
        <div className="border-box-main">
          <div className="tabel-scroll">
            <table className="table-white-1">
              <thead>
                <tr>
                  <th>Name</th>
                  {/* <th>Email</th> */}
                  <th>Category</th>
                  <th>Date</th>
                  <th>Time Slot</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="user-profile-cell">
                      <div className="User_Reschedule_avatar">
                        {/* <img src={profileImage} /> */}
                        <img src={`https://www.mypropertypal.in/administrator/uploads/${profileImage}`} alt="Profile" />
                      </div>
                      <span>{name}</span>
                    </div>
                  </td>
                  {/* <td>{email}</td> */}
                  <td>{category}</td>
                  <td>{format(date, "dd/MM/yyyy")}</td>
                  <td>{timeSlot}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="two-col-2-main">
            <div className="two-col-2-1">
              <div className="booking_date" onClick={handledate}>
                <div>
                  {selectedDates ? selectedDates.format() : "Select Date"}
                </div>
                <DatePicker
                  className="custom_date"
                  placeholder="for usertesting"
                  ref={datePickerRef}
                  calendarPosition="bottom-right"
                  onOpen={() => setShouldCloseCalendar(false)}
                  onClose={() => shouldCloseCalendar}
                  onChange={setSelectedDates}
                  render={<img src={dateimg} />}
                  // render={<Icon/>}
                  minDate={new Date()}
                  highlightToday={false}
                  mapDays={({
                    date,
                    currentMonth,
                    selectedDate,
                    isSameDate,
                    weekNumber,
                  }) => {
                    let props = {};

                    props.style = {
                      fontSize: "13px",
                    };

                    //   if(selectedMonthData.except){
                    //  hi=selectedMonthData.except.map(date => +date.substring(8))||[];
                    // }
                    // if ([0,6].includes(date.weekDay.index)) return  props.className= "highlight highlight-green"
                    let isWeekend = Object.keys(
                      availabilityData[currentMonth.index]?.week || {}
                    )
                      .map(Number)
                      .includes(date.weekDay.index);
                    // let isWeekend = [0,4,5,6].includes(date.weekDay.index)
                    // settimeslots(availabilityData[currentMonth.index]?.week?[weekNumber]:[])
                    if (
                      availabilityData[currentMonth.index]?.except?.includes(
                        date.format("YYYY-MM-DD")
                      )
                    ) {
                      return { disabled: true };
                    }

                    if (isWeekend)
                      props.style = {
                        ...props.style,
                        color: "green",
                        fontWeight: "bold",
                      };
                    else {
                      return { disabled: true };
                    }
                    if (isSameDate(date, selectedDate))
                      props.style = {
                        ...props.style,
                        color: "#FFFFFF",
                        backgroundColor: "#011D70",

                        borderRadius: "8px",
                      };
                    //     // console.log(hi)
                    // if (hi.includes(date.day))  return {
                    //   disabled: true,
                    //   style: { color: "#ccc" },
                    //   onClick: () => alert("sorry that date is reserved")
                    // }
                    // else props.className = "highlight highlight-green"

                    return props;
                  }}
                />
              </div>
            </div>
            <div className="two-col-2-1">
              <CustomSelect
                options={timeOptions}
                onSelect={handleTimeSelect}
                timeslots={timeslots}
              />
            </div>
          </div>
          <button onClick={handleproceed} className="find_button">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default User_Reschedule;

{
  /* <div className="select-container">
<div className="select">
  <select id="select-date" className="select-trigger">
    <option value="" disabled selected>Select Date</option>
    <option value="date1">Date 1</option>
    <option value="date2">Date 2</option>
  </select>
</div>
<div className="select">
  <select id="select-time-slot" className="select-trigger">
    <option value="" disabled selected>Select Time Slot</option>
    <option value="time1">Time Slot 1</option>
    <option value="time2">Time Slot 2</option>
  </select>
</div>
</div> */
}
