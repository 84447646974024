

import React, { useEffect, useState } from 'react';

import ruppe from './img/ruppe.png'


// import {  useNavigate } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import bigruppe from './img/bigruppe.svg'
import blueruppe from './img/blueruppe.svg'
import './Agents_Earnings.css'
const Dash = () => {
    const [consultations, setConsultations] = useState([]);
    const [totalEarnings,settotalEarnings]=useState([]);
    const [prevMonEarnings,setprevMonEarnings]=useState([]);
    const [thisMonEarnigs,setthisMonEarnings]=useState([]);
    const [earnigs,setearnings]=useState([]);
 
    // useEffect(() => {
    //     // Generate dummy data for demonstration
    //     const dummyData = Array.from({ length: 5 }, (_, index) => ({
    //       id: index + 1,
    //       consultantType: `Consultation Type ${index + 1}`,
    //       perConsultationFee: Math.floor(Math.random() * 1000),
    //       totalconsultation: Math.floor(Math.random() * 1000),  // Numeric value for demonstration
    //     }));
    
    //     setConsultations(dummyData);
    //   }, []);

     useEffect(() => {
      const fetchData = async () => {
  
        try {
          const response = await fetch(`https://www.mypropertypal.in/administrator/agentearnings/${sessionStorage.getItem('agentID')}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log('fetchblogs')
          const jsonData = await response.json();
          console.log(jsonData,'jsondata')
          if(jsonData){
            const transformedData = jsonData.earningbycategory.map(item => ({
              id: item.consId,
           
              consultantType: item.consultingType,
              perConsultationFee: item.consultingFee,
              totalconsultation: item.noofconsultations,
              totalconsultationsfee: item.totalconsultations,
            
          }
          ));
       setearnings(transformedData)
       settotalEarnings(jsonData.totalearnings.totalearnings);
       setprevMonEarnings(jsonData.prevmonthearning.prevmonthearning)
       setthisMonEarnings(jsonData.thismonthearning.thismonthearning)

          }
        
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
      }, []);
    
      const calculateTotalConsultationFee = () => {
        return consultations.reduce((total, consultation) => total + consultation.perConsultationFee, 0);
      };
    
      const calculateTotalEarnings = () => {
        return consultations.reduce((total, consultation) => {
          // For demonstration, assuming some percentage of the perConsultationFee contributes to earnings
         // Adjust as needed
         console.log(consultation.perConsultationFee,consultation.totalconsultation)
          return   consultation.perConsultationFee * consultation.totalconsultation;
        }, 0);
      };
//   const [data, setData] = useState([]);
//  const data= [
//     {
//       "title": "Total appointments so far",
//       "word1": "Upcoming",
//       "word2": "54"
//     },
//     {
//       "title": "Consultations",
//       "word1": "Scheduled",
//       "word2": "5"
//     },
//     {
//       "title": "Earning",
//       "word1": "Total",
//       "word2": "1,05,000"
//     }
//   ]
 
  
//   useEffect(() => {
//     // Fetch data from JSON file (replace with actual path)
//     fetch('path/to/data.json')
//       .then(response => response.json())
// //       .then(data => setData(data))
// //       .catch(error => console.error('Error fetching data:', error));
// //   }, []);
// const Card = ({ title, word1, word2 }) => {
//     return (
   
//       <div className="dash_card">
//         <div className="dash_card-title">{title}</div>
//         <div className="dash_card-word1">{word1}</div>
//         <div className="dash_card-word2">{word2}</div>
//       </div>
 
//     );
//   };

  return (


    <div className="userdash d-heading-1">
      <div className='wrapper-2'>
        <h1>Earnings</h1>
        <div className="userdash_cards">
          <div className="userdash_card">
              <div className="userdash_card-title">  This Month Earning </div>
              <div className="userdash_card-word1">1st Jan 24 to Till Now</div>
              <div className="userdash_card-word2" style={{color:'#6083F7'}}><img className='dash_icon' src={blueruppe}/>{thisMonEarnigs}</div>
            </div>

            <div className="userdash_card">
              <div className="userdash_card-title">  Previous Month Earning </div>
              <div className="userdash_card-word1">1st Dec 23 to 30th Dec 23</div>
              <div className="userdash_card-word2" style={{color:'#6083F7'}}><img className='dash_icon' src={bigruppe}/>{prevMonEarnings}</div>
            </div>

            <div className="userdash_card">
              <div className="userdash_card-title">  Total Earning So Far</div>
              <div className="userdash_card-word1">1st Jan 24 to Till Now</div>
              <div className="userdash_card-word2" style={{color:'#6083F7'}}><img className='dash_icon' src={bigruppe}/>{totalEarnings}</div>
            </div>

        </div>

      </div>

      <div className='wrapper-2'>
        <h2>Earning by Category</h2>

        <div className="usertable_div">
        
        <table className="usertable">
          <thead>
            <tr>
              <th>#</th>
              <th>Consultation Type</th>
              <th>Per Consultation Fee</th>
              <th>Total Consultations</th>
              <th>Total Earnings</th>
            </tr>
          </thead>
          <tbody>
            {earnigs.map((consultation) => (
              <tr key={consultation.id}>
                <td>{consultation.id}</td>
                <td>{consultation.consultantType}</td>
                <td><img src={ruppe} alt='ruppe'/> {consultation.perConsultationFee}</td>
                <td>{consultation.totalconsultation}</td>
                {/* <td><img src={ruppe} alt='ruppe'/> { consultation.perConsultationFee * consultation.totalconsultation}</td> */}
                <td><img src={ruppe} alt='ruppe'/> { consultation.totalconsultationsfee}</td>
              </tr>
            ))}
          </tbody>
        </table>

            </div>
            

      </div>
    <div className="dash">
       

      
      <div className="consultations-container">
      {/* ... Header Section ... */}
      <div className="table-container" style={{marginTop:'-5%',marginLeft:'0%'}}>
       
      </div>
    </div>

{/* <DataTable /> */}

 {/* < Reschedule/> */}
 {/* <Agent_Consultations/> */}
    </div>
    </div>
    // dash
    
  );
};

export default Dash;
