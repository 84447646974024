// Contact.js
import React from "react";
import "./footer.css";
import facebook from "./img/facebbok.svg";
import instagram from "./img/instagram.svg";
import youtubee from "./img/mypal-youtube.svg"
import twitter from "./img/mypal-twitter.svg"
import linkden from "./img/mpal-linkedin.svg"
import { NavLink, useLocation, useNavigate } from "react-router-dom";
const Footer = ({ prop_sidebar, categories  }) => {

   // console.log("jjjjjjjjjjjjjjjjjjj:" , categories )
  const navigate = useNavigate();

  const agenlogin = () => {
    clicked();
    navigate("/agent_login");
  };
  const clicked = () => {
    sessionStorage.setItem("showsidebar", "false");
    prop_sidebar("false");
  };
  const userlogin = () => {
    clicked();

    navigate("/user_login");
  };

  const handleChange = (consultationType) => {
    navigate('/category_agent', { state: { consultationType } });
    //clicked();
  };



  return (
    <div
      style={{ backgroundColor: "#011D70", paddingTop: "4%", marginTop: "0px" }}
      className="footer"
    >
      <div className="wrapper-1">
        <div className="footer-main-wrap">
          <div className="footer-main-1">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/find_agents">Find Consultants</NavLink>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/blog">Blog</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
            </ul>
          </div>
          {/* <div className="footer-main-2">
            <h3>Categories</h3>
            <ul>
               <li><NavLink to="/">Home Buying and Selling</NavLink></li>
            <li><NavLink to="/">Property Management</NavLink></li>
            <li><NavLink to="/">Legal and Regulatory Compliance</NavLink></li>
            <li><NavLink to="/">Real Estate Development</NavLink></li>
            <li><NavLink to="/">International Real Estate</NavLink></li>
            <li><NavLink to="/">Market Research and Analysis</NavLink></li>
            <li><NavLink to="/">Investment Properties</NavLink></li>
            <li><NavLink to="/">Commercial Real Estate</NavLink></li>
            <li><NavLink to="/">Real Estate Financing</NavLink></li>
            <li><NavLink to="/">Real Estate Technology</NavLink></li> 

              {categories.map((category) => (
                <li key={category.id} onClick={clicked}>
                  <NavLink
                    to={`/category/${category.id}`}
                    activeClassName="active"
                    onClick={() => handleChange(category.consultingType)}
                  >
                    {category.consultingType}
                  </NavLink>
                </li>
              ))}

            </ul>
          </div> */}
          <div className="footer-main-1">
            <h3>Categories</h3>
            <ul>
            {categories.map((category) => (
                <li key={category.id} onClick={clicked}>
                  <NavLink
                    to={`/category/${category.id}`}
                    activeClassName="active"
                    onClick={() => handleChange(category.consultingType)}
                  >
                    {category.consultingType}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          {/* cities */}
          <div className="footer-main-3">
            <h3>Cities</h3>
            <ul>
              <li>
                <NavLink to="/find_agents">Hyderabad</NavLink>
              </li>
              <li>
                <NavLink to="/find_agents">Kolkata</NavLink>
                {/* <NavLink to="/">User Login</NavLink> */}
              </li>
              {/* <li>
                <NavLink to="/blog">FAQs</NavLink>
              </li>
              <li>
                <NavLink to="/agent_terms_&_conditons">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/agent_privacy_&_policy">Privacy Policy</NavLink>
              </li> */}
            </ul>
          </div>

          <div className="footer-main-3">
            <h3>Other Links</h3>
            <ul>
              <li>
                <NavLink to="/agent_login">Consultant Login</NavLink>
              </li>
              <li>
                <NavLink to="/user_login">User Login</NavLink>
              </li>
              <li>
                <NavLink to="/blog">FAQs</NavLink>
              </li>
              <li>
                <NavLink to="/terms_&_conditons">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy_&_policy">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/disclamer">Disclamer</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="wrapper-1">
        <div className="footer-copy-wrap">
          <div className="footer-copy-1">
            Copyright @ 2024. MYPROPERTYPAL. All Rights Reserved. | Concept &
            Design by eparivartan
          </div>
          <div className="footer-copy-2">
            <img src={facebook} />
            <img style={{ paddingLeft: "20px" }} src={instagram} />
            <img style={{ paddingLeft: "20px" }} src={youtubee} />
            <img style={{ paddingLeft: "20px" }} src={linkden} />
            <img style={{ paddingLeft: "20px"  }} src={twitter} />
          </div>
        </div>
      </div>

      {/* <div className='footer_routes' style={{color:'white'}}>
                <div className='footer_quick'>
                    <p className='footer_p1'>Quick Links</p>
                    <p className='footer_p2'>Home</p>
                    <p className='footer_p2'>Find Agents</p>
                    <p className='footer_p2'>About Us</p>
                    <p className='footer_p2'>Blog</p>
                    <p className='footer_p2'>Contact Us</p>
                </div>
                <div className='footer_categories'>
                <p className='footer_p1' style={{marginBottom:'4%'}}>Categories</p>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <div >
             
                    <p className='footer_p3'>Home Buying and Selling</p>
                    <p className='footer_p3'>Legal and Regulatory Compliance</p>
                    <p className='footer_p3'>International Real Estate</p>
                    <p className='footer_p3'>Investment Properties</p>
                    <p className='footer_p3'>Real Estate Financing</p>

                    </div>
                    <div style={{paddingLeft:'10%'}}>
                  
                    <p className='footer_p3'>Property Management</p>
                    <p className='footer_p3'>Real Estate Development</p>
                    <p className='footer_p3'>Market Research and Analysis</p>
                    <p className='footer_p3'>Commercial Real Estate</p>
                    <p className='footer_p3'>Real Estate Technology</p>

                    </div>
                </div>
                </div>
                <div className='footer_other'>
                <p className='footer_p1'>Other Links</p>
           
                    <p  onClick={agenlogin}className='footer_p2'>Agent Login</p>
                    <p  onClick={userlogin}className='footer_p2'>User Login</p>
                    <p className='footer_p2'>FAQS</p>
                    <p className='footer_p2'>Terms & Conditions</p>
                    <p className='footer_p2'>Privacy Policy</p>
                </div>
         </div> */}
      {/* footer_routes */}
    </div>
  );
};

export default Footer;
