// Header.js
import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./Header.css"; // Import the CSS file
import logo from "./img/logo.png";
import ruppe from "./img/ruppe.png";
import Sidebar from "./Sidebar";
import User_sidebar from "./user/User_sidebar";

import "./Navigation.css";

const Header = ({ isLoggedIn, prop_sidebar, jsonData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldShowHeader, setshouldShowHeader] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("Category");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showsidebar, setshowsidebar] = useState(false);
  const [carousel_data, setcarousel_data] = useState([]);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  // console.log("on fetch data")
    // const fetchcategories = async () => {

    //   try {
    //     const response = await fetch('https://www.mypropertypal.in/administrator/listcategory/');
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     const jsonData = await response.json();
    //     // console.log(jsonData.categories,'headerjsondata')
    //     setcarousel_data(jsonData.categories)

    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    // fetchcategories();


  // useEffect(() => {
    
  //   if (jsonData.length > 0) {
  //     setcarousel_data(jsonData);
  //   }
  // }, [jsonData]);

  const categories = [
    { value: "Land", label: "Land" },
    { value: "Apartment", label: "Apartment" },
    { value: "Villa", label: "Villa" },
    { value: "Rentle", label: "Rentle" },
  ];

  // const handleChange = (event) => {
  //   navigate("/category_agent", {
  //     state: { consultationType: event.target.value },
  //   });
  //   clicked();
  // };

  const handleChange = (consultationType) => {
    navigate('/category_agent', { state: { consultationType } });
    //clicked();
  };

  const handleDashboard = () => {
    setShowNavbar(!showNavbar);
    setshouldShowHeader(false);
    navigate("/dashboard");
    setshowsidebar("true");
    prop_sidebar("true");
    sessionStorage.setItem("showsidebar", "true");
  };

  const handleUserDashboard = () => {
    navigate("/User_sidebar");
  };

  const handleLoginSignUpClick = () => {
    navigate("/user_login");
    setMobileMenuOpen(false); // Close the mobile menu after navigation
  };
  const clicked = () => {
    sessionStorage.setItem("showsidebar", "false");
    prop_sidebar("false");
    setShowNavbar(!showNavbar);
  };

  return (
    <div>
      <div class="fix_header nav-no-js">
        <header>
          <div class="wrapper-1">
            <div class="tz-t1">
              <div class="tz-r1">
                <div class="tz-c1">
                  <NavLink to="/" onClick={clicked}>
                    <div className="logoOuter" >
                      <img src={logo} alt="Logo" />
                    </div>
                  </NavLink>
                </div>
                <div class="tz-c1">
                  <div class="nav-main">
                    <div
                      className="menu-icon nav-button22"
                      onClick={handleShowNavbar}
                    >
                      {" "}
                    </div>
                    <nav className={`nav-elements  ${showNavbar && "active"}`}>
                      <ul>
                        <li onClick={scrollToTop}>
                          <NavLink to="/" onClick={clicked}>
                            Home
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/find_agents" onClick={clicked}> Find Consultants </NavLink>
                        </li>
                        <li onClick={scrollToTop}>
                          <NavLink to="/about" onClick={clicked}>About Us</NavLink>
                        </li>

                        <li>
                          Category
                          <ul>
                          {jsonData.map((category) => (
            <li key={category.id} onClick={clicked}>
             <NavLink 
              to={`/category/${category.id}`} 
              activeClassName="active" 
              onClick={() => handleChange(category.consultingType)} 
            >
              {category.consultingType}
            </NavLink>
            </li>
          ))}
                          </ul>
                          {/* <select
            value={selectedCategory}
            onChange={handleChange}
            style={{ fontFamily: "poppins_med", outline: "none" }}
          >
            <option value="Category" disabled hidden>
              Category
            </option>
            {carousel_data.map((category) => (
              <option key={category.value} value={category.id}>
                {category.consultingType}
              </option>
            ))}
          </select>

           */}
                        </li>

                        <li onClick={scrollToTop}>
                          <NavLink to="/blog" onClick={clicked}>Faq's</NavLink>
                        </li>
                        <li onClick={scrollToTop}>
                          <NavLink to="/contact" onClick={clicked}>Contact Us</NavLink>
                        </li>
                        <li >
                          {sessionStorage.getItem("isloggedin") === "true" ? (
                            <>
                              <button onClick={handleDashboard} className="button-a-2">
                                Dashboard
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={handleLoginSignUpClick}
                                className="button-a-2"
                              >
                                Login/SignUp
                              </button>
                            </>
                          )}
                        </li>

                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>

      {/* <header className={`header ${mobileMenuOpen ? "mobile-menu-open" : ""}`}>
        <div className="brand">
          <img src={logo} alt="Logo" />
        </div>

        <div className={`menu ${mobileMenuOpen ? "open" : ""}`}>
          <NavLink exact to="/" activeClassName="active-link" onClick={clicked}>
            Home
          </NavLink>
          <NavLink
            to="/find_agents"
            activeClassName="active-link"
            onClick={clicked}
          >
            Find Agents
          </NavLink>
          <select
            value={selectedCategory}
            onChange={handleChange}
            style={{ fontFamily: "poppins_med", outline: "none" }}
          >
            <option value="Category" disabled hidden>
              Category
            </option>
            {carousel_data.map((category) => (
              <option key={category.value} value={category.id}>
                {category.consultingType}
              </option>
            ))}
          </select>
        </div>

        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          <img src={ruppe} />
        </div>
      </header> */}
    </div>
  );
};

export default Header;
