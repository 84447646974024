// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import './user_signup.css';
// import Image2 from './img/userbg.png';
// import User_terms from './user_tems.js'
// import { parseJSON } from 'date-fns';

// const User_signup = () => {
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [emailAddress, setEmailAddress] = useState('');
//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [reraId, setReraId] = useState('');
//   const [associationCertificate, setAssociationCertificate] = useState();
//   const [reracertificate, setreracertificate] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [agreeTerms, setAgreeTerms] = useState();
//   const [formErrors, setFormErrors] = useState({});
//   const [showTermsPopup, setShowTermsPopup] = useState(false);
//   const [associationMember, setAssociationMember] = useState('');

//   const validateForm = () => {
//     const errors = {};

//     if (!firstName.trim()) {
//       errors.firstName = 'First Name is required';
//     }

//     if (!lastName.trim()) {
//       errors.lastName = 'Last Name is required';
//     }

//     if (!emailAddress.trim()) {
//       errors.emailAddress = 'Email Address is required';
//     }

//     if (!phoneNumber.trim()) {
//       errors.phoneNumber = 'Phone Number is required';
//     }


//     if (!password.trim()) {
//       errors.password = 'Password is required';
//     }
//     if (!confirmPassword.trim()) {
//       errors.confirmPassword = 'Confirm Password is required';
//     }
//     if (password !== confirmPassword) {
//       errors.confirmPassword = 'Passwords do not match';
//     }

//     setFormErrors(errors);
//     return Object.keys(errors).length === 0;
//   };

//   const handleSignUp = () => {
//     if (validateForm() && agreeTerms) {
//       const formData = {
//         firstname : firstName,
//         lastname: lastName,
//         email: emailAddress,
//         phonenumber: phoneNumber,
//      //   reraId:reraId,
//         //associationmember:associationMember,
//        // reracertificate: reracertificate,
//        // associationcertificate: associationCertificate,
//        password: password,
//       };
//       console.log(formData);
//       fetch('https://www.mypropertypal.in/administrator/register', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
        
//         },
//         body:JSON.stringify(formData),
        
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           console.log('Server response:', data);
//           // Handle the response as needed
//         })
//         .catch((error) => {
//           console.error('Error:', error);
//         });
//       // console.log('Form submitted:', formData);
//     } else {
//       alert('Please fill all');
//     }
//   };

//   const handleReraFileChange = (e) => {
//     setreracertificate(e.target.files[0]);
//   };

//   const handleAssociationFileChange = (e) => {
//     setAssociationCertificate(e.target.files[0]);
//   };

//   const handleRemoveReraFile = () => {
//     setreracertificate(null);
//   };

//   const handleRemoveAssociationFile = () => {
//     setAssociationCertificate(null);
//   };


//   const handleTermsClick = () => {
//     setShowTermsPopup(true);
//   };

//   const closeTermsPopup = () => {
//     setShowTermsPopup(false);
//   };

//   const handleCheckboxChange = () => {
//     setAgreeTerms(!agreeTerms);
//   };

//   return (
//     <div className="Agent_signup" style={{ backgroundImage: `url(${Image2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
//       <form className="userlogin_form" >
//       <h1>User Signup</h1>
//         {showTermsPopup && <User_terms onClose={closeTermsPopup} />}
//         <input type="text"  placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input-field-2" />
//         {formErrors.firstName && <p className="error-message">{formErrors.firstName}</p>}

//         <input type="text"  placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input-field-2" />
//         {formErrors.lastName && <p className="error-message">{formErrors.lastName}</p>}

//         <input type="email"  placeholder="Email Address" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className="input-field-2" />
//         {formErrors.emailAddress && <p className="error-message">{formErrors.emailAddress}</p>}

//         <input type="number"  placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="input-field-2" />
//         {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}

//         {/* <div className="file_upload">
//           <input type="text" style={{ height: '2vw', width: '70%' }} placeholder="RERA ID" value={reraId} onChange={(e) => setReraId(e.target.value)} className="input-field-2" />
         
          
//             <label style={{ textAlign:'center',marginLeft: '1%', border: '2px solid #6083F7', height: '15px', width: '30%', borderRadius: '0.5vw', marginTop: '1.5%', fontSize: '0.7vw', padding: '3%', overflow: 'auto' }}>
//               Upload RERA Certificate
//               <input
//                 type="file"
//                 accept=".pdf,.doc,.docx"
//                 onChange={handleReraFileChange}
//                 style={{ display: 'none' }}
//               />
//             </label>
       
          
//         </div>
//         {reracertificate && (
//             <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",width:'100%',alignItems:'center'}}>
//               <p>{reracertificate.name}</p>
//               <button onClick={handleRemoveReraFile} style={{padding:'1.5%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',}}>Remove</button>
//             </div>
//           )}
//         {formErrors.reraId && <p className="error-message">{formErrors.reraId}</p>}
//         <div className="file_upload">
//           <input type="text" style={{ height: '2vw', width: '70%' }} placeholder="Association Member" value={associationMember} onChange={(e) => setAssociationMember(e.target.value)} className="input-field-2" />
          
          
//             <label style={{ textAlign:'center', marginLeft: '1%', border: '2px solid #6083F7', height: '15px', width: '30%', borderRadius: '0.5vw', marginTop: '1.5%', fontSize: '0.7vw', padding: '3%'}}>
//               Upload Certificate
//               <input
//                 type="file"
//                 accept=".pdf,.doc,.docx"
//                 onChange={handleAssociationFileChange}
//                 style={{ display: 'none' }}
//               />
//             </label>
        
//         </div>
//         {associationCertificate && (
//             <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",width:'100%',alignItems:'center'}}>
//               < p   >{associationCertificate.name}</p>
//               <button onClick={handleRemoveAssociationFile} style={{padding:'1.5%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',}}>Remove</button>
//             </div>
//           )} */}

//         <input type="password"  placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="input-field-2" required />
//         {formErrors.password && <p className="error-message">{formErrors.password}</p>}

//         <input type="password"  placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="input-field-2" />
//         {formErrors.confirmPassword && <p className="error-message">{formErrors.confirmPassword}</p>}

//         <div style={{ textAlign: "left", width: "100%", padding: "10px 0px 0px 0px", }}  >
//           <input type="checkbox" id="agreeTerms" checked={agreeTerms} onChange={handleCheckboxChange} required />
//           <label htmlFor="agreeTerms">
//             I agree to the <span onClick={handleTermsClick} style={{ textDecoration: 'underline', cursor: 'pointer',color:'#6083F7' }}>Terms and Conditions</span>
//           </label>
//         </div>

//         <button onClick={handleSignUp} className="find_button button-3-padding">
//           Sign Up
//         </button>

//         <p>
//           Already a member yet? <NavLink to="/user_login" className="signup-link">Login Now</NavLink>
//         </p>
//       </form>
//     </div>
//   );
// };

// export default User_signup;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './user_signup.css';
import Image2 from './img/userbg.png';
import User_terms from './user_tems.js'
import { parseJSON } from 'date-fns';

const User_signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [reraId, setReraId] = useState('');
  const [associationCertificate, setAssociationCertificate] = useState();
  const [reracertificate, setreracertificate] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [associationMember, setAssociationMember] = useState('');

  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    if (!lastName.trim()) {
      errors.lastName = 'Last Name is required';
    }

    if (!emailAddress.trim()) {
      errors.emailAddress = 'Email Address is required';
    }

    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required';
    }


    if (!password.trim()) {
      errors.password = 'Password is required';
    }
    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'Confirm Password is required';
    }
    if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSignUp = () => {
    if (validateForm() && agreeTerms) {
      const formData = {
        firstname: firstName,
        lastname: lastName,
        email: emailAddress,
        phonenumber: phoneNumber,
        reraId:reraId,
        associationmember:associationMember,
        reracertificate: reracertificate,
        associationcertificate: associationCertificate,
        password: password,
      };
      console.log(formData);
      fetch('https://www.mypropertypal.in/administrator/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        
        },
        body:JSON.stringify(formData),
        
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Server response:', data);
          // Handle the response as needed
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      // console.log('Form submitted:', formData);
    } else {
      alert('Please fill all');
    }
  };

  const handleReraFileChange = (e) => {
    setreracertificate(e.target.files[0]);
  };

  const handleAssociationFileChange = (e) => {
    setAssociationCertificate(e.target.files[0]);
  };

  const handleRemoveReraFile = () => {
    setreracertificate(null);
  };

  const handleRemoveAssociationFile = () => {
    setAssociationCertificate(null);
  };


  const handleTermsClick = () => {
    setShowTermsPopup(true);
  };

  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  };

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  return (
    <div className="Agent_signup" style={{ backgroundImage: `url(${Image2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      <form className="userlogin_form">
      <h1>User Signup</h1>
        {showTermsPopup && <User_terms onClose={closeTermsPopup} />}
        <input type="text"  placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="input-field-2" />
        {formErrors.firstName && <p className="error-message">{formErrors.firstName}</p>}

        <input type="text"  placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="input-field-2" />
        {formErrors.lastName && <p className="error-message">{formErrors.lastName}</p>}

        <input type="email"  placeholder="Email Address" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} className="input-field-2" />
        {formErrors.emailAddress && <p className="error-message">{formErrors.emailAddress}</p>}

        <input type="number"  placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="input-field-2" />
        {formErrors.phoneNumber && <p className="error-message">{formErrors.phoneNumber}</p>}

        {/* <div className="file_upload">
          <input type="text" style={{ height: '2vw', width: '70%' }} placeholder="RERA ID" value={reraId} onChange={(e) => setReraId(e.target.value)} className="input-field-2" />
         
          
            <label style={{ textAlign:'center',marginLeft: '1%', border: '2px solid #6083F7', height: '15px', width: '30%', borderRadius: '0.5vw', marginTop: '1.5%', fontSize: '0.7vw', padding: '3%', overflow: 'auto' }}>
              Upload RERA Certificate
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleReraFileChange}
                style={{ display: 'none' }}
              />
            </label>
       
          
        </div>
        {reracertificate && (
            <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",width:'100%',alignItems:'center'}}>
              <p>{reracertificate.name}</p>
              <button onClick={handleRemoveReraFile} style={{padding:'1.5%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',}}>Remove</button>
            </div>
          )}
        {formErrors.reraId && <p className="error-message">{formErrors.reraId}</p>}
        <div className="file_upload">
          <input type="text" style={{ height: '2vw', width: '70%' }} placeholder="Association Member" value={associationMember} onChange={(e) => setAssociationMember(e.target.value)} className="input-field-2" />
          
          
            <label style={{ textAlign:'center', marginLeft: '1%', border: '2px solid #6083F7', height: '15px', width: '30%', borderRadius: '0.5vw', marginTop: '1.5%', fontSize: '0.7vw', padding: '3%'}}>
              Upload Certificate
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleAssociationFileChange}
                style={{ display: 'none' }}
              />
            </label>
        
        </div>
        {associationCertificate && (
            <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",width:'100%',alignItems:'center'}}>
              < p   >{associationCertificate.name}</p>
              <button onClick={handleRemoveAssociationFile} style={{padding:'1.5%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',}}>Remove</button>
            </div>
          )} */}

        <input type="password"  placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="input-field-2" required />
        {formErrors.password && <p className="error-message">{formErrors.password}</p>}

        <input type="password"  placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="input-field-2" />
        {formErrors.confirmPassword && <p className="error-message">{formErrors.confirmPassword}</p>}

        <div style={{ textAlign: "left", width: "100%", padding: "10px 0px 0px 0px", }}  >
          <input type="checkbox" id="agreeTerms" checked={agreeTerms} onChange={handleCheckboxChange} required />
          <label htmlFor="agreeTerms">
            I agree to the <span onClick={handleTermsClick} style={{ textDecoration: 'underline', cursor: 'pointer',color:'#6083F7' }}>Terms and Conditions</span>
          </label>
        </div>

        <button onClick={handleSignUp} className="find_button button-3-padding">
          Sign Up
        </button>

        <p>
          Already a member yet? <NavLink to="/user_login" className="signup-link">Login Now</NavLink>
        </p>
      </form>
    </div>
  );
};

export default User_signup;
