import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./User_sidebar.css"; //
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Dash from "./User_Dash";
import dashboard from "./img/dashboard.svg";
import consultations from "./img/consultations.svg";
import feedback from "./img/feedback.svg";
import profile from "./img/profile.svg";
import availability from "./img/avaliability.svg";
import servicablecities from "./img/servicablecities.svg";
import sidebar_ruppe from "./img/sidebar_ruppe.svg";
import sidebar_logo from "./img/sidebar_logo.svg";
import logout from "./img/logout.svg";
import appointments from "./img/appointments_grey.svg";
// import DataTable from "./Appointments.js"
import User_Consultations from "./User_Consultations";
import User_Payment from "./User_Payment.js";
// import Agent_Remarks from './Agent_Remarks'
// import Reschedule  from './Reschedule';
// import Appointments from './Appointments';
// import Availability from './Agents_Avaliabillity.js'
import dashboard_white from "./img/dashboard_white.svg";
import appointments_white from "./img/appointments_white.svg";
import consultations_white from "./img/consultations_white.svg";
import feedback_white from "./img/feedback_white.svg";
import profile_white from "./img/profile_white.svg";
import availability_white from "./img/avaliabillity_white.svg";
import servicablecities_white from "./img/servicable_white.svg";
import earning_white from "./img/earning_white.svg";
// import Appointments from './Agents_Avaliability.js'
// import Cities from './cities.js'
// import Personalinfo from './personalinfo.js'
// import Feedback from './feedback.js'
import sidebar_footer from "./img/user_siderbar.png";
import USer_Remarks from "./User_Remarks";
import User_Profile from "./User_Profile";
import User_Reschedule from "./User_Reschedule";
import User_Feedback from "./User_Feedback";

const User_Sidebar = ({ prop_logout }) => {

  const [isOpen, setIsOpen] = useState(false);

  const menuHandleClick = () => {
    setIsOpen(!isOpen);
    
  };


  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  

  const [isloggedout, setisloggedout] = useState(false);
  const handlelogout = () => {
    setisloggedout("true");
    prop_logout("true");
    sessionStorage.setItem("isloggedout", "true");
    sessionStorage.setItem("isloggedin", "false");
    sessionStorage.setItem("showsidebar", "false");
    sessionStorage.setItem("userID", "");
  };
  const location = useLocation();
  const isActive = (path) => {
    return location.pathname === path;
  };
 
  const path = location.pathname;

  // Define paths where the sidebar should not be displayed
  const pathsToHideSidebar = ["/agent_login", 
  "/user_login", 
  "/blog", "/terms_&_conditons", 
  "/privacy_&_policy",
    "/",
    "/find_agents",
    "/about",
    "/contact",
    "/disclamer",
];

  // Check if the current path is in the array of paths to hide the sidebar
  const shouldDisplaySidebar = !pathsToHideSidebar.includes(path);

  if (!shouldDisplaySidebar) {
    return null; // Do not render the sidebar
  }

  return (
    <div className={`usersidebar ${isSidebarOpen ? "sidebar-open" : ""}`} >
      {/* <button className="toggle-button" onClick={toggleSidebar}>
      Navigation22
      </button> */}

      <div className="usersidebar_left">
      <div className="cross-mark" onClick={toggleSidebar}>
          <div
            className={`menu-icon ${isOpen ? "open" : ""}`}
            onClick={menuHandleClick}
          >
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
          </div>
        </div>
        {/* <img style={{marginBottom:'30%',marginTop:'15%'}} src={sidebar_logo}/> */}
        <div style={{}}>
          {/* Dashboard */}
          <NavLink
            to="/dashboard"
            className="usersidebar-option"
            activeclassname="active"
            onClick={toggleSidebar}
          >
            <span className="usersidebar_icon">
              <img
                src={isActive("/dashboard") ? dashboard_white : dashboard}
                alt="Dashboard"
              />
            </span>
            Dashboard
          </NavLink>

          {/* Consultations */}
          <NavLink
            to="/userconsultations"
            className="usersidebar-option"
            activeclassname="active"
            onClick={toggleSidebar}
          >
            <span className="usersidebar_icon">
              <img
                src={
                  isActive("/userconsultations")
                    ? consultations_white
                    : consultations
                }
                alt="Consultations"
              />
            </span>
            Consultations
          </NavLink>

          {/* Earnings */}
          <NavLink
            to="/User_Payment"
            className="usersidebar-option"
            activeclassname="active"
            onClick={toggleSidebar}
          >
            <span className="usersidebar_icon">
              <img
                src={isActive("/earnings") ? earning_white : sidebar_ruppe}
                alt="Earnings"
              />
            </span>
            Payment History
          </NavLink>
          {/* Profile */}
          <NavLink
            to="/profile"
            className="usersidebar-option"
            activeclassname="active"
            onClick={toggleSidebar}
          >
            <span className="usersidebar_icon">
              <img
                src={isActive("/profile") ? profile_white : profile}
                alt="Profile"
              />
            </span>
            Profile
          </NavLink>

          {/* Logout */}
          <NavLink
            to="/"
            className="usersidebar-option"
            activeclassname="active"
            onClick={handlelogout}
          >
            <span className="usersidebar_icon">
              <img src={logout} alt="Logout" />
            </span>
            Logout
          </NavLink>

          {/* Add other menu options here */}
          {/* <NavLink to="/test1" className="usersidebar-option" activeclassnmae="active"  onClick={toggleSidebar}>
        <span className="usersidebar_icon">  <img src={logout}/></span>
      
        test
      </NavLink> */}
        </div>
        <div>
          {/* <img  style={{width:'100%',height:'100%'}}src={sidebar_footer}/> */}
        </div>
      </div>

      <div className="usersidebar_right">
        <Routes>
          <Route path="/dashboard" exact element={<Dash />} />
          {/* <Route path="/appointments" element={<Appointments  />} /> */}
          {/* <Route path="/availability" element={<Availability />} /> */}
          {/* <Route path="/table" element={<DataTable />}/> */}
          <Route path="/userconsultations" element={<User_Consultations />} />
          <Route path="/User_Payment" element={<User_Payment />} />
          <Route path="/User_Feedback" element={<User_Feedback />} />
          <Route path="/User_Remarks" element={<USer_Remarks />} />
          {/* <Route path="/servicablecities" element={<Cities />} /> */}
          <Route path="/profile" element={<User_Profile />} />
          {/* <Route path="/feedback" element={<Feedback />} /> */}
          <Route path="/User_Reschedule" element={<User_Reschedule />} />
        </Routes>
      </div>
      {/* sidebar rigth */}
    </div>
  );
};

export default User_Sidebar;

// Layout.js
// import React from 'react';
// import { NavLink, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import Home from './Home';
// import Profile from './Profile';
// import Settings from './Settings';

// const Layout = () => {
//   return (
//     <Router>
//       <div style={{ display: 'flex' }}>
//         {/* Left Menu */}
//         <div style={{ width: '200px', background: '#eee', padding: '10px' }}>
//           <NavLink to="/home">Home</NavLink>
//           <NavLink to="/profile">Profile</NavLink>
//           <NavLink to="/settings">Settings</NavLink>
//         </div>

//         {/* Right Content */}
//         <div style={{ flex: 1, padding: '10px' }}>
//           <Switch>
//             <Route path="/home" component={Home} />
//             <Route path="/profile" component={Profile} />
//             <Route path="/settings" component={Settings} />
//           </Switch>
//         </div>
//       </div>
//     </Router>
//   );
// };

// export default Layout;
