import React, { useState, useEffect } from "react";
import "./personalinfo.css"; // Import CSS file for component styling
//import AgentImage from './img/agent1.png'; // Import image for profile
import cam from "./img/cam.png";
//import profile from './img/profile.png'

export default function PersonalInfo() {
  // const [cpassword, setcpassword] = useState('')
  // const [reracertificate,setreracertificate]=useState(null)
  // const [associationcertificate,setassociationcertificate]=useState(null)
  // const [formData, setFormData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   phonenumber: '',
  //   reraid: '',
  //   reracertificate:null,
  //   associationmember:'',
  //   associationcertificate:null,
  //   briefintro: '',
  //   address: '',
  //   state: '',
  //   city: '',
  //   pincode: '',
  //   password: '',
  //   profile: ''
  // });

  const [cpassword, setcpassword] = useState("");
  // const [reracertificate,setreracertificate]=useState(null)
  // const[associationcertificate,setassociationcertificate]=useState(null)
  //const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    agentid: "",
    firstName: "",
    lastName: "",
    email: "",
    phonenumber: "",
    reraid: "",
    reracertificate: "",
    associationmember: "",
    associationcertificate: "null",
    briefintro: "",
    areapreference: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    password: "",
    noofdeals : "",
    profile: "null",
  });

  const [passwordMismatch, setPasswordMismatch] = useState(false);

  // fetching user login data starts
  useEffect(() => {
    // Fetch data dynamically (sample)
    const fetchData = async () => {
      try {
        // Sample fetch request
        const agentid = sessionStorage.getItem("agentID");
        if (!agentid) {
          throw new Error("Agent ID not found in sessionStorage");
        }
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/my-profile/${agentid}`
        );

        const data = await response.json();

        // console.log('API response:', data);
        // Update form data with fetched data
        setFormData((prevFormData) => ({
          ...prevFormData,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phonenumber: data.phonenumber,
          reraid: data.reraid,
          reracertificate: data.reracertificate,
          associationmember: data.associationmember,
          associationcertificate: data.associationcertificate,
          briefintro: data.briefintro,
          areapreference: data.areapreference,
          address: data.address,
          state: data.state,
          city: data.city,
          pincode: data.pincode,
          password: data.password,
          profile: data.profile,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // const fetchData = async () => {
  //   try {
  //     // Sample fetch request
  //     const response = await fetch('https://www.mypropertypal.in/administrator/my-profile/1');
  //     const data = await response.json();

  //     // Update form data with fetched data
  //     setFormData(data);
  //     console.log("fetsched ata",data)
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  ///removing files change
  const handleRemoveFile = (name) => {
    if (name === "reracertificate") {
      setFormData({
        ...formData,
        [name]: null,
      });
    } else if (name === "associationcertificate") {
      setFormData({
        ...formData,
        [name]: null,
      });
    } else {
      setFormData({
        ...formData,
        [name]: "",
      });
    }
  };

  // const handleRemoveFile = (name) => {
  //   if(name==='reracertificate')
  //   setreracertificate(null)
  // else
  // setassociationcertificate(null)
  //   setFormData({
  //     ...formData,
  //     [name]: '',
  //   });
  // };

  // const handleFileChange = (e, type) => {
  //   const file = e.target.files[0];
  //   if(type==='reracertificate')
  //   setreracertificate(type)
  // else if(type === 'associationcertificate'){
  // setassociationcertificate(type)
  // }

  //   setFormData({
  //     ...formData,
  //     [type]: file,
  //   });
  // };

  // handle reras certificate file change for files
  
  const onhandlererafilechange = (e) => {
    const file = e.target.files[0];
    // Update formData state with the selected file
    setFormData((prevState) => ({
      ...prevState,
      reracertificate: file,
    }));
    //   console.log(formData.reracertificate)
  };

  // handle association file change
  const onhandleassociationfilechange = async (e) => {
    const file = e.target.files[0];
    // Update formData state with the selected file
    setFormData((prevState) => ({
      ...prevState,
      associationcertificate: file,
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Check if password and confirm password match
  // //   if(formData.password !== undefined){
  // //     console.log("true",formData.password)
  // //   if (formData.password !== cpassword) {
  // //     setPasswordMismatch(true);
  // //     return;
  // //   }
  // // }

  // const formData1 = new FormData();

  // if (reracertificate !== null) {
  //   formData1.append('reracertificate', formData.reracertificate);
  // }
  // if (associationcertificate !== null) {
  //   formData1.append('associationcertificate', formData.associationcertificate);
  // }
  // formData1.append('firstName', formData.firstName);
  // formData1.append('lastName', formData.lastName);
  // formData1.append('email', formData.email);
  // formData1.append('phonenumber', formData.phonenumber);
  // formData1.append('reraid', formData.reraid);
  // formData1.append('associationmember', formData.associationmember);
  // formData1.append('briefintro', formData.briefintro);
  // formData1.append('address', formData.address);
  // formData1.append('state', formData.state);
  // formData1.append('city', formData.city);
  // formData1.append('pincode', formData.pincode);
  // formData1.append('password', formData.password);
  // for (let pair of formData1.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }
  // console.log(formData1)
  //   try {
  //     const response = await fetch('https://www.mypropertypal.in/administrator/Home/updateprofile', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(formData1),
  //     });
  //     const data = await response.json();
  //     console.log('Data posted successfully:', data);
  //   } catch (error) {
  //     console.error('Error posting data:', error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const agentid = sessionStorage.getItem("agentID");
    if (!agentid) {
      console.error("User ID not found in sessionStorage");
      return;
    }

    const {
      firstName,
      lastName,
      email,
      phonenumber,
      reraid,
      associationmember,
      briefintro,
      areapreference,
      address,
      state,
      city,
      pincode,
      noofdeals,
      password,
    } = formData;

    const NewAgentProfileData = {
      agentid,
      firstName,
      lastName,
      email,
      phonenumber,
      reraid,
      associationmember,
      briefintro,
      areapreference,
      address,
      state,
      city,
      pincode,
      password,
      noofdeals,
    };

    console.log("new:", NewAgentProfileData);

    // console.log("new:",combinedFromData.reracertificate)
    const apiUrl = "https://www.mypropertypal.in/administrator/updateprofile";
    const options = {
      method: "POST",
      body: JSON.stringify(NewAgentProfileData),
    };
    try {
      const response = await fetch(apiUrl, options);
      console.log(response);
      if (response.ok === true) {
        const data = await response.json();
        console.log("data:", data);
        if (data) {
          console.log("Agent Profile updated successfully");
          // Save updated data in sessionStorage
          // sessionStorage.setItem('agentData', JSON.stringify(formData));
        } else {
          console.log("Failed to update agent profile");
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }

    // reracertidicate file :
    const file = formData.reracertificate;
    if (!file) {
      alert("Please select a file.");
      return;
    }
    try {
      // Create a new FormData object and append the file and agentid
      const formData = new FormData();
      formData.append("reracertificate", file);

      console.log("FormData:", formData);

      // Send the FormData object to the server
      const response = await fetch(
        `https://www.mypropertypal.in/administrator/updateagentrera/${agentid}`,
        {
          method: "POST",
          body: formData,
        }
      );

      console.log("response:", response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Data", data);

      if (data && data.error) {
        console.error("Server Error:", data.error);
        // Display error message to user or handle it as needed
      } else {
        console.log("Server Response:", data);
        // Process the response data or handle success
      }
    } catch (error) {
      console.error("Upload error:", error);
    }

    /// association file submitting
    const associationfile = formData.associationcertificate;
    if (!associationfile) {
      alert("Please select a file.");
      return;
    }
    try {
      // Create a new FormData object and append the file and agentid
      const formData = new FormData();
      formData.append("associationcertificate", associationfile);

      console.log("FormData:", formData);

      // Send the FormData object to the server
      const response = await fetch(
        `https://www.mypropertypal.in/administrator/updateagentassoc/${agentid}`,
        {
          method: "POST",
          body: formData,
        }
      );

      console.log("response:", response);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Data", data);

      if (data && data.error) {
        console.error("Server Error:", data.error);
        // Display error message to user or handle it as needed
      } else {
        console.log("Server Response:", data);
        // Process the response data or handle success
      }
    } catch (error) {
      console.error("Upload error:", error);
    }
  };
  console.log(formData);

  //  handling agent profile pic image :
  const handleImageFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const agentid = sessionStorage.getItem("agentID");
    if (!agentid) {
      console.error("User ID not found in sessionStorage");
      return;
    }

    // Create form data
    const formData = new FormData();
    formData.append("file", file);
    const fileName = file.name;
    alert(fileName);

    // Send the file to the server
    //https://www.mypropertypal.in/administrator/updateagentpic

    fetch(
      `https://www.mypropertypal.in/administrator/updateagentpic/${agentid}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Check if response has content type JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          // Response contains JSON data, parse it
          return response.json();
        } else {
          // Response is not JSON, return a placeholder response
          return { success: true, message: "Upload successful." };
        }
      })
      .then((data) => {
        console.log("Upload successful:", data);
        // Handle success, if needed
      })
      .catch((error) => {
        console.error("Upload error:", error);
        // Handle error, if needed
      });
  };

  // return (
  //   <div className='personalinfo'>
  //     <div style={{margin:'1%',marginBottom:'4%', fontSize:'30px'}} className="heading">Profile</div>
  //     <div className="pi_container">
  //       <div className="pi_profile-section">

  //         {/* <img alt="Profile" className="pi_profile-img" src={profile} />
  //         <div className="pi_edit-section">
  //           <input
  //             type="file"
  //             accept="image/*"
  //             onChange={(e) => handleFileChange(e, 'profile')}
  //             style={{ display: 'none' }}
  //             id="file-upload"
  //           />
  //           <label htmlFor="file-upload">
  //             <img src={cam} alt="Upload" />
  //           </label>
  //         </div>
  //          */}
  //          <img alt="Profile" className="pi_profile-img"
  //            src = {`https://www.mypropertypal.in/administrator/uploads/${formData.profile}` || "https://www.mypropertypal.in/administrator/uploads/noname.png"}
  //           //src={formData.profile ? URL.createObjectURL(formData.profile) :"https://www.mypropertypal.in/administrator/uploads/noname.png"}
  //          />
  //         {/* // <p>{formData.profile}</p> */}
  //          <div className="pi_edit-section">
  //            <input
  //              type="file"
  //              accept="image/*"
  //              onChange={handleImageFileChange}
  //              style={{ display: 'none' }}
  //              id="file-upload"
  //            />
  //            <label htmlFor="file-upload">
  //              <img src={cam} alt="Upload" />
  //            </label>
  //          </div>

  //       </div>
  //       <form onSubmit={handleSubmit}>
  //         <div className="pi_form-section">
  //           <input
  //             type="text"
  //             placeholder="First Name"
  //             name="firstName"
  //             value={formData.firstName}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="text"
  //             placeholder="Last Name"
  //             name="lastName"
  //             value={formData.lastName}
  //             onChange={handleChange}
  //           />
  //         </div>
  //         <div className="pi_form-section">
  //           <input
  //             type="email"
  //             placeholder="Email Address"
  //             name="email"
  //             value={formData.email}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="tel"
  //             placeholder="Phone Number"
  //             name="phonenumber"
  //             value={formData.phonenumber}
  //             onChange={handleChange}
  //           />
  //         </div>
  //         <div className="pi_form-section_cert">
  //           <input
  //             type="text"
  //             placeholder="RERA ID"
  //             name="reraid"
  //             value={formData.reraid}
  //             onChange={handleChange}
  //           />
  //            {/* <button className="pi_upload-btn" type="button">
  //             Upload Certificate
  //           </button> */}

  //           <label className='pi_upload-btn'>
  //             Upload RERA Certificate
  //           <input
  //             type="file"
  //             accept="application/pdf"
  //             // onChange={(e) => handleFileChange(e, 'reracertificate')}
  //             onChange = {onhandlererafilechange}
  //             style={{ display: 'none' }}
  //           />
  //           </label>

  //           <input
  //             type="text"
  //             placeholder="Association member(if any)"
  //             name="associationmember"
  //             value={formData.associationmember}
  //             onChange={handleChange}
  //           />
  //           <label className='pi_upload-btn'>
  //             Upload Certificate
  //           <input
  //             type="file"
  //             accept="application/pdf"
  //             // onChange={(e) => handleFileChange(e, 'associationcertificate')}
  //             onchange = {onhandleassociationfilechange}
  //             style={{ display: 'none' }}
  //           />
  //           </label>

  //         </div>

  // <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',}}>
  //       <div>
  //           {formData.reracertificate && (
  //         <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",alignItems:'center'}}>
  //           <p>{formData.reracertificate}</p>
  //           <button onClick={()=>handleRemoveFile('reracertificate')} style={{padding:'8%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',marginLeft:'50%'}}>Remove</button>
  //         </div>
  //       )}
  //         </div>

  //         <div>
  //         {formData.associationcertificate && (
  //        <div style={{fontSize:'14px',fontFamily:'poppins_reg',display:'flex',flexDirection:'row',justifyContent:"space-between",alignItems:'center',}}>
  //       {/* <p>{formData.associationcertificate.name}</p> */}
  //       <p>{formData.associationcertificate}</p>
  //       <button onClick={()=>handleRemoveFile('associationcertificate')} style={{padding:'6%',border:'none',borderRadius:"10px",backgroundColor:'#FFD9D8',marginLeft:'50px'}}>Remove</button>
  //       </div>
  //         )}
  //         </div>

  //     </div>

  //         <div style={{ marginInline: "-0.1%",marginTop:'5%',marginBottom:'5%' }} className='custom-line'></div>
  //         <h2 className="pi_heading">Breif Intro</h2>
  //         <div className="pi_form-section">
  //           <textarea
  //             className="pi_intro-textarea"
  //             placeholder="Brief..."
  //             name="briefintro"
  //             value={formData.briefintro}
  //             onChange={handleChange}
  //           ></textarea>
  //         </div>
  //         <div style={{ marginInline: "-0.1%",marginTop:'5%',marginBottom:'5%' }} className='custom-line'></div>
  //         <h2 className="pi_heading">Address</h2>
  //         <textarea
  //             type="text"
  //             placeholder="Address"
  //             name="address"
  //             value={formData.address}
  //             onChange={handleChange}
  //             style={{width:'98%',minHeight:'5vh',  padding:'0.5rem',marginBottom:'2%'}}
  //           />
  //         <div className="pi_form-section">
  //           <select
  //             name="state"
  //             value={formData.state}
  //             onChange={handleChange}
  //           >
  //             <option value="">State</option>
  //             <option value="state1">State 1</option>
  //             <option value="state2">State 2</option>
  //           </select>
  //           <select
  //             name="city"
  //             value={formData.city}
  //             onChange={handleChange}
  //           >
  //             <option value="">City</option>
  //             <option value="city1">City 1</option>
  //             <option value="city2">City 2</option>
  //           </select>
  //           <select
  //             name="pincode"
  //             value={formData.pincode}
  //             onChange={handleChange}
  //           >
  //             <option value="">Zipcode</option>
  //             <option value="zipcode1">Zipcode 1</option>
  //             <option value="zipcode2">Zipcode 2</option>
  //           </select>
  //         </div>
  //         <div style={{ marginInline: "-0.1%",marginTop:'5%',marginBottom:'5%' }} className='custom-line'></div>
  //         <h2 className="pi_heading">Change Password</h2>
  //         <div className="pi_form-section">
  //           <input
  //             type="password"
  //             placeholder="Password"
  //             name="password"
  //             value={formData.password}
  //             onChange={handleChange}
  //           />
  //           <input
  //             type="password"
  //             placeholder="Confirm Password"
  //             value={cpassword}
  //             onChange={(e) => setcpassword(e.target.value)}
  //             style={{
  //               border: passwordMismatch ? '1px solid red' : '1px solid #ccc',
  //               color: passwordMismatch ? 'red' : 'inherit',
  //             }}
  //           />
  //         </div>
  //         <div style={{ marginInline: "-0.1%",marginTop:'5%',marginBottom:'5%' }} className='custom-line'></div>
  //         {passwordMismatch && <p style={{ color: 'red' }}>Passwords do not match</p>}
  //         <button className="find_button" type="submit">
  //           Save
  //         </button>
  //       </form>
  //     </div>
  //   </div>
  // );

  return (
    <div className="wrapper-2 style-1">
      <h1>Profile</h1>
      <div className="personalinfo">
        <div className="pi_container">
          <div className="pi_profile-section">
            <img
              alt="Profile"
              className="pi_profile-img"
              src={
                `https://www.mypropertypal.in/administrator/uploads/${formData.profile}` ||
                "https://www.mypropertypal.in/administrator/uploads/noname.png"
              }
              //src={formData.profile ? URL.createObjectURL(formData.profile) : "https://www.mypropertypal.in/administrator/uploads/noname.png"}
            />
            {/* // <p>{formData.profile}</p> */}
            <div className="pi_edit-section">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageFileChange}
                style={{ display:"none" }}
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <img src={cam} alt="Upload" />
              </label>
            </div>
          </div>
          {/*form submitting  */}
          <form onSubmit={handleSubmit}>
            <div className="new-form-sec-main">
              <div className="new-form-sec-1 w-48">
                <input
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-48">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-48">
                <input
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-48">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  name="phonenumber"
                  value={formData.phonenumber}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-48">
                <div className="new-form-upload-main">
                  <div className="new-form-upload-1">
                    <input
                      type="text"
                      placeholder="RERA ID"
                      name="reraid"
                      value={formData.reraid}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="new-form-upload-2">
                    <label className="pi_upload-btn">
                      Upload RERA Certificate
                      <input
                        type="file"
                        accept="application/pdf"
                        name="reracertificate"
                        //     onChange={(e) => onhandleFilechange(e, 'reracertificate')}
                        // //     onChange={(e) => {
                        // //       const file = e.target.files[0];
                        // //       setreracertificate(file);
                        // //   }}
                        onChange={onhandlererafilechange}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div>

                {/* <button className="pi_upload-btn" type="button">
              Upload Certificate
            </button> */}

                <div>
                  {formData.reracertificate && (
                    <div className="remove-sec">
                      {/* <p>{formData.associationcertificate.name}</p> */}
                      <div className="remove-sec-1">
                        <div className="text-ellipsis">
                          {formData.reracertificate.name ||
                            formData.reracertificate}
                        </div>
                      </div>
                      <div className="remove-sec-2">
                        <button
                          onClick={() => handleRemoveFile("reracertificate")}
                          className="remove-btn"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="new-form-sec-1 w-48">
                <div className="new-form-upload-main">
                  <div className="new-form-upload-1">
                    {" "}
                    <input
                      type="text"
                      placeholder="Association member(if any)"
                      name="associationmember"
                      value={formData.associationmember}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="new-form-upload-2">
                    <label className="pi_upload-btn">
                      Upload Certificate
                      <input
                        type="file"
                        accept="application/pdf"
                        name="associationcertificate"
                        onChange={onhandleassociationfilechange}
                        // onChange={(e) => onhandleFilechange(e, 'associationcertificate')}
                        style={{ display: "none" }}
                      />
                    </label>
                  </div>
                </div>

                {formData.associationcertificate && (
                  <div className="remove-sec">
                    {/* <p>{formData.associationcertificate.name}</p> */}
                    <div className="remove-sec-1">
                      {formData.associationcertificate.name ||
                        formData.associationcertificate}
                    </div>
                    <div className="remove-sec-2">
                      <button
                        onClick={() =>
                          handleRemoveFile("associationcertificate")
                        }
                        className="remove-btn"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* /////////////////////////////////// */}
              <div className="new-form-sec-1 w-48">
                <input
                  type="number"
                  placeholder="No Of Deals"
                  name="noofdeals"
                  value={formData.noofdeals}
                  onChange={handleChange}
                />
              </div>
              
              <div className="new-form-sec-1">
              <hr></hr>
                <h2>Breif Intro</h2>
                <input
                  type="text"
                  placeholder="Brief..."
                  name="briefintro"
                  value={formData.briefintro}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1">
                <h2>Area of Preference</h2>
                <input
                  type="text"
                  placeholder="Area of Prefrence"
                  name="areapreference"
                  value={formData.areapreference}
                  onChange={handleChange}
                />
              </div>
              
              <div className="new-form-sec-1">
              <hr></hr>
                <h2>Address</h2>
                <textarea
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-32">
                <input
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-32">
                <input
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-32">
                <input
                  type="num"
                  placeholder="Enter pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1">
              <hr></hr>
                <h2>Change Password</h2>
              </div>
              <div className="new-form-sec-1 w-48">
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="new-form-sec-1 w-48">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={cpassword}
                  onChange={(e) => setcpassword(e.target.value)}
                  style={{
                    border: passwordMismatch
                      ? "1px solid red"
                      : "1px solid #ccc",
                    color: passwordMismatch ? "red" : "inherit",
                  }}
                />
              </div>
              <div className="new-form-sec-1"></div>
            </div>

            <hr></hr>
            <div className="save-btn-2">
              {passwordMismatch && (
                <p style={{ color: "red" }}>Passwords do not match</p>
              )}
              <button className="button-a-2" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
