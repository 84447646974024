import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ruppe from './img/ruppe.png';
import bigruppe from './img/bigruppe.svg';
import blueruppe from './img/blueruppe.svg';
import './User_Payment.css';

const User_Payment = () => {
  const [consultations, setConsultations] = useState([]);
  // useEffect(() => {
  //   // Generate dummy data for demonstration
  //   const dummyData = Array.from({ length: 5 }, (_, index) => ({
  //     id: index + 1,
  //     invoiceNo: `Invoice No ${index + 1}`,
  //     recipient: {
  //       name: `Recipient Name ${index + 1}`,
  //       image: `https://randomuser.me/api/portraits/men/${index + 1}.jpg`, // Replace with actual image URL
  //     },
  //     category: `Category ${index + 1}`,
  //     date: `${Math.floor(Math.random() * 31) + 1}/${Math.floor(Math.random() * 12) + 1}/${Math.floor(Math.random() * 10) + 2020}`, // Random date in d m y format
  //     amount: {
  //       value: Math.floor(Math.random() * 1000),
     
  //     },
  //     status: Math.random() > 0.5 ? 'Completed' : 'Canceled',
  //   }));

  //   setConsultations(dummyData);
  // }, []);
  useEffect(() => {
    console.log("on fetch data")
    const fetchUserConsults = async () => {
     
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/mybookings/${sessionStorage.getItem('userID')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log('fetchblogs')
        const jsonData = await response.json();
        console.log(jsonData,'jsondata')
        if(jsonData){
          const transformedData = jsonData.map(item => ({
            slno: item.postId,
            name: {
                profileImage: item.profilePic,
                name: `${item.agentfName} ${item.agentlName}`
            },
            email: item.emailId,
            category: item.consultingType,
            date: item.bookingDate,
            timeSlot: item.bookingTime,
            action: item.postId,
            amount:item.bookingFee,
            status:item.bookingStatus ==='1' ?('Completed'):('Canceled')
        }));
          setConsultations(transformedData);
     
        }
      
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUserConsults();
  }, []);
console.log(consultations,'payments')
  return (
<div className='wrapper-2 style-1'>
  <h1>Payment History</h1>
<div className="payments" >
      
      <div className="payments-container">
        <div className="paymentstable-container" >
          <table className="payments-table">
            <thead>
              <tr>
            
                <th>Invoice No</th>
                <th>Recipient</th>
                <th>Category</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody >
              {consultations.map((consultation) => (
                <tr key={consultation.slno} >
               
                  <td >{consultation.slno}</td>
                  <td>
                    <div className="user-profile-cell">
                    <img 
                          src={`https://www.mypropertypal.in/administrator/uploads/${consultation.name.profileImage}`} 
                          alt="profile"
                          onError={(e) => e.target.src = 'path-to-default-image.jpg'} // Handle missing image
                        />
                      {consultation.name.name}
                    </div>
                  </td>
                  <td>{consultation.category}</td>
                  <td>{consultation.date}</td>
                  <td>
                  <img src={ruppe} alt="INR" />
                    {` ${consultation.amount}`}
                   
                  </td>
                  <td>
                    <span className={consultation.status === 'Completed' ? 'status-completed' : 'status-canceled'}>
                      {consultation.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

</div>


   
  );
};

export default User_Payment;
