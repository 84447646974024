import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  onClickStartMeeting,
 // slno
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialMeetingId = queryParams.get("meetingId");

  const [meetingId, setMeetingId] = useState(initialMeetingId || "");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(!!initialMeetingId);
  
  useEffect(() => {
    if (meetingId) {
      console.log(`Meeting ID updated: ${meetingId}`);
      sendMeetingDataToApi(meetingId);
    }
  }, [meetingId]);

  const sendMeetingDataToApi = async (meetingId) => {
    try {
      // Retrieve slnoFromSession from sessionStorage
      const dashslnoFromSession = sessionStorage.getItem("Slno");
      // const appoinSlnoFromSession = sessionStorage.getItem("Slno")
      // const payload = {
      //   dashslnoFromSession,
      //   appoinSlnoFromSession,
      // };

      if (!dashslnoFromSession) {
        console.error("No slno found in session storage");
        return;
      }

      console.log("slno from session storage:", );

      const response = await fetch('https://www.mypropertypal.in/administrator/updatemeeting', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ postid: dashslnoFromSession, meetingid: meetingId }),
      });

      if (!response.ok) {
        throw new Error('Failed to send data');
      }

      const data = await response.json();
      console.log('Data sent successfully:', data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };


  return (
    <div className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}>
      {iscreateMeetingClicked ? (
        <div className="border border-solid border-gray-400 rounded-xl px-4 py-3 flex items-center justify-center">
          <p className="text-white text-base">
            {`Meeting code : ${meetingId}`}
          </p>
          <button
            className="ml-2"
            onClick={() => {
              navigator.clipboard.writeText(meetingId);
              console.log(`Meeting ID copied to clipboard: ${meetingId}`);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            {isCopied ? (
              <CheckIcon className="h-5 w-5 text-green-400"/>
            ) : (
              <ClipboardIcon className="h-5 w-5 text-white"/>
            )}
          </button>
        </div>
      ) : isJoinMeetingClicked ? (
        <>
          <input
            value={meetingId}
            onChange={(e) => {
              setMeetingId(e.target.value);
              console.log(`Meeting ID entered: ${e.target.value}`);
            }}
            placeholder={"Enter meeting Id"}
            className="px-4 py-3 bg-gray-650 rounded-xl text-white w-full text-center"
          />
          {meetingIdError && (
            <p className="text-xs text-red-600">{`Please enter valid meetingId`}</p>
          )}
        </>
      ) : null}

      {(iscreateMeetingClicked || isJoinMeetingClicked) && (
        <>
          <input
            value={participantName}
            onChange={(e) => setParticipantName(e.target.value)}
            placeholder="Enter your name"
            className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
          />
          <button
            disabled={participantName.length < 3}
            className={`w-full ${participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"} text-white px-2 py-3 rounded-xl mt-5`}
            onClick={(e) => {
              if (iscreateMeetingClicked) {
                console.log(`Starting meeting with ID: ${meetingId}`);
                onClickStartMeeting();
              } else {
                console.log(`Attempting to join meeting with ID: ${meetingId}`);
                if (meetingId.match("\\w{4}-\\w{4}-\\w{4}")) {
                  console.log(`Joining meeting with ID: ${meetingId}`);
                  onClickJoin(meetingId);
                } else {
                  console.log("Invalid meeting ID format");
                  setMeetingIdError(true);
                }
              }
            }}
          >
            {iscreateMeetingClicked ? "Start a meeting" : "Join a meeting"}
          </button>
        </>
      )}

      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col">
          <div className="flex items-center justify-center flex-col w-full">
            <button
              className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl p-5"
              onClick={async (e) => {
                console.log("Create meeting button clicked");
                const { meetingId, err } = await _handleOnCreateMeeting();
                if (meetingId) {
                  console.log(`Created meeting with ID: ${meetingId}`);
                  setMeetingId(meetingId);
                  setIscreateMeetingClicked(true);
                } else {
                  console.log(`Error creating meeting: ${err}`);
                  toast(
                    `${err}`,
                    {
                      position: "bottom-left",
                      autoClose: 4000,
                      hideProgressBar: true,
                      closeButton: false,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    }
                  );
                }
              }}
            >
              Create a meeting
            </button>
            <button
              className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl mt-5 p-5"
              onClick={(e) => {
                console.log("Join meeting button clicked");
                setIsJoinMeetingClicked(true);
              }}
            >
              Join a meeting
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
