// Header.js
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Sign_Up.css"; // Import the CSS file
import Image2 from "./img/bgdiv.png";
import call from "./img/call.svg";
import mail from "./img/mail.svg";

const Header = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [reraId, setReraId] = useState("");
  const [associationCertificate, setAssociationCertificate] = useState("");
  const [uploadCertificate, setUploadCertificate] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [emailid, setemailid] = useState();
  const [comments, setcomments] = useState();


  const validateForm = () => {
    const errors = {};

    if (!firstName) {
      errors.firstName = "First name is required";
    }

    if (!lastName) {
      errors.lastName = "Last name is required";
    }

    if (!emailid) {
      errors.emailid = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(emailid)) {
      errors.emailid = "Email address is invalid";
    }

    if (!phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number is invalid. It should be 10 digits.";
    }

    if (!comments) {
      errors.comments = "Message is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //const submit = () => {};
  const submit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const NewCommitData = {
      firstName,
      lastName,
      phoneNumber,
      emailid,
      comments
    };

    console.log('NewCommitData,', NewCommitData);

    const apiurl = "https://www.mypropertypal.in/administrator/contact-us";
    const option = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(NewCommitData),
    };

    try {
      const response = await fetch(apiurl, option);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("data:", data);
      if (data) {
        console.log("Contact form updated successfully!!!");

        // Clear the form fields
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setemailid("");
        setcomments("");
      } else {
        console.log("Contact form update unsuccessful!!!");
      }
    } catch (error) {
      console.error('Error contacting API:', error);
    }
  };





  return (
    <div
      className="Agent_signup"
      style={{
        backgroundImage: `url(${Image2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >

      <div className="wrapper-1">
        <div className="contact-page-detailes">
          <div className="contact-page-1"><img src={call} />  +91 98765 43210</div>
          <div className="contact-page-1"><img src={mail} />  info@email.com</div>
        </div>
      </div>


      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "2%",
        }}
      >



      </div>
      <form className="signup_form" onSubmit={submit}>
        <h2>Contact Us</h2>
        <h6>Submit your details below and our expert team will get back to you.</h6>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="input-field-2"
        />
        {formErrors.firstName && (
          <p className="error-message">{formErrors.firstName}</p>
        )}
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="input-field-2"
        />
        {formErrors.lastName && (
          <p className="error-message">{formErrors.lastName}</p>
        )}
        <input
          type="text"
          placeholder="Email Address"
          value={emailid}
          name="emailid"
          onChange={(e) => setemailid(e.target.value)}
          className="input-field-2"
        />
        {formErrors.emailid && <p className="error-message">{formErrors.emailid}</p>}
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="input-field-2"
        />
        {formErrors.phoneNumber && (
          <p className="error-message">{formErrors.phoneNumber}</p>
        )}
        <textarea
          className="textarea-field-2"
          placeholder="Message"
          value={comments}
          name="comments"
          onChange={(e) => setcomments(e.target.value)}
        ></textarea>
        {/* {formErrors.comments && (
          <p className="error-message">{formErrors.comments}</p>
        )} */}
        <button
          type="submit"
          className="find_button"
          style={{ width: "100%", marginTop: "8%", marginBottom: "2%" }}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Header;
