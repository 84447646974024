import React from 'react';
import {useState} from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Agent_Remarks.css';
function Agent_Remarks() {


  const location = useLocation();
  console.log(location.state)
  const referringPageUrl = location.state?.url || '/';
  const rowData = location.state?.rowData || {};
  
  // console.log(referringPageUrl);
  // console.log(rowData);
  const [remarks, setRemarks] = useState('');

  const handleChange = (event) => {
    setRemarks(event.target.value);
  };
  const { slno, name:{name,profileImage}, email, category, date, timeSlot } = rowData;
  const handleSubmit = async (event) => {
    event.preventDefault();
    if(remarks){
  var data={
    'postid':slno,
    'consultaionremarks':remarks,
  }
} 
    try {
      const response = await fetch('https://www.mypropertypal.in/administrator/postconsultaionremarks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers required by your API
        },
        body: JSON.stringify(data),
      });
var d= await response.json();
console.log(d)
      if (!response.ok) {
        throw new Error('Failed to submit remarks');
      }
  if(d.message){
    alert(d.message)
  }
  // const data1 = await response.json();
  

      setRemarks('');
   
    } catch (error) {
      console.error('Error submitting remarks:', error.message);
    }
  };
  
  
  return (
    <div className="Agent_Remarks">
      <div className="Agent_Remarks_header">
        <h1 className="Agent_Remarks_title">Appointments - Remarks</h1>
        <button className="Agent_Remarks_cancel-btn"><Link to={decodeURIComponent(referringPageUrl)}>Cancel</Link></button>
      </div>
      <div className='AgentsRemarks_container'>
        <table className="Agent_Remarks_table">
          <thead>
            <tr>
              <th>Name</th>
              {/* <th>Email</th> */}
              <th>Category</th>
              <th>Date</th>
              <th >Time Slot</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="user-profile-cell">
                  <div className="Agent_Remarks_avatar"><img src={profileImage}/></div>
                  <span>{name}</span>
                </div>
              </td>
              {/* <td>{email}</td> */}
              <td>{category}</td>
              <td>{date}</td>
              <td>{timeSlot}</td>
            </tr>
          </tbody>
        </table>
        <div className="Agent_Remarks_remarks">
          <textarea     value={remarks}
          onChange={handleChange}className="Agent_Remarks_remarks-input" placeholder="Remarks"></textarea>
        </div>
        <div className="Agent_Remarks_submit-btn">
          <button  onClick={handleSubmit}style={{marginBottom:'6%',marginLeft:'3%'}} className="find_button">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Agent_Remarks;
