import React, { useState, useEffect,useRef } from 'react';
import './Agents_Avaliabillity.css'; 
import DatePicker , { DateObject, toDateObject } from 'react-multi-date-picker';
import moment from 'moment';
import "react-multi-date-picker/styles/colors/red.css"
import { Calendar } from 'react-multi-date-picker';
import InputIcon from "react-multi-date-picker/components/input_icon" 
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default function Component() {
  const today = new Date();
  const datePickerRef = useRef();
  const [selectedWeeks, setSelectedWeeks] = useState([today.getDay()]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth());
  const [availabilityData, setAvailabilityData] = useState({});
  const [showWeeks, setShowWeeks] = useState(true);
  const [selectedMonthData, setSelectedMonthData] = useState('');
  const [selectedMonthWeekDays, setSelectedMonthWeekDays] = useState([]);
  const [isEditingWeeks, setIsEditingWeeks] = useState(false);
  const [isEditingTimeSlots, setIsEditingTimeSlots] = useState(false);
  const [showtimeslots, setshowtimeslots] = useState(true);
  const [monthname,setmonthname]=useState(months[selectedMonth])
  const [weekname,setweekname]=useState('')
  const [MonthIndex,setMonthIndex]=useState(today.getMonth())
  const [selectedDates, setSelectedDates] = useState(new DateObject());
  const [selectedWeekDayButtons, setSelectedWeekDayButtons] = useState([]);
  const [exceptedDates, setExceptedDates] = useState([]);
 const[userclick,setuserclick]=useState(false)
  const [showscreen,setshowscreen]=useState(true)
  const [user,setuser]=useState(false)
  const calendarRef = useRef();
  
  useEffect(() => {
    // Load data from sessionStorage when the component mounts
   
    // const storedData = sessionStorage.getItem('availabilityData');
    // if (storedData) {
    //   setAvailabilityData(JSON.parse(storedData));
    // }
    const fetchData = async () => {
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/getagentavailability/${sessionStorage.getItem('agentID')}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData)
        console.log(jsonData

)
        if(jsonData.availability!==null){
        setAvailabilityData(JSON.parse(jsonData));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
   

    fetchData();
    
    // postData();
  
}, []);
  
  useEffect(() => {
    // Load data from sessionStorage when the component mounts
  //   const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  //   const currentDayIndex = today.getDay();
  // const currentDayOfWeek = daysOfWeek[currentDayIndex];
  // const month1=months[today.getMonth()]
  // if(Object.keys(availabilityData).length > 0){
  // handleMonthClick(today.getMonth(),month1)
  // handleWeekDayClick(currentDayIndex,currentDayOfWeek);
  // }
  // if(Object.keys(availabilityData).length > 0){
  // setshowscreen(true)
  //   }

console.log("hi")
console.log(availabilityData)
console.log(Object.keys(availabilityData).length )
if(Object.keys(availabilityData).length > 0){
setshowscreen(true)

if(!userclick){
  // postData();
console.log(MonthIndex)
console.log( Object.keys(availabilityData[MonthIndex]?.except || {}).map(Number))
setSelectedMonthData(availabilityData[today.getMonth()])
}
}
  
  },[availabilityData]);

  // useEffect(()=>{
   
  //   if(Object.keys(selectedMonthData).length > 0)
  //   setshowscreen(true)
  // console.log(Object.keys(selectedMonthData).length)
  // },[selectedMonthData])
  // useEffect(() => {
  //   // Update button styles when selectedMonthWeekDays or selectedWeekDayButtons change
  //   const buttons = document.querySelectorAll('.component-button1');
  //   buttons.forEach((button, index) => {
  //     button.style.backgroundColor = selectedMonthWeekDays.includes(index) ? '#339A33' : '#FFD9D8';
  //   });
  // }, [selectedMonthData]);
  const pos={
    "3": {
      "except": ["2024-03-18"],
      "week": {
        "3": [4, 3],
        "4": [],
        "5":[]
  }
}
  }
  console.log(selectedDates,'selectedDates')
  const postData = async () => {
    console.log(availabilityData)
    try {
      const response = await fetch(`https://www.mypropertypal.in/administrator/agentavailability/${sessionStorage.getItem('agentID')}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
       
        body: JSON.stringify(availabilityData) // Your request body here
      });
      const data1 = await response.json();
      console.log('resposnse from post',data1)
      if(data1.message){
        alert(data1.message)
      }
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const handleWeekDayClick = (index,week) => {
    setuserclick(true)
    if(isEditingWeeks===false)
    setshowtimeslots(true);
    setweekname(week)
    // if (isEditingWeeks) {
    //   setSelectedWeeks(prev => {
    //     if (prev.includes(index)) {
    //       return prev.filter(day => day !== index);
    //     } else {
    //       return [...prev, index];
    //     }
    //   });
      if (isEditingWeeks) {
        setSelectedWeekDayButtons(prev => prev.includes(index) ? prev.filter(btnIndex => btnIndex !== index) : [...prev, index]);
      }
    // }
     else if (isEditingTimeSlots) {
      setSelectedWeeks([index]); // Only allow selecting one week day when editing time slots
      setSelectedTimeSlots(prev => {
        if (prev.includes(index)) {
          return prev.filter(slot => slot !== index);
        } else {
          return [...prev, index];
        }
      });
    } else {
      setSelectedWeeks([index]);
    }
  };
  

  const handleMonthClick = (monthIndex,month) => {
    setuserclick(true)
    console.log("monthIndex",monthIndex,month)
    setMonthIndex(monthIndex);
    setSelectedMonth(monthIndex)
    setmonthname(month)
    update("month",monthIndex)
    // setSelectedMonth(monthIndex === selectedMonth ? '' : monthIndex);
    console.log(availabilityData)
    setShowWeeks(monthIndex !== selectedMonth); // Show week days only if a month is selected
    setSelectedMonthData(availabilityData[monthIndex] ||{});
 
    const monthAvailability = availabilityData[monthIndex];
    if (monthAvailability && monthAvailability.week) {
      const weekDaysWithAvailability = Object.keys(monthAvailability.week).map(Number);
      setSelectedMonthWeekDays(weekDaysWithAvailability);
    } else {
      setSelectedMonthWeekDays([]);
    }
    setIsEditingWeeks(false);
    setIsEditingTimeSlots(false);
  };

  const printStoredData = () => {
    console.log(availabilityData);
  };
  const handleDateSelect = (dates) => {
    setSelectedDates(dates);
  };
  const handleSaveWeeks = () => {

    const newAvailabilityData = { ...availabilityData };
    const monthData = newAvailabilityData[selectedMonth] || { week: {} };
    selectedWeekDayButtons.forEach(weekDayIndex => {
      if (monthData.week[weekDayIndex] !== undefined) {
        // Week is already present, remove it
        delete monthData.week[weekDayIndex];
      } else {
        // Week is not present, add it
        monthData.week[weekDayIndex] = [];
      }
    });
    newAvailabilityData[selectedMonth] = monthData;
    // Save data to sessionStorage
    sessionStorage.setItem('availabilityData', JSON.stringify(newAvailabilityData));
    setAvailabilityData(newAvailabilityData);
    setIsEditingWeeks(false);
    setSelectedMonthData(monthData)
 
  };

  const handleSaveTimeSlots = () => {
    const newAvailabilityData = { ...availabilityData };
    const monthData = newAvailabilityData[selectedMonth] || { week: {} };
    selectedWeeks.forEach(weekDayIndex => {
      const existingTimeSlots = monthData.week[weekDayIndex] || [];
      const newTimeSlots = selectedTimeSlots.filter(slot => !existingTimeSlots.includes(slot));
      monthData.week[weekDayIndex] = existingTimeSlots.concat(newTimeSlots);

      // Remove time slot if it's already in the existing slots
      existingTimeSlots.forEach(existingSlot => {
        if (selectedTimeSlots.includes(existingSlot)) {
          monthData.week[weekDayIndex] = monthData.week[weekDayIndex].filter(slot => slot !== existingSlot);
        }
      });
    });
    newAvailabilityData[selectedMonth] = monthData;
    // Save data to sessionStorage
    sessionStorage.setItem('availabilityData', JSON.stringify(newAvailabilityData));
    setAvailabilityData(newAvailabilityData);
    setIsEditingWeeks(false);
    setIsEditingTimeSlots(false);
    setSelectedTimeSlots('')
    setSelectedMonthData(monthData)
  };

  const remove = () => {
    sessionStorage.removeItem('availabilityData');
  };

  const isSlotSelected = (weekDayIndex, timeSlotIndex) => {
    return (
      selectedMonthData?.week &&(
      selectedMonthData.week[weekDayIndex] &&
      selectedMonthData.week[weekDayIndex].includes(timeSlotIndex)
      )
    );

  };
const isweekselected =(index,b)=>{

  // console.log(index)
  // console.log(b)
  // console.log("selctedmonthdate",selectedMonthData);
  // console.log(selectedMonthData.week)
 if(selectedMonthData?.week!==undefined ){
// console.log("from undefined",b)
// console.log( index in selectedMonthData.week)
  return index in selectedMonthData.week;
 }
else{ 
  // console.log("from else")
  return false
}
}
const handlepost =()=>{
  postData();
}

const   handleAddExceptedDates = () => {
  if( selectedDates.length>0){
  setuserclick(true)
  const newAvailabilityData = { ...availabilityData };
  const monthData = newAvailabilityData[selectedMonth] || { week: {}, except: [] };

  const formattedDates = selectedDates.map(date => date.format('YYYY-MM-DD'));
  const newExceptedDates = [ ...formattedDates]; // Initialize as empty array if undefined or null
  monthData.except = newExceptedDates;
  newAvailabilityData[selectedMonth] = monthData;
  // Save data to sessionStorage
  sessionStorage.setItem('availabilityData', JSON.stringify(newAvailabilityData));
  setAvailabilityData(newAvailabilityData);
  setExceptedDates(newExceptedDates);
  }else{
    alert("pls select dates to add")
  }
};

const showdates=()=>{
  const d=selectedDates;
  console.log(d.map(m=>
    m.format()  
  ));
  console.log(typeof(MonthIndex))
}

let year = today.getFullYear()
const [buttonColors, setButtonColors] = useState([]
);
// console.log(buttonColors,"buttoncoloes")
// Function to toggle button color
const toggleButtonColor = (index) => {
  setButtonColors(prevColors =>
    prevColors.map((color, i) =>
      i === index ? (color === '#FFD9D8' ? '#339A33' : '#FFD9D8') : color
    )
  );
};
const [buttonColors1, setButtonColors1] = useState([])
  


// Function to toggle button color
const toggleButtonColor1   = (index) => {
  setButtonColors1((prevColors) =>
    prevColors.map((color, i) =>
      i === index ? (color === '#FFD9D8' ? '#339A33' : '#FFD9D8') : color
    )
  );
};

function update(month,value) {
  
  let date = calendarRef.current.date;

  calendarRef.current.set(month, value+1  );

  
  }
  return (
    <div className='Agents_Avaliabillity'>
         <p className="component-heading">Availability</p>
         {showscreen ? (
    <div className="component-container">
  
      <div className="component-section">
      <div className='MonthSection'>
        <p className="component-subheading">Select Months</p>
        <div className="component-buttons">
          {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
           
           <div  className={` ${selectedMonth === index ? 'selected1' : ''}`}>
          
           <button
              key={month}
              className='component-button'
       
            
              onClick={() => handleMonthClick(index,month) }
              //#339A33 green
              
              style={{
                backgroundColor: selectedMonth === index
                  ? '#6083F7'
                  : (availabilityData[index] && Object.keys(availabilityData[index].week).length > 0)
                  ? '#339A33'
                  : '#FFD9D8',
                color: selectedMonth === index
                ? 'white'
                : (availabilityData[index] && Object.keys(availabilityData[index].week).length > 0)
                ? 'white'
                : '#666666'// Assuming you want white color for all other cases
              }}
              
              
              


            >
              {month}
            </button>
            </div>
          ))}
        </div>
  
   
        <div className="component-section">
          {/* <p className="component-subheading">Select Dates for {monthname} month</p> */}
          <div className="component-buttons">
         <div style={{display:'flex',flexDirection:'column',alignItems:'center',width:'100%'}}>
            <Calendar
            placeholder='click to add excepted dates'
            highlightToday={false}
            ref={calendarRef}
            open 
            minDate={new Date()}
              value={selectedMonthData?.except||''}
              onChange={setSelectedDates}
              multiple
              className="red"
     
            
  //            currentDate={
  //   new DateObject({ 
  //     year: year,
  //     month: selectedMonth+1,
   
  //   })
  // }
 
            
            //   mapDays={({ date }) => {
            //     let color
            //     console.log("selectedates",selectedDates)

            //  const hi=selectedMonthData.except.map(date => +date.substring(8));
            //     // console.log(hi)
            //     // console.log(date.day)
            //     if (hi.includes(date.day)) color = "red"
            
                
            //     if (color) return { className: "highlight highlight-" + color }
            //   }}
         
            />
             {/* < DatePicker
placeholder='for usertesting'
onChange={setSelectedDates}
render={<InputIcon/>}
minDate={new Date()}
highlightToday={false}
mapDays={({ date,currentMonth }) => {
  let props = {}
  var hi=[]
  
//   if(selectedMonthData.except){
//  hi=selectedMonthData.except.map(date => +date.substring(8))||[];
  // }
  // if ([0,6].includes(date.weekDay.index)) return  props.className= "highlight highlight-green" 
  let isWeekend = Object.keys(availabilityData[currentMonth.index]?.week || {}).map(Number).includes(date.weekDay.index) 
  // let isWeekend = [0,4,5,6].includes(date.weekDay.index)
  if (availabilityData[currentMonth.index]?.except?.includes(date.format("YYYY-MM-DD"))) {
    return { disabled: true };
  }
  
  if (isWeekend) props.className = "highlight highlight-green"
  //     // console.log(hi)
  // if (hi.includes(date.day))  return {
  //   disabled: true,
  //   style: { color: "#ccc" },
  //   onClick: () => alert("sorry that date is reserved")
  // }
  // else props.className = "highlight highlight-green"
 
  
  
  return props
}}

/> */}


            <button style={{marginTop:'2%',width:'max-content',padding:"1%"}} className='button-a-2' onClick={handleAddExceptedDates}>Add Exemted Dates</button>
            </div>  
          </div>
        </div>
  
      </div>
      </div>

      <div className='WeeksSection'>
      {showscreen ? (
        <div className="component-section">
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginInline:'1%',marginRight:'4%'}}>
          <p className="component-subheading">Select Week Days for {monthname} month</p>
          {!isEditingWeeks && (
              <div className="component-button-container">
              <button
                className="component-edit-button"
                onClick={() => {
                  setIsEditingWeeks(true);
                 
                  setButtonColors(
                    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekDay, index) => (
                      isweekselected(index, weekDay) ? '#339A33' : '#FFD9D8'
                    ))
                  );
                }}
              >
                Edit Weeks
              </button>
            </div>
            
            )}
            {isEditingWeeks && (
              <div className="component-button-container1">
                <button className="component-cancel-button" onClick={() => {
                  setIsEditingWeeks(false);
                  setSelectedWeeks([]);
                }}>Cancel</button>
                <button className="component-save-button" onClick={handleSaveWeeks}>Save Weeks</button>
              </div>
            )}
            </div>
          <div className="component-buttons">
       
          {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((weekDay, index) => (
              
           <div  className={`${isEditingWeeks ? '' :selectedWeeks.includes(index) ?  'selected1' : ''}`}>
  <button
    key={weekDay}
    className={`component-button `}
    onClick={() => {
     
      if (isEditingWeeks) {
        handleWeekDayClick(index, weekDay);
        toggleButtonColor(index);
     
      }
      else{
        handleWeekDayClick(index, weekDay);
      }
    
    }}
    style={{ backgroundColor: isEditingWeeks ? buttonColors[index] : (selectedWeeks.includes(index) ? '#6083F7':  (isweekselected(index) ? '#339A33' : '#FFD9D8')) ,
    color: selectedWeeks.includes(index) ? 'white':  (isweekselected(index) ? 'white' : '#666666')
  }}
  >
    {weekDay}
  </button>
  </div>
))}       
          </div>
        </div>
        ): (
          <div>Loading...</div>
        )}
      
</div>

<div className='TimeSection'>
  {showtimeslots && !isEditingWeeks && (
    
    <div className="component-section">
   <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginInline:'1%',marginRight:'5%'}}>
      <p className="component-subheading">Select Time Slots for {daysOfWeek[selectedWeeks]} week</p>
      {!isEditingTimeSlots && (
          <div className="component-button-container">
            <button className="component-edit-button" onClick={() => {
              setIsEditingTimeSlots(true);
        setButtonColors1( Array.from({ length: 12 }, (_, index) =>
        isSlotSelected(selectedWeeks[selectedWeeks.length - 1], index) ? '#339A33' : '#FFD9D8'
      ))
             
            
            }}>Edit Time Slots</button>
            
          </div>
        )}
        {isEditingTimeSlots && (
          <div className="component-button-container1">
            <button className="component-cancel-button" onClick={() => {
              setIsEditingTimeSlots(false);
              setSelectedTimeSlots([]);
            }}>Cancel</button>
            <button className="component-save-button" onClick={handleSaveTimeSlots}>Save Time Slots</button>
          </div>
        )}
        </div>
      <div className="component-buttons">
        {['9 AM to 10 AM', '10 AM to 11 AM', '11 AM to 12 PM', '12 PM to 1 PM', '1 PM to 2 PM', '2 PM to 3 PM', '3 PM to 4 PM', '4 PM to 5 PM', '5 PM to 6 PM', '6 PM to 7 PM', '7 PM to 8 PM', '8 PM to 9 PM'].map((timeSlot, index) => (
          <button
            key={timeSlot}
            className={`component-button ${(selectedTimeSlots.includes(index)) ? 'selected editing-weeks' : ''}`}
            onClick={() => isEditingTimeSlots ? (toggleButtonColor1(index), setSelectedTimeSlots(prev => prev.includes(index) ? prev.filter(slot => slot !== index) : [...prev, index])) : null}
            style={{ backgroundColor: isEditingTimeSlots?buttonColors1[index]:
            isSlotSelected(selectedWeeks[selectedWeeks.length - 1], index) ? '#339A33' : '#FFD9D8',
            color:isSlotSelected(selectedWeeks[selectedWeeks.length - 1], index) ? 'white' : '#666666'
             }}
          >
            {timeSlot}
          </button>
        ))}
       
      </div>
    </div>
  )}
</div>

      {/* <button onClick={printStoredData}>Show Data</button>
      <button onClick={remove}>Remove</button>
      <button onClick={showdates}>showdates</button> */}
      <button style={{marginTop:'2%',marginBottom:'2%'}}onClick={handlepost} className='button-a-2'>Save</button>
    </div>
     ): (
      <div>Loading...</div>
    )}
    </div>
  );
}
