
import React, { useState,useRef } from 'react';
import { NavLink} from 'react-router-dom';
import './Agent_Login.css'; 
import Image2 from './img/Image2.png'
import {  useNavigate } from 'react-router-dom';
const Agent_forgot = () => {
    const navigate = useNavigate();
    const [emailid, setEmail] = useState('');
    const [message,setmessage]=useState('');
  const [load ,setload]=useState(false)
    const {loggedin,setloggedin}=useState(false);

    // const handleLogin = () => {
      
    //     console.log('Login clicked with email:', email, 'and password:', password);
    //    isloggedIn('true')
    //    navigate('/');
    //    sessionStorage.setItem('isloggedin','true')
    // };
    const handleLogin = async () => {
      setload(true)
   console.log(JSON.stringify({ emailid }))
        try {
            const response = await fetch('https://www.mypropertypal.in/administrator/forgotpassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ emailid }),
            });
            const data = await response.json();
            console.log('Response:', data);
 if(data?.message){
    setload(false)
 console.log("got message",data.message)
 setmessage(data.message)
 }
            // if (data.message === 'Login Successful.') {
            //     sessionStorage.setItem('agentID', data.profiledet.agentID);
            //     // You can also set other data to sessionStorage if needed
            //     alert("loggedin ");
            
              
            //     isloggedIn('true')
            //     navigate('/');
            //     sessionStorage.setItem('isloggedin','true')
               
            // } else {
            //     // Handle other responses like incorrect password or email not found
            //     console.error('Login Failed:', data.message);
            // }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    const handleForgotPassword = () => {
    
        console.log('Forgot Password clicked');
        navigate('/Agent_Login')
    };

    const handleSignUp = () => {
       
        navigate('/Sign_Up');
    };
    // useEffect(() => {
        
    //   }, [loggedin]); 

    return (
     
        <div className='Agent_Login'  style={{   backgroundImage: `url(${Image2})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', }}>
            <div className="login_form">
              <div className='login_heading'>
                <p>Forgot Password</p>
                
              </div>
                <input
                    type="email"
                    placeholder="Email"
                    value={emailid}
                    onChange={(e) => setEmail(e.target.value)}
                    className="input-field"
                    style={{ height: '2vw' }}
                />
                {/* <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="input-field"
                    style={{ height: '2vw' }}
                /> */}
                <div className="forgot-signup">
                    <div className="forgot-password" >
                        <p onClick={handleForgotPassword}>Login?</p>
                    </div>
                   
                </div>
                { load ?(<p>loading....</p>):( <p style={{color:"#6083F7"}}>{message}</p>)
                }
               
                <button onClick={handleLogin} className="find_button" style={{width:'100%',marginTop:'8%',marginBottom:'2%'}}>
                    Get Password
                </button>
                 <div className="signup">
                 <p>Not a member yet?</p>   <p onClick={handleSignUp} className="signup-link">Sign Up</p>
                    </div>
            </div>
            {/* login-form */}
            </div>
    );
};

export default Agent_forgot;
