// App.js
import React, { useEffect, useState } from "react";
// Assuming Card component is in Card.js
import "./dash.css"; // Assuming you have a separate CSS file
import "./Appointments.css";

import up_down from "./img/up&down.svg";
import sort_arrow from "./img/icon_remarks.svg";
import reschedule from "./img/icon_reschedule.svg";
import remarks from "./img/icon_remarks.svg";
import { Link } from "react-router-dom";
import bigruppe from "./img/bigruppe.svg";
import metting from "./img/agent_metting.svg";
import { useNavigate } from "react-router-dom";
import VideoNew from "./VideoNew";
import closebtn from "./img/close.png";

//import  video from "./components/video"

const Dash = () => {
  //   const [data, setData] = useState([]);
  //  const data= [
  //     {
  //       "title": "Total appointments so far",
  //       "word1": "Upcoming",
  //       "word2": "54"
  //     },
  //     {
  //       "title": "Consultations",
  //       "word1": "Scheduled",
  //       "word2": "5"
  //     },
  //     {
  //       "title": "Earning",
  //       "word1": "Total",
  //       "word2": "1,05,000"
  //     }
  //   ]

  //   useEffect(() => {
  //     // Fetch data from JSON file (replace with actual path)
  //     fetch('path/to/data.json')
  //       .then(response => response.json())
  // //       .then(data => setData(data))
  // //       .catch(error => console.error('Error fetching data:', error));
  // //   }, []);
  // const Card = ({ title, word1, word2 }) => {
  //     return (

  //       <div className="dash_card">
  //         <div className="dash_card-title">{title}</div>
  //         <div className="dash_card-word1">{word1}</div>
  //         <div className="dash_card-word2">{word2}</div>
  //       </div>

  //     );
  //   };
  const tableData1 = [
    {
      slno: 1,
      name: {
        profileImage: "https://randomuser.me/api/portraits/men/1.jpg",
        name: "User 1",
      },
      email: "user1@example.com",
      category: "Category 1",
      date: "2022/02/2",
      timeSlot: "9:00AM",
      action: 1,
    },
    {
      slno: 2,
      name: {
        profileImage: "https://randomuser.me/api/portraits/men/2.jpg",
        name: "User 2",
      },
      email: "user2@example.com",
      category: "Category 2",
      date: "2022/01/2",
      timeSlot: "9:00AM",
      action: 2,
    },
    {
      slno: 3,
      name: {
        profileImage: "https://randomuser.me/api/portraits/men/3.jpg",
        name: "User 3",
      },
      email: "user3@example.com",
      category: "Category 3",
      date: "2022/01/3",
      timeSlot: "9:00AM",
      action: 3,
    },
    {
      slno: 4,
      name: {
        profileImage: "https://randomuser.me/api/portraits/men/4.jpg",
        name: "User 4",
      },
      email: "user4@example.com",
      category: "Category 1",
      date: "2022/01/4",
      timeSlot: "9:00AM",
      action: 4,
    },
    {
      slno: 5,
      name: {
        profileImage: "https://randomuser.me/api/portraits/men/5.jpg",
        name: "User 5",
      },
      email: "user5@example.com",
      category: "Category 2",
      date: "2022/01/5",
      timeSlot: "9:00AM",
      action: 5,
    },
  ];

  const navigate = useNavigate();
  const [tableData, settableData] = useState([]);
  const [totalearnings, settotalearnings] = useState("");
  const [consultations, setConsultations] = useState("");
  const [popup, setPopup] = useState(false);

  // const togglePopup = () => {
  //   setPopup(!popup);
  // };

  const togglePopup = (slno) => {
    sessionStorage.setItem("Slno", slno); // Store slno in session storage
    setPopup(!popup);
  };
  

  const handleReload = () => {
    setPopup(!popup);
    window.location.reload();
  };

  useEffect(() => {
    console.log("on fetch data");
    const fetchUserConsults = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/agentbookings/${sessionStorage.getItem(
            "agentID"
          )}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData, "jsondata");
        if (jsonData) {
          const transformedData = jsonData.mybookings.map((item) => ({
            slno: item.postId,
            name: {
              profileImage: item.userProfile && item.userProfile !== "noname.png" 
                ? `https://www.mypropertypal.in/administrator/uploads/${item.userProfile}` 
                : "https://www.mypropertypal.in/administrator/uploads/noname.png",
              name: `${item.userFname} ${item.userLname}`,
            },            
            email: item.userEmail,
            category: item.consultingType,
            date: item.bookingDate,
            timeSlot: item.bookingTime,
            action: item.postId,
            agentId: item.agentId,
          }));
          settableData(transformedData);
          setSortedData(transformedData);
          settotalearnings(jsonData.totalconsultingfee.totalearnings);
          // console.log(settotalearnings(jsonData.totalconsultingfee.totalbookingfee),'helloe')
          console.log(transformedData);
          console.log(jsonData.totalconsultingfee.totalearnings);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserConsults();
    const fetchConsulting = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/agentconsultings/${sessionStorage.getItem(
            "agentID"
          )}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        if (data.myconsultings) {
          //   const transformedData = data.myconsultings.map(item => ({
          // id:item.consId,
          // type:item.consultingType,
          // fee:item.consultingFee,
          // status:item.conStatus
          // }
          // ));

          setConsultations(data.myconsultings.length);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchConsulting();
  }, []);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [sortedData, setSortedData] = useState(tableData);
  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });

    // Sort the data based on the key and direction
    let sorted;
    if (direction === "ascending") {
      sorted = [...tableData].sort(
        (a, b) => new Date(a[key]) - new Date(b[key])
      );
    } else if (direction === "descending") {
      sorted = [...tableData].sort(
        (a, b) => new Date(b[key]) - new Date(a[key])
      );
    } else {
      sorted = tableData;
    }
    setSortedData(sorted);
  };

  // const sortedData = React.useMemo(() => {
  //   let sortedData = [...data];
  //   if (sortConfig !== null) {
  //     sortedData.sort((a, b) => {
  //       if (a[sortConfig.key] < b[sortConfig.key]) {
  //         return sortConfig.direction === 'ascending' ? -1 : 1;
  //       }
  //       if (a[sortConfig.key] > b[sortConfig.key]) {
  //         return sortConfig.direction === 'ascending' ? 1 : -1;
  //       }
  //       return 0;
  //     });
  //   }
  //   return sortedData;
  // }, [data, sortConfig]);

  // const columns = [
  //   { Header: '#', accessor: 'slno', disableSortBy: true },
  //   { Header: 'Name', accessor: 'name', disableSortBy: true, Cell: NameCell },

  //   { Header: 'Email', accessor: 'email', disableSortBy: true },
  //   { Header: 'Category', accessor: 'category', disableSortBy: true },
  //   {
  //     Header: (
  //       <div style={{ display: 'flex', flexDirection: 'row' }}>
  //         Date
  //         {sortConfig && sortConfig.key === 'date' && (
  //           <span style={{ marginLeft: '5px' }}>
  //             {sortConfig.direction === 'ascending' ? '🔼' : '🔽'}
  //           </span>
  //         )}
  //       </div>
  //     ),
  //     accessor: 'date',
  //     disableSortBy: true,
  //     Cell: ({ cell }) => <div>{cell.value}</div>, // Render date value
  //   },
  //   { Header: 'Time Slot', accessor: 'timeSlot', disableSortBy: true },
  //   { Header: 'Action', accessor: 'action', Cell: ActionCell, disableSortBy: true },
  // ];
  const handleviewall = () => {
    navigate("/appointments");
  };
  return (
    <div>
      <div className="userdash d-heading-1">
        <div className="wrapper-2">
          <h1>Dashboard</h1>
          <div className="userdash_cards">
            <div className="userdash_card">
              <div className="userdash_card-title">
                Total Appointments So Far
              </div>
              <div className="userdash_card-word1">1st Jan 24 to Till Now</div>
              <div className="userdash_card-word2">{tableData.length}</div>
            </div>

            <div className="userdash_card">
              <div className="userdash_card-title"> Total Consultations</div>
              {/* <div className="userdash_card-word1">1st Jan 24 to Till Now</div>  */}
              <div className="userdash_card-word2">{consultations}</div>
            </div>

            <div className="userdash_card">
              <div className="userdash_card-title"> Total Earning So Far</div>
              <div className="userdash_card-word1">1st Jan 24 to Till Now</div>
              <div className="userdash_card-word2">
                <img className="dash_icon" src={bigruppe} />
                {totalearnings}
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper-2">
          <div
            className="userdash_view_all"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className="p-tb-0">Recent Appointments</h3>
            <button
              style={{ width: "128px" }}
              onClick={handleviewall}
              className="find_button"
            >
              View All
            </button>
          </div>

          <div className="usertable_div">
            <div>{/* Dashboard content */}</div>
            <table className="usertable">
              <thead>
                <tr>
                  <th> # </th>
                  <th>Name</th>
                  {/* <th>Email</th> */}
                  <th>Category</th>
                  <th onClick={() => requestSort("date")}>
                    Date
                    {sortConfig &&
                      sortConfig.key === "date" &&
                      sortConfig.direction !== null && (
                        <>
                          {sortConfig.direction === "ascending" ? (
                            <span style={{ marginLeft: "5px" }}>🔼</span>
                          ) : (
                            <span style={{ marginLeft: "5px" }}>🔽</span>
                          )}
                        </>
                      )}
                    {!sortConfig ||
                      (sortConfig.key !== "date" &&
                        sortConfig.direction === null && (
                          <img
                            src={up_down}
                            alt="Sort"
                            style={{ marginLeft: "5px", height: "15px" }}
                          />
                        ))}
                  </th>
                  <th>Time Slot</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((row) => (
                  <tr key={row.slno}>
                    <td>{row.slno}</td>
                    <td>
                      <div className="user-profile-cell">
                        <img src={row.name.profileImage} alt={row.name.name} />
                        <span>{row.name.name}</span>
                      </div>
                    </td>
                    {/* <td>{row.email}</td> */}
                    <td>{row.category}</td>
                    <td>{row.date}</td>
                    <td>{row.timeSlot}</td>
                    <td>
                      <div>
                        <Link
                          to="/Agent_Remarks"
                          state={{
                            rowData: row,
                            url: window.location.pathname,
                          }}
                        >
                          <button
                            style={{ backgroundColor: "white", border: "none" }}
                          >
                            <img src={remarks} alt="Agent Remarks" />
                          </button>
                        </Link>
                        <Link
                          to="/Reschedule"
                          state={{
                            rowData: row,
                            url: window.location.pathname,
                          }}
                        >
                          <button
                            style={{ backgroundColor: "white", border: "none" }}
                          >
                            <img src={reschedule} alt="Reschedule" />
                          </button>
                        </Link>
                        {/* ///////////// */}
                        <button
                         onClick={() => togglePopup(row.slno)} 
                          style={{ backgroundColor: "white", border: "none" }}
                        >
                          <img src={metting} alt="metting" />
                        </button>

                        {popup && (
                          <div className="popup">
                            <div className="popup-inner">
                              {/* {video} */}
                              <div className="close-btn-1">
                                <button onClick={handleReload}>
                                  {" "}
                                  <img src={closebtn} alt="close" />
                                </button>
                              </div>
                              <div>
                                <VideoNew />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="dash">
        {/* <div className="dash_heading">
        <p>Dashboard</p>
      </div> */}
        {/* <div className="dash_cards">
        <div className="dash_card">
          <div className="dash_card-title">Total Appointments So Far</div>
          <div className="dash_card-word1">1st Jan 24 to Till Now</div>
          <div className="dash_card-word2">{tableData.length}</div>
        </div>
        <div className="dash_card">
          <div style={{ marginBottom: "30%" }} className="dash_card-title">
            Total Consultations
          </div>

          <div className="dash_card-word2">{consultations}</div>
        </div>
        <div className="dash_card">
          <div className="dash_card-title">Total Earning So Far</div>
          <div className="dash_card-word1">1st Jan 24 to Till Now..</div>
          <div className="dash_card-word2">
            <img className="dash_icon" src={bigruppe} />
            {totalearnings}
          </div>
        </div>
      </div> */}
        {/* <div
        className="dash_view_all"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="dash_heading">Recent Appointments</p>
        <button onClick={handleviewall} className="find_button">
          View All
        </button>
      </div> */}
      </div>
    </div>
    // dash
  );
};
const NameCell = ({ cell: { value } }) => {
  return (
    <div className="user-profile-cell">
      <img src={value.profileImage} alt={value.name} />
      <span>{value.name}</span>
    </div>
  );
};

const ActionCell = ({ row }) => {
  const referringPageUrl = encodeURIComponent(window.location.pathname);
  const rowData = row.values;

  return (
    <div>
      <Link to="/Agent_Remarks" state={{ rowData, url: referringPageUrl }}>
        <button style={{ backgroundColor: "white", border: "none" }}>
          <img src={remarks} alt="Agent Remarks" />
        </button>
      </Link>
      <Link to="/Reschedule" state={{ rowData, url: referringPageUrl }}>
        <button style={{ backgroundColor: "white", border: "none" }}>
          <img src={reschedule} alt="Reschedule" />
        </button>
      </Link>
    </div>
  );
};

export default Dash;
