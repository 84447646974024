// Contact.js
import React from "react";
import { useRef, useState, useEffect } from "react";
import search from "./img/search.svg";
import location from "./img/location.svg";
import bgdiv from "./img/bgdiv.png";
import agent1 from "./img/agent1.png";
import agent2 from "./img/agent2.png";
import agent3 from "./img/agent3.png";
import agent4 from "./img/agent4.png";
import agent5 from "./img/agent5.png";
import "./Find_Agents.css";
import { useNavigate } from "react-router-dom";
const Find_Agnets = ({ jsonData }) => {
  const [selectedOption, setSelectedOption] = useState("city");
  const [selectedCategory, setSelectedCategory] = useState("category");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const words = ["Real Estate", "Property Purchase", "Legal Property Advisor"];
  const locations = [
    "Hyderabad",
    "Andhra Pradesh",
    "delhi",
    "mumbai",
    "banglore",
  ];
  const [carousel_data, setcarousel_data] = useState([]);
  const [cities, setcities] = useState([]);

  const handleSelectChange = (e) => {
    const selectedCity = e.target.value;
    setSelectedOption(selectedCity);

    // Clear error message if both category and city are selected
    if (selectedCity !== "city" && selectedCategory !== "category" && selectedCategory) {
      setErrorMessage("");
    }
  };
   const handleSelectCategory = (e) => {
    const selectedCat = e.target.value;
    setSelectedCategory(selectedCat);
    // Clear error message if both category and city are selected
    if (selectedOption !== "city" && selectedOption && selectedCat !== "category") {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/homecities"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setcities(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setcarousel_data(jsonData);
  }, [jsonData]);


  const handlesearch = () => {
    if (!selectedCategory || selectedCategory === "category" || !selectedOption || selectedOption === "city") {
      setErrorMessage("*Please select both a city and a category to proceed.");
    } else {
      setErrorMessage(""); // Clear any previous error message
      navigate("/location_agent", {
        state: { consultationType: selectedCategory, city: selectedOption },
      });
    }
  };;

  const data = [
    {
      id: 1,
      imageUrl: agent5,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 2,
      imageUrl: agent4,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 3,
      imageUrl: agent3,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 4,
      imageUrl: agent2,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 5,
      imageUrl: agent1,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 6,
      imageUrl: agent5,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 7,
      imageUrl: agent4,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 8,
      imageUrl: agent3,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 9,
      imageUrl: agent2,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
    {
      id: 10,
      imageUrl: agent1,
      name: "John Doe",
      membertype: "Gold Member",
      price: "500",
    },
  ];

  return (
    <div className="Find_Agents">
      <div className="wrapper-1">
        <div className="find_div ">
          <div
            className="find_border"
            style={{ border: "0px", boxShadow: "none", marginIinline: "20px" }}
          >
            <h2>Find Consultants</h2>

            <div className="find-agents-box-wrap">
              <div className="find-agents-box-1">
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  className="location-icon"
                >
                  <option value="city" disabled hidden>
                    Select City
                  </option>
                  {cities.map((value) => (
                    <option key={value.city_id} value={value.city_name}>
                      {value.city_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="find-agents-box-2">
                <select
                  id="dropdown"
                  value={selectedCategory}
                  onChange={handleSelectCategory}
                  className="search-icon"
                >
                  <option value="category" disabled hidden>
                    Select Category
                  </option>
                  {carousel_data.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.consultingType}
                    </option>
                  ))}
                </select>
              </div>
              <div className="find-agents-box-3">
                <button onClick={handlesearch} className="button-a-1">
                  Search
                </button>
              </div>
            </div>
            {errorMessage && (
            <div style={{ color: 'red', marginTop: '10px' }}>
              {errorMessage}
            </div>
          )}

            {/* <div className="find_secdiv">
            <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "5%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                style={{ border: "none" }}
              >
                <option value="city" disabled hidden>
                  Select City
                </option>
                {cities.map((value) => (
                  <option key={value.city_id} value={value.city_name}>
                    {value.city_name}
                  </option>
                ))}
              </select>
            </div>
           <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "5%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedCategory}
                onChange={handleSelectCategory}
                style={{ border: "none" }}
              >
                <option value="category" disabled hidden>
                  Select Category
                </option>
                {carousel_data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.consultingType}
                  </option>
                ))}
              </select>
            </div>
          
            <div className="find_buttondiv">
              <button onClick={handlesearch} className="find_button">
                Search
              </button>
            </div>
           
          </div>
          */}

            <div className="find_thirddiv">
              <p className="find_hint">
                Popular Searches:{" "}
                {words.map((word, index) => (
                  <React.Fragment key={index}>
                    <span>{word}</span>
                    {index !== words.length - 1 && <span> </span>}
                  </React.Fragment>
                ))}{" "}
                Etc..
              </p>
            </div>
            {/* find third div */}
          </div>
          {/* find border */}
        </div>
      </div>
      {/*  find_div */}

      {/* <div className="find_div">
        <div className="find_border" style={{ boxShadow: "none" }}>
          <div className="find_firstdiv">
            <p>Find Agents</p>
          </div>

          <div className="find_secdiv">
            <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "6%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
                style={{ border: "none" }}
              >
                <option value="city" disabled hidden>
                  Select City
                </option>
                {cities.map((value) => (
                  <option key={value.city_id} value={value.city_name}>
                    {value.city_name}
                  </option>
                ))}
              </select>
            </div>
           
            <div className="find_select">
              <div className="location-icon" style={{ paddingRight: "5%" }}>
                {" "}
                <img
                  src={location}
                  alt="location icon"
                  className="locaiton_img"
                />
              </div>

              <select
                id="dropdown"
                value={selectedCategory}
                onChange={handleSelectCategory}
                style={{ border: "none" }}
              >
                <option value="category" disabled hidden>
                  Select Category
                </option>
                {carousel_data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.consultingType}
                  </option>
                ))}
              </select>
            </div>

            <div className="find_buttondiv">
              <button onClick={handlesearch} className="find_button">
                Search
              </button>
            </div>
          </div>

          <div className="find_thirddiv">
            <p className="find_hint">
              Popular Searches:{" "}
              {words.map((word, index) => (
                <React.Fragment key={index}>
                  <span>{word}</span>
                  {index !== words.length - 1 && <span> </span>}
                </React.Fragment>
              ))}{" "}
              Etc..
            </p>
          </div>
        </div>
      </div> */}

      <div className="custom-line"></div>
      <div className="top-agents-p-wrap">
        <div className="wrapper-1">
          <h2>Top Consultants</h2>
          <div className="top-agents-p-main">
            {data.map((item) => (
              <div key={item.id} className="top-agents-p-1">
                <div className="profile-img-1-1"><img
                  src={item.imageUrl}
                  alt={`Image ${item.id}`}
                  className="img"
                /></div>
                <h4>{item.name}</h4>
                <h6>{item.membertype}</h6>
                <h5>₹{item.price}</h5>
                <button>Consult Now</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    // parent
  );
};

export default Find_Agnets;
