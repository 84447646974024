// Contact.js
import React from "react";

import "./booking.css";
import thankyou from './img/Slot-Booked.png'
import {  useNavigate } from 'react-router-dom';


const Thankyou = () => {
  const navigate = useNavigate();
  const handleChange = () => {
    navigate ('/');
  }
  
  return (
    <>
    <div className="thank-you"><img src={thankyou} alt={thankyou}/>
    
    
    
    </div>
    <div className="thank-you"><button className="button-a-2" onClick={handleChange}>Back to Home</button>
    
    
    
    </div>
    
   
    </>
  );
};

export default Thankyou;
