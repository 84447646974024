import React, { useMemo, useEffect, useState } from "react";
import { useTable, usePagination } from "react-table";
import topic1 from "./img/topic1.png";
import image1 from "./img/bgdiv.png";
// Dummy data for the table

const Blog = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    console.log("on fetch data");

    const fetchblogs = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/getblogs"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData.categories, "jsondata");
        if (jsonData) setblogs(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchblogs();
    //console.log(blogs);
  }, []);
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "title",
        Cell: ({ row }) => (
          <>
            <div className="blog-card-p-1-img">
              <img
                src={`https://www.mypropertypal.in/administrator/uploads/${row.original.postImage}`}
                alt="MPP"
              />
            </div>
            <h5>{row.original.postTitle}</h5>
            <p>{truncateWords(row.original.postContent, 50)}</p>
          </>
        ),
      },
    ],
    []
  );

  const groupedData = useMemo(() => {
    const grouped = [];
    for (let i = 0; i < blogs.length; i += 3) {
      grouped.push(blogs.slice(i, i + 3));
    }
    console.log(grouped.flat());
    return grouped.flat(); // Flatten the array
  }, [blogs]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: groupedData,
      initialState: { pageIndex: 0, pageSize: 9 }, // Set pageSize to 3 for 3 rows per page
    },
    usePagination
  );
  function truncateWords(text, limit) {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  }

  const faqs = [
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },
    {
      question: "Lorem Ipsum is simply dummy text ",
      answer:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum...",
    },

    // Add more FAQ items as needed
  ];

  return (
    <div>
      <div
      className="Blog"
      style={{
        backgroundImage: `url(${image1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingBottom: "1%",
      }}
    >
      <div className="wrapper-1">
        <h1>Faq's</h1>

        {/* <div
      className="Blog"
      style={{
        backgroundImage: `url(${image1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingBottom: "1%",
      }}
    >
      <div className="wrapper-1">
        <h1 className="text-center">Blog</h1>
        <div className="blog-card-p-main" {...getTableProps()}>


          
        {page.map((row) => {
          prepareRow(row); 
          return (
            <div key={row.id} className="blog-card-p-1">
              {row.cells.map((cell) => {
                return <>{cell.render("Cell")}</>;
              })}
              
            </div>
          );
        })}
      </div>
      </div>
      
      <div className="custom-line" style={{ marginInline: "10%" }}></div>
      <div
        className="pagination"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "5%",
        }}
      >
        
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="fb_page-btn prev-next"
        >
          {"Prev"}
        </button>{" "}
        {pageOptions.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => gotoPage(pageNumber)}
            className={`fb_page-btn ${
              pageIndex === pageNumber ? "active" : ""
            }`}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="fb_page-btn prev-next"
        >
          {"Next"}
        </button>{" "}
        
      </div>
    </div> */}


      <div>

    
        <div className="faq-container">
          {faqs.map((faq, index) => (
            <div key={index} className="faq">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
              </div>
              <div
                className={`faq-answer ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
