// import React, { useState, useRef } from "react";
// import { NavLink } from "react-router-dom";
// import "./user_login.css";
// import Image2 from "./img/userbg.png";
// import { useNavigate } from "react-router-dom";
// const User_login = ({ isloggedIn }) => {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const { loggedin, setloggedin } = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [ErrorMsg, SetErroMsg] = useState("");
//   // const handleLogin = () => {

//   //     console.log('Login clicked with email:', email, 'and password:', password);
//   //    isloggedIn('true')
//   //    navigate('/');
//   //    sessionStorage.setItem('isloggedin','true')
//   // };
//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(email);
//   };

//   const handleLogin = async () => {
//     // isloggedIn('true')
//     // navigate('/');
//     if (!sessionStorage.getItem("userID")) {
//       sessionStorage.setItem("isloggedin", "true");
//       console.log(
//         "Login clicked with email:",
//         email,
//         "and password:",
//         password
//       );
//       if (!validateEmail(email)) {
//         setEmailError("*Please enter a valid email address.");
//         return;
//       }
//       try {
//         const response = await fetch(
//           "https://www.mypropertypal.in/administrator/postuserlogin",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ email, password }),
//           }
//         );
//         const data = await response.json();
//         console.log("Response:", data);

//         if (data.message === "Login Successful.") {
//           sessionStorage.setItem("userID", data.userprofiledet.userId);
//           // You can also set other data to sessionStorage if needed
//        //   alert("loggedin ");

//           console.log(
//             "Login clicked with email:",
//             email,
//             "and password:",
//             password
//           );
//           isloggedIn("true");
//         //  alert(sessionStorage.getItem("userID"));
//           navigate("/");
//           sessionStorage.setItem("isloggedin", "true");
//         } else {
//           // Handle other responses like incorrect password or email not found
//           // console.error("Login Failed:", data.message);
//           // alert(`Login Failed ${data.message}`);
//           SetErroMsg(`*Couldn't find your MyPropertyPal Acc \n Please double-check try again.`)
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       }
//     } else {
//       alert("already loggedin");
//     }
//   };

//   const handleForgotPassword = () => {
//     navigate("/user_forgot");
//     console.log("Forgot Password clicked");
//   };

//   const handleSignUp = () => {
//     navigate("/user_signUp");
//   };
//   // useEffect(() => {

//   //   }, [loggedin]);

//   return (
//     <div
//       className="user_Login"
//       style={{
//         backgroundImage: `url(${Image2})`,
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//       }}
//     >
//       <div className="userlogin_form">
//         <h1>User Login</h1>
//         <input
//           type="email"
//           placeholder="Email"
//           value={email}
//           onChange={(e) => {
//             setEmail(e.target.value);
//             setEmailError(''); // Clear email error
//             SetErroMsg(''); // Clear general error message
//         }}
//           className="input-field-2"
//         />

//         {emailError && <p style={{ color: "red" }}>{emailError}</p>}
//         <input
//           type="password"
//           placeholder="Password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           className="input-field-2"
//         />
//         <div className="forgot-password-1" onClick={handleForgotPassword}>
//           Forgot Password?
//         </div>

//         <button onClick={handleLogin} className="find_button button-3-padding">
//           Login
//         </button>
//         {ErrorMsg && (
//                     <div style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}>
//                         {ErrorMsg.split('\n').map((line, index) => (
//                             <React.Fragment key={index}>
//                                 {line}
//                                 <br />
//                             </React.Fragment>
//                         ))}
//                     </div>
//                 )}
//         <div className="signup">
//           <p>Not a member yet?</p>{" "}
//           <p
//             style={{ cursor: "pointer" }}
//             onClick={handleSignUp}
//             className="signup-link"
//           >
//             Sign Up
//           </p>
//         </div>
//       </div>
//       {/* login-form */}
//     </div>
//   );
// };

// export default User_login;


import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./user_login.css";
import Image2 from "./img/userbg.png";

const User_login = ({ isloggedIn }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [ErrorMsg, SetErroMsg] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleLogin = async () => {
    try {
      // Validate email format
      if (!validateEmail(email)) {
        setEmailError("*Please enter a valid email address.");
        return;
      }

      // Perform login API call
      const response = await fetch(
        "https://www.mypropertypal.in/administrator/postuserlogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );
      const data = await response.json();
      console.log("Response:", data);

      // Check login success
      if (data.message === "Login Successful.") {
        sessionStorage.setItem("userID", data.userprofiledet.userId);
        sessionStorage.setItem("isloggedin", "true");

        // Set loggedIn state in parent component
        isloggedIn("true");

        // Navigate to home page
        navigate("/");
      } else {
        // Handle login failure
        SetErroMsg(
          `*Couldn't find your MyPropertyPal Acc \n Please double-check try again.`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate("/user_forgot");
    console.log("Forgot Password clicked");
  };

  const handleSignUp = () => {
    navigate("/user_signUp");
  };

  return (
    <div
      className="user_Login"
      style={{
        backgroundImage: `url(${Image2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="userlogin_form">
        <h1>User Login</h1>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError(""); // Clear email error on input change
            SetErroMsg(""); // Clear general error message on input change
          }}
          className="input-field-2"
        />
        {emailError && <p style={{ color: "red" }}>{emailError}</p>}
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field-2"
        />
        <div className="forgot-password-1" onClick={handleForgotPassword}>
          Forgot Password?
        </div>

        <button onClick={handleLogin} className="find_button button-3-padding">
          Login
        </button>
        {ErrorMsg && (
          <div style={{ color: "red", marginBottom: "10px", textAlign: "center" }}>
            {ErrorMsg.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        )}
        <div className="signup">
          <p>Not a member yet?</p>{" "}
          <p
            style={{ cursor: "pointer" }}
            onClick={handleSignUp}
            className="signup-link"
          >
            Sign Up
          </p>
        </div>
      </div>
    </div>
  );
};

export default User_login;
