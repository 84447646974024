import React, { useState, useEffect } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { Link, useLocation } from "react-router-dom";
import "./Appointments.css";
import up_down from "./img/up&down.svg";
import sort_arrow from "./img/icon_remarks.svg";
import reschedule from "./img/icon_reschedule.svg";
import remarks from "./img/icon_remarks.svg";
import { format } from "date-fns";
import VideoNew from "./VideoNew";
import metting from "./img/agent_metting.svg";
import closebtn from "./img/close.png";

function getRandomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const startDate = new Date("2022-01-01");
const endDate = new Date("2022-12-31");

const Appointments = () => {
  const [tableData, settableData] = useState([]);
  const [popup, setPopup] = useState(false);

  const handleReload = () => {
    setPopup(!popup);
    window.location.reload();
  };

  const togglePopup = (slno) => {
    sessionStorage.setItem("Slno", slno); // Store slno in session storage
    setPopup(!popup);
  };

  useEffect(() => {
    console.log("on fetch data");
    const fetchUserConsults = async () => {
      try {
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/agentbookings/${sessionStorage.getItem("agentID")}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        console.log("fetchblogs");
        const jsonData = await response.json();
        console.log(jsonData, "jsondata");
        if (jsonData) {
          const transformedData = jsonData.mybookings.map((item) => ({
            slno: item.postId,
            name: {
              profileImage:
                item.userProfile && item.userProfile !== "noname.png"
                  ? `https://www.mypropertypal.in/administrator/uploads/${item.userProfile}`
                  : "https://www.mypropertypal.in/administrator/uploads/noname.png",
              name: `${item.userFname} ${item.userLname}`,
            },
            email: item.userEmail,
            category: item.consultingType,
            date: item.bookingDate,
            timeSlot: item.bookingTime,
            action: item.postId,
            agentId: item.agentId,
          }));
          settableData(transformedData);
          console.log(transformedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserConsults();
  }, []);
  useEffect(() => { }, [tableData]);
  let data = React.useMemo(() => tableData, [tableData]);
  const [showSortArrow, setShowSortArrow] = useState(false);

  console.log("in table", data.length);
  const columns = React.useMemo(
    () => [
      { Header: "#", accessor: "slno", disableSortBy: true },
      {
        Header: "Name",
        accessor: "name",
        Cell: UserProfileCell,
        disableSortBy: true,
      },
      // { Header: "Email", accessor: "email", disableSortBy: true }, //removed by P
      { Header: "Category", accessor: "category", disableSortBy: true },
      {
        Header: ({ column }) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            Date
            {column.isSorted ? (
              <span style={{ marginLeft: "5px" }}>
                {column.isSortedDesc ? "🔼" : "🔽"}
              </span>
            ) : (
              <img src={up_down} style={{ marginLeft: "5px", marginTop: "2%" }} />
            )}
          </div>
        ),
        accessor: "date",
        Cell: ({ value }) => {
          const date = new Date(value);
          return format(date, "dd/MM/yyyy");
        },
      },
      { Header: "Time Slot", accessor: "timeSlot", disableSortBy: true },
      {
        Header: "Action",
        accessor: "action",
        Cell: (props) => <ActionCell {...props} togglePopup={togglePopup} popup={popup} handleReload={handleReload} />,
        disableSortBy: true,
      },
    ],
    [showSortArrow, popup]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 3 },
    },
    useSortBy,
    usePagination
  );
  const totalPages = pageOptions.length;
  const visiblePageCount = Math.min(10, pageCount);
  const renderPageButtons = () => {
    let startPage = Math.max(0, pageIndex - 2);
    let endPage = Math.min(totalPages - 1, pageIndex + 2);

    if (endPage - startPage < 4) {
      if (startPage === 0) {
        endPage = Math.min(4, totalPages - 1);
      } else {
        startPage = Math.max(0, totalPages - 5);
      }
    }

    return Array.from(
      { length: endPage - startPage + 1 },
      (_, index) => startPage + index
    );
  };
  return (
    <div className="wrapper-2 style-1">
      <h1>Consultations</h1>
      <div className="usertable_div">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="fb_pagination" style={{ marginLeft: "1%" }}>
          <div>
            <span>
              Showing{" "}
              <strong>
                {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length}
              </strong>{" "}
            </span>
          </div>
          <div>
            <button onClick={() => previousPage()} disabled={!canPreviousPage || pageIndex === 0} className="fb_page-btn prev-next">
              Prev
            </button>
            <button onClick={() => gotoPage(0)} disabled={pageIndex === 0} className="fb_page-btn min-max">
              {"<<"}
            </button>
            {renderPageButtons().map((pageNumber) => (
              <button key={pageNumber} onClick={() => gotoPage(pageNumber)} className={`fb_page-btn ${pageIndex === pageNumber ? "active" : ""}`}>
                {pageNumber + 1}
              </button>
            ))}
            <button onClick={() => gotoPage(totalPages - 1)} disabled={pageIndex === totalPages - 1} className="fb_page-btn min-max">
              {">>"}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage || pageIndex === totalPages - 1} className="fb_page-btn prev-next">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserProfileCell = ({ cell: { value } }) => {
  return (
    <div className="user-profile-cell">
      {value.profileImage && <img src={value.profileImage} alt={value.name} />}
      <span>{value.name}</span>
    </div>
  );
};

const ActionCell = ({ row, togglePopup, popup, handleReload }) => {
  const referringPageUrl = encodeURIComponent(window.location.pathname);
  const rowData = row.values;
  const slno = rowData.slno; 
  return (
    <div>
      <Link to="/Agent_Remarks" state={{ rowData, url: referringPageUrl }}>
        <button style={{ backgroundColor: "white", border: "none" }}>
          <img src={remarks} alt="Agent Remarks" />
        </button>
      </Link>
      <Link to="/Reschedule" state={{ rowData, url: referringPageUrl }}>
        <button style={{ backgroundColor: "white", border: "none" }}>
          <img src={reschedule} alt="Reschedule" />
        </button>
      </Link>
      <button onClick={() => togglePopup(slno)} style={{ backgroundColor: "white", border: "none" }}>
        <img src={metting} alt="metting" />
      </button>

      {popup && (
        <div className="popup">
          <div className="popup-inner">
            {/* {video} */}
            <div className="close-btn-1">
              <button onClick={handleReload}>
                {" "}
                <img src={closebtn} alt="close" />
              </button>
            </div>
            <div>
              <VideoNew />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointments;
