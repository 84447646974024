import React, { useState, useEffect } from 'react';
import close from './img/close.svg';
import { set } from 'date-fns';

// Define the Component
export default function Cities() {
  const [data, setdata] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCityids, setSelectedCityids] = useState([]);
  const [selectedCityid, setSelectedCityid] = useState();
  const [selectedCities, setSelectedCities] = useState(() => {
    const savedCities = localStorage.getItem('selectedCities');
    return savedCities ? JSON.parse(savedCities) : [];
  });
  const [postcities, setpostcities] = useState([]);
  const [postids, setpostids] = useState([]);

  const agentid = sessionStorage.getItem('agentID');
  console.log(agentid, 'agentid');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.mypropertypal.in/administrator/getstates');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setdata(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Fetch selected cities from API
  useEffect(() => {
    const fetchSelectedCities = async () => {
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/agentcities/${agentid}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log('jjjjjjj:',jsonData)
        const d = jsonData['mycities'];
        setSelectedCities(d.map(city => city.cityname));
        setSelectedCityids(d.map(city => city.cityid));
        setpostcities(d.map(city => city.cityname));
        setpostids(d.map(city => city.cityid));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSelectedCities();
  }, [agentid]);

  useEffect(() => {
    localStorage.setItem('selectedCities', JSON.stringify(selectedCities));
  }, [selectedCities]);

  // Dummy data for Indian states and cities
  const indianStates = Object.keys(data);
  const cities = {
    'Andhra Pradesh': ['Hyderabad', 'Visakhapatnam', 'Ananthapur', 'vijayawada', 'kurnool'],
    'Rajasthan': ['Jaipur'],
    'Tamil Nadu': ['Chennai']
  };

  // Event handler to handle state selection
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  // Event handler to handle city selection
  const handleCityChange = (event) => {
    const { id, name } = JSON.parse(event.target.value);
    setSelectedCity(name);
    setSelectedCityid(id);
  };

 
const handlesave = async (e) => {
    e.preventDefault();

    // Assuming you have these values stored in state variables
    const cityname = selectedCity;
    const cityid = selectedCityid;
    const agentid = sessionStorage.getItem('agentID');

    const ciitiespost = { "cityname": cityname, "agentid": agentid, 'cityid': cityid };
    const apiUrl = "https://www.mypropertypal.in/administrator/postcities";
    const option = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(ciitiespost),
    };

    try {
        const resposne = await fetch(apiUrl, option);
        const data = await resposne.json();
        console.log(data);
        if (data.status === 500) {
            console.log("invalid data");
        } else {
            console.log("valid data");
        }
    } catch (error) {
        console.error('Error saving data:', error);
        // Additional error handling logic can be added here
    }
};

  
  

  // Event handler to add city to selected cities
  const handleAddCity = () => {
    if (selectedCity && !selectedCities.includes(selectedCity)) {
  
      setSelectedCities([...selectedCities, selectedCity]);
      setpostcities([...postcities, selectedCity]);
      setpostids([...postids, selectedCityid]);
      setSelectedCityids([...selectedCityids, selectedCityid]);
    }
  };

  // Event handler to remove city from selected cities
  const handleRemoveCity = (city) => {
    setSelectedCities(selectedCities.filter(c => c !== city));
    const removeData = async () => {
      try {
        const response = await fetch(`https://www.mypropertypal.in/administrator/deleteagentcity/${selectedCityids[selectedCities.indexOf(city)]}/${agentid}`, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data1 = await response.json();
        console.log('resposnse from post', data1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    removeData();
  };


  return (
    <div className='wrapper-2'> 
    <h1 >Serviceable Cities</h1>
  
              
    {/* style={{ maxWidth: '85%', margin: 'auto', marginTop: '2.5rem', padding: '2rem', background: '#ffffff', borderRadius: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)' }} */}
    <div className='serviceablecities'>

   


      <div className='serviceablecities-main'>
        <select
          value={selectedState}
          onChange={handleStateChange}
          
        >
          <option value="">Select State</option>
          {indianStates.map(state => (
            <option key={state} value={state}>{state}</option>
          ))}
        </select>
        <select
          value={JSON.stringify({ id: selectedCityid, name: selectedCity })}
          onChange={handleCityChange}
          
        >
          <option value="">Select City</option>
          {data[selectedState] && data[selectedState].map(city => (
            <option key={city["city_id"]} 
            value={JSON.stringify({ id: city.city_id, name: city.city_name })}>{city["city_name"]}</option>
          ))}
        </select> 
        <button
          onClick={handleAddCity}
          disabled={!selectedCity || selectedCities.includes(selectedCity)}
         
        >
          Add City
        </button>
      </div>
      {selectedCities &&
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', marginBottom: '1rem' }}>
      
  {selectedCities.map((city,index) => (
    <Badge key={index} onRemove={() => handleRemoveCity(city)}>
      {city}
    </Badge>
  ))}
  
</div>
}
      <div style={{ borderTop: '1px solid #ccc', paddingTop: '1rem',marginTop:'5%' }}>
        <button onClick={handlesave} className='find_button' style={{marginTop:'1%',marginBottom:'2%'}}>Save</button>
      </div>
    </div>
    </div>
  );
}

// Badge Component
function Badge({ variant, children, onRemove }) {
  return (
    <div style={{ background:  '#ffffff' , color:'#233B58',border:'1px solid #707070', padding: '0.25rem 0.75rem', borderRadius: '1rem', display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '0.25rem' }}>{children}</span>
      <button onClick={onRemove} style={{ background: 'none', border: 'none', color: 'black', cursor: 'pointer' }}>
        &#x2715;
      </button>
    </div>
  );
}