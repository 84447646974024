import React from "react";
import "./About_Us.css";
import aboutus from "./img/about_us.png";
import image1 from "./img/bgdiv.png";
import works1 from "./img/works1.png";
import works2 from "./img/works2.png";
const About_Us = () => {
  const works = [
    {
      id: 1,
      imageSrc: works2,
      matter: {
        heading: "Discover Your Real Estate Path",
        subheading:
          "Explore a range of advisory services tailored to your unique real estate needs. Sign up effortlessly to unlock exclusive access to personalized advice. Whether you’re buying, selling, investing, or seeking legal insights, MypropertyPal guides you every step of the way.",
      },
    },
    {
      id: 2,
      imageSrc: works1,
      matter: {
        heading: "Connect with Expert Guidance",
        subheading:
          "Join our community and connect with experienced real estate advisors. Select your preferred advisory category, receive personalized consultations, and access exclusive resources. Empower yourself with expert insights to make well-informed decisions and transform your real estate experience.",
      },
    },
   
    // Add more data as needed
  ];
  return (
    <div>
      <div
        className="about_us"
        style={{
          backgroundImage: `url(${image1})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "100px",
          paddingTop: "30px",
          backgroundPosition: "bottom center",
          backgroundSize: '100%',
        }}
      >
        <div className="wrapper-1">
          <h1 className="text-center">About Us</h1>
          <div className="about_us_card">
          <div className="aboutus_left">
            <div className="aboutus-left-content">
            <h2>About Us</h2>
            <p>
              MypropertyPal, your trusted partner in navigating the dynamic
              world of real estate. At MypropertyPal, we understand that every
              real estate journey is unique, and that’s why we’re dedicated to
              providing personalized advisory services tailored to your specific
              needs.
            </p>
            <h4>Our Mission</h4>
            <p>
              Empowering individuals to make confident and informed decisions in
              their real estate endeavors is at the heart of our mission. We
              strive to be the go-to platform for reliable guidance, ensuring
              that you have the tools and insights necessary for success.
            </p>
            </div>
          </div>
          <div className="aboutus_right">
            <img src={aboutus} alt="aboutus" />
          </div>
         
        </div>



        </div>


        

        
      </div>
      <div className="wrapper-1">

          <h2 className="text-center">How it works</h2>
          
          <div className="works_grid-container">

            
          {works.map((item, index) => (
            <>
             <div className="about-sec-2-wrap"  key={item.id}>
            <div className="about-sec-2-1">
                 <h3>{item.matter.heading}</h3>
                <p>{item.matter.subheading}</p>
                </div>
            <div className="about-sec-2-2">
              
            <img
                  className="works_img"
                  src={item.imageSrc}
                  alt={`works${item.id}`}
                />
            </div>

          </div>



            {/* <div className="works_grid-item">
              <div className="works_img_container">
                <img
                  className="works_img"
                  src={item.imageSrc}
                  alt={`works${item.id}`}
                />
              </div>
              <div className="works_matter">
                <p className="works_topic">{item.matter.heading}</p>
                <p className="works_info">{item.matter.subheading}</p>
              </div>
            </div> */}
            </>
            
          ))}
        </div>


        </div>
      
    </div>
  );
};

export default About_Us;
