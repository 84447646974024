import React, { useState, useEffect } from "react";
import "./user_profile.css"; // Import CSS file for component styling
import AgentImage from "./img/agent1.png"; // Import image for profile
import cam from "./img/cam.png";
import profile from "./img/profile.png";

export default function PersonalInfo() {
  const [formData, setFormData] = useState({
    userId: "",
    userFname: "",
    userLname: "",
    userEmail: "",
    phoneNumber: "",

    userAddress: "",
    userState: "",
    userCity: "",
    userPincode: "",
    userPassword: "",
    confirmPassword: "",
    userProfile: "null",
    data: "",
    passwordError: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get userId from sessionStorage
        const userId = sessionStorage.getItem("userID");
        if (!userId) {
          throw new Error("User ID not found in sessionStorage");
        }

        // Fetch data from the API endpoint using the userId
        const response = await fetch(
          `https://www.mypropertypal.in/administrator/userprofile/${userId}`
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Parse response data
        const data = await response.json();
        console.log("API response:", data);
        // Update form data with fetched data
        setFormData(data.profiledet);
      } catch (error) {
        console.error("Error fetching data:", error);
        console.log("Response:", error.response);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if passwords match
    const { userPassword, confirmPassword } = formData;

    // Check if confirmPassword is empty
    if (confirmPassword === "") {
      setFormData((prevState) => ({
        ...prevState,
        passwordError: "* Please confirm the password",
      }));
      return;
    }

    // Check if passwords match
    if (userPassword !== confirmPassword) {
      setFormData((prevState) => ({
        ...prevState,
        passwordError: "* Passwords do not match",
      }));
      return;
    } else {
      setFormData((prevState) => ({
        ...prevState,
        passwordError: "",
      }));
    }

    // Continue with API call
    const userId = sessionStorage.getItem("userID");
    alert(userId)
    if (!userId) {
      console.error("User ID not found in sessionStorage");
      return;
    }


    const {
      userFname,
      userLname,
      userEmail,
      phoneNumber,
      userAddress,
      userState,
      userCity,
      userPincode,
    } = formData;
    const NewFormData = {
      userId,
      userFname,
      userLname,
      userEmail,
      phoneNumber,
      userAddress,
      userState,
      userCity,
      userPincode,
      userPassword,
      confirmPassword,
    };

    const apiUrl =
      "https://www.mypropertypal.in/administrator/updateuserprofile";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(NewFormData),
    };

    try {
      const response = await fetch(apiUrl, options);
      if (response.ok === true) {
        const data = await response.json();
        console.log(data);
        if (data) {
          console.log("User Profile updated successfully");
        } else {
          console.log("Failed to update user profile");
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }

    // Handle file upload
    // const file = formData.userProfile;
    // if (!file) {
    //   alert("Please select a file.");
    //   return;
    // }

    // const formDataForFileUpload = new FormData();
    // formDataForFileUpload.append("file", file);
    // //formDataForFileUpload.append("userId", userId);
    // const fileName = file.name;
    // alert(fileName);

    // console.log('formDataForFileUpload:' , formDataForFileUpload);

    // // `https://www.psmprojects.net/mypropertypal/updateuserpic/${userId}`
    // fetch(`https://www.mypropertypal.in/administrator/updateuserpic/${userId}`, {
    //   method: "POST",
    //   body: formDataForFileUpload,
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     // Check if response has content type JSON
    //     const contentType = response.headers.get("content-type");
    //     if (contentType && contentType.indexOf("application/json") !== -1) {
    //       // Response contains JSON data, parse it
    //       return response.json();
    //     } else {
    //       // Response is not JSON, return a placeholder response
    //       return { success: true, message: "Upload successful." };
    //     }
    //   })
    //   .then((data) => {
    //     console.log("Upload successful:", data);
    //     window.location.reload();
    //     // Handle success, if needed
    //   })
    //   .catch((error) => {
    //     console.error("Upload error:", error);
    //   });

    window.location.reload();
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      alert("Please select a file.");
      return;
    }

    const userid= sessionStorage.getItem("userID");
    if (!userid) {
      console.error("User ID not found in sessionStorage");
      return;
    }

    // Create form data
    const formData = new FormData();
    formData.append("file", file);
    const fileName = file.name;
    alert(fileName);

    // Send the file to the server
    //https://www.mypropertypal.in/administrator/updateagentpic

    fetch(
      `https://www.mypropertypal.in/administrator/updateuserpic/${userid}`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        // Check if response has content type JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          // Response contains JSON data, parse it
          return response.json();
        } else {
          // Response is not JSON, return a placeholder response
          return { success: true, message: "Upload successful." };
        }
      })
      .then((data) => {
        console.log("Upload successful:", data);
        // Handle success, if needed
      })
      .catch((error) => {
        console.error("Upload error:", error);
        // Handle error, if needed
      });
      
  };

  return (
    <div className="wrapper-2 style-1">
      <h1>Profile</h1>
      <div className="personalinfo">
        <div className="pi_container">


          {/* <div className="pi_profile-section">
            <img alt="Profile" className="pi_profile-img" src={profile} />
            <div className="pi_edit-section">
              <img src={cam} />
            </div>
          </div> */}

<div className="pi_profile-section">
          <img
            alt="Profile"
            className="pi_profile-img"
            src={
              `https://www.mypropertypal.in/administrator/uploads/${formData.userProfile}` ||
              "https://www.mypropertypal.in/administrator/uploads/noname.png"
            }
           
          />
          
          <div className="pi_edit-section">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-upload"
            />
            <label htmlFor="file-upload">
              <img src={cam} alt="Upload" />
            </label>
          </div>
        </div>




          <form onSubmit={handleSubmit}>

          <div className="new-form-sec-main">
              <div className="new-form-sec-1 w-48">

              <input
                type="text"
                placeholder="First Name"
                name="userFname"
                value={formData.userFname}
                onChange={handleChange}
              />
              </div>
              <div className="new-form-sec-1 w-48">
              <input
                type="text"
                placeholder="Last Name"
                name="userLname"
                value={formData.userLname}
                onChange={handleChange}
              />

              </div>
              <div className="new-form-sec-1 w-48">
              <input
                type="email"
                placeholder="Email Address"
                name="userEmail"
                value={formData.userEmail}
                onChange={handleChange}
              />

              </div>
              <div className="new-form-sec-1 w-48">
              <input
                type="tel"
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              </div>
              <div className="new-form-sec-1">
                <hr></hr>
                <h2>Address</h2>
                <textarea
              type="text"
              placeholder="Address"
              name="userAddress"
              value={formData.userAddress}
              onChange={handleChange}
              
            />

              </div>
              <div className="new-form-sec-1 w-32">
              <input
              type="text"
              placeholder="State"
              name="userState"
              value={formData.userState}
              onChange={handleChange}
            />
              </div>
              <div className="new-form-sec-1 w-32">
              <input
              type="text"
              placeholder="City"
              name="userCity"
              value={formData.userCity}
              onChange={handleChange}
            />
              </div>
              <div className="new-form-sec-1 w-32">
              <input
            type="number"
            placeholder="PinCode"
            name="userPincode"
            value={formData.userPincode}
            onChange={handleChange}
          />

              </div>
              <div className="new-form-sec-1">
              <hr></hr>
              <h2>Confirm Password </h2>

              </div>
              <div className="new-form-sec-1 w-48"> <input
                type="password"
                placeholder="Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              /></div>
              <div className="new-form-sec-1 w-48">
              <input
                type="password"
                placeholder="Confirm Password"
                name="userPassword"
                value={formData.userPassword}
                onChange={handleChange}
              />
              </div>
              {/* <div className="new-form-sec-1 w-48"></div> */}
           </div>
           
           
           
           <hr></hr>
            <div className="save-btn-2">
            {formData.passwordError && (
                <p className="passowrd-error-message">{formData.passwordError}</p>
              )}
              <div className="clear"></div>
              <button className="find_button" type="submit">
              Save
            </button>
            </div>


            
           
            
          </form>
        </div>
      </div>
    </div>
  );
}
