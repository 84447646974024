// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import Agent_Login from "./Agent_Login";
import Agent_forgot from "./Agent_forgot.js";
import Sign_Up from "./Sign_Up";
import Category_agents from "./category_agents";
import Sidebar from "./Sidebar";
import "./App.css";
import User_Sidebar from "./user/User_sidebar.js";
import Dash from "./dash";
import About from "./About_Us";
import Contact from "./Contact_Us";
import Find_Agnets from "./Find_Agents";
import Booking from "./booking";
import MainCOntent from "./MainContent";
import About_Us from "./About_Us";
import Footer from "./footer";
import Blog from "./Blog.js";
import User_signup from "./user/user_signup.js";
import User_login from "./user/user_login.js";
import User_forgot from "./user/User_forgot.js";
import Location_agent from "./location_agent.js";
import Thankyou from "./Thankyou.jsx";
//import VideosMain from "./VideosMain.jsx";
import VideoNew from "./VideoNew.jsx";
import TermsandConditons from "./terms_conditions.js";
import PrivacyPolicy from "./privacy_policy.js";
import Disclamer from "./Disclamer.js";
import Cities2 from "./cities2.js";
import { MeetingDetailsScreen } from "./components/MeetingDetailsScreen.js";

const App = () => {
  const Dashboard = () => (
    <div>
      <p style={{ textAlign: "center" }}>Dashboard Content</p>
    </div>
  );
  const Appointments = () => <div>Appointments Content</div>;
  const Availability = () => <div>Availability Content</div>;
  const [isloggedin, setLoggedIn] = useState(
    sessionStorage.getItem("isloggedin") ?? "false"
  );
  const [isloggedout, setloggedout] = useState(
    sessionStorage.getItem("isloggedout")
  );
  const [showsidebar, setshowsidebar] = useState(
    sessionStorage.getItem("showsidebar")
  );
  const [showheader, setshowheader] = useState(!showsidebar);
  const [categories, setcategories] = useState([]);

  console.log("fskjdhk", isloggedin);
  console.log("isloggedout", isloggedout);
  const sh = sessionStorage.getItem("showsidebar");
  console.log(FormData);

  useEffect(() => {
    console.log("on fetch data");

    const fetchcategories = async () => {
      try {
        const response = await fetch(
          "https://www.mypropertypal.in/administrator/listcategory/"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData.categories, "jsondatacategories");
        setcategories(jsonData.categories);
      } catch (error) {
        console.error("Error fetching data category:", error);
      }
    };

    fetchcategories();

    console.log(categories, "categories");
  }, []);

  useEffect(() => {
    if (isloggedout === "true") {
      setLoggedIn("false");
      sessionStorage.setItem("isloggedout", "false");
      setshowsidebar("false");
      console.log("logged our changed");
      sessionStorage.setItem("showsidebar", "false");
      setshowsidebar("false");
    }
  }, [isloggedout]);
  useEffect(() => {
    if (isloggedin === "true") setloggedout("false");
    if (isloggedin === "false") setloggedout("true");
  }, [isloggedin]);

  console.log("from app.js logged in", isloggedin);
  console.log("from app.js shoesidebar", sessionStorage.getItem("showsidebar"));
  console.log("from app.js shoesidebar", showsidebar);
  console.log("from app.js shoesidebar", sessionStorage.getItem("showsidebar"));
  console.log("from app.js shoesidebar", sh);
  console.log(sessionStorage.getItem("showsidebar") === "true");
  return (
    <Router>
      <div>
        <div>
          {/* {!(showsidebar==='true') ?(
   <Header  isLoggedIn={isloggedin} prop_sidebar={setshowsidebar} />
                ):(null)
                }
              */}
          {categories && (
            <Header
              isLoggedIn={isloggedin}
              prop_sidebar={setshowsidebar}
              jsonData={categories}
            />
          )}
          {/* {sh === "true" ? (
            // <Sidebar prop_logout={setloggedout} />
            <User_Sidebar prop_logout={setloggedout} />
          ) : null} */}

          {sh === "true" ? (
            sessionStorage.getItem("agentID") ? (
              <Sidebar prop_logout={setloggedout} />
            ) : (
              <User_Sidebar prop_logout={setloggedout} />
            )
          ) : null}

          <Routes>
            <Route path="/VideoNew" element={<VideoNew />} />
            <Route path="/about" element={<About_Us />} />
            <Route path="/" exact element={<Home jsonData={categories} />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/find_agents"
              element={<Find_Agnets jsonData={categories} />}
            />
            <Route path="/blog" element={<Blog />} />
            <Route
              path="/agent_login"
              element={<Agent_Login isloggedIn={setLoggedIn} />}
            />
            <Route path="/Sign_Up" element={<Sign_Up />} />
            <Route path="/test" element={<Dash />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/category_agent" element={<Category_agents />} />{" "}
            {/* removed for home page serch check with shyam*/}
            <Route path="/User_sidebar" element={<User_Sidebar />} />
            <Route
              path="/user_login"
              element={<User_login isloggedIn={setLoggedIn} />}
            />
            <Route path="/Agent_forgot" element={<Agent_forgot />} />
            <Route path="/user_forgot" element={<User_forgot />} />
            <Route path="/user_signup" element={<User_signup />} />
            <Route path="/location_agent" element={<Location_agent />} />
            <Route path="/category/:categoryId" element={<Category_agents />} />
            <Route path="/Thankyou" element={<Thankyou />} />
            <Route path = "/terms_&_conditons" element = {<TermsandConditons/>}/>
            <Route path = "/privacy_&_policy" element = {<PrivacyPolicy/>}/>
            <Route path = "/disclamer" element = {<Disclamer/>}/>
            <Route path = "/Cities2" element = {<Cities2/>}/>
            <Route path="/meeting" element={<MeetingPage />} />
             {/* <Route path="/dashboard" exact element={<Dashboard />} />
                    <Route path="/appointments" element={<Appointments  />} />
                    <Route path="/avaliability" element={<Availability />} /> */}
          </Routes>
          {/* <VideoNew /> */}
          <Footer prop_sidebar={setshowsidebar} categories={categories} />
        </div>
      </div>
    </Router>
  );
};

const MeetingPage = () => {
  const query = new URLSearchParams(window.location.search);
  const meetingId = query.get('meetingId');

  return (
    <div>
      <h1>Meeting ID: {meetingId}</h1>
      <VideoNew />
    </div>
  );
};

export default App;
