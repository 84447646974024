import React, { useState } from "react";
import "./Sign_Up.css";
import Image2 from "./img/Image2.png";
import TermsPopup from "./TermsPopup.js";
import { parseJSON } from "date-fns";


import { NavLink , useNavigate } from 'react-router-dom';
import { getAllDatesInRange } from "react-multi-date-picker";


const Header = () => {
  const navigate = useNavigate();
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [emailAddress, setEmailAddress] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [reraId, setReraId] = useState("");
  // const [associationCertificate, setAssociationCertificate] = useState();
  // const [reracertificate, setreracertificate] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState();
  const [associationMember, setAssociationMember] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  //const [associationMember, setAssociationMember] = useState("");
  const [formData , setFormData] = useState({
    firstName : '', 
    lastName : '', 
    emailAddress : '',
    phoneNumber : '',
    reraId : '', 
    associationCertificate : null,
    reracertificate : null,
    password : '',
    confirmPassword : '',
    associationMember : '',
    selectType : '',
    companyName : '',
  })

  const validateForm = () => {
    const errors = {};

    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    // if (!formData.lastName.trim()) {
    //   errors.lastName = 'Last Name is required';
    // }

    if (!formData.emailAddress.trim()) {
      errors.emailAddress = 'Email Address is required';
    }

    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required';
    }

    if (!formData.reraId.trim()) {
      errors.reraId = 'RERA ID is required';
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
    }
    if (!formData.confirmPassword.trim()) {
      errors.confirmPassword = 'Confirm Password is required';
    }
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    if (formData.selectType === ""){
      errors.selectType = 'Please select any option';
    }
    if (formData.companyName.trim()){
      errors.companyName = "Company name is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value,
    });
};

  // const handleSignUp = () => {
  //   if (validateForm() && agreeTerms) {
  //     const formData = {
  //       firstname: firstName,
  //       lastname: lastName,
  //       email: emailAddress,
  //       phonenumber: phoneNumber,
  //       reraId: reraId,
  //       associationmember: associationMember,
  //       reracertificate: reracertificate,
  //       associationcertificate: associationCertificate,
  //       password: password,
  //     };
  //     console.log(formData);
  //     fetch("https://www.psmprojects.net/mypropertypal/Home/postsignup", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("Server response:", data);
  //         // Handle the response as needed
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //     // console.log('Form submitted:', formData);
  //   } else {
  //     alert("Please fill all");
  //   }
  // };


//   const handleSignUp = async(e) => {
//     e.preventDefault()
//     if (validateForm() && agreeTerms) {
//       const formData = {
//         'firstName': firstName,
//         'lastName': lastName,
//         'emailAddress': emailAddress,
//         'phoneNumber': phoneNumber,
//         'reraId':reraId,
//         'associationMember':associationMember,
//         'reracertificate': reracertificate,
//         'associationCertificate': associationCertificate,
//         'password': password,
//       };
 
//       console.log(formData)
 
//       // console.log(formData);
//       // fetch('https://www.psmprojects.net/mypropertypal/postsignup',
//       //  {
//       //   method: 'POST',
//       //   headers: {
//       //     'Content-Type': 'application/json',
 
//       //   },
//       //   body:JSON.stringify(formData),
 
//       // })
//       //   .then((response) => response.json())
//       //   .then((data) => {
//       //     console.log('Server response:', data);
//       //     // Handle the response as needed
//       //   })
//       //   .catch((error) => {
//       //     console.error('Error:', error);
//       //   });
//       const apiUrl =
//  'https://www.psmprojects.net/mypropertypal/postsignup';
//       const option = {
//         method : "POST",
//         headers: {
//           'Content-Type': 'application/json'
//       },
//         body : JSON.stringify(formData),
//       }
//       const response = await fetch(apiUrl , option);
//       try{
//         if (response.ok === true){
//           const data = await response.json();
//           console.log(data)
//           if (data){
//             console.log("New Agent Added Sucessfully!!");
//             alert("New Agent Added Sucessfully!!  ")
//           }
//           else{
//             console.log("Agent Added Unsucessfully")
//           }
//         }
//       }
//       catch(error){
//        console.log("Error:", error)
//       }
//     }
//     // Add closing bracket here
//  };

const handleReraFileChange = (e) => {
  setFormData({
    ...formData,
    reracertificate : e.target.files[0]
  });
};

const handleAssociationFileChange = (e) => {
  setFormData({
    ...formData,
    associationCertificate : e.target.files[0]
  });
};


const handleSignUp = async(e) => {
  e.preventDefault();
  //alert("hello")
  if (validateForm() && agreeTerms){
   // alert("hi")
    const newagentsignup  = new FormData();
    newagentsignup.append('firstName' , formData.firstName);
    newagentsignup.append('lastName' , formData.lastName);
    newagentsignup.append('emailAddress' , formData.emailAddress);
    newagentsignup.append('phoneNumber' , formData.phoneNumber);
    newagentsignup.append('reraId' , formData.reraId);
    newagentsignup.append('associationCertificate' , formData.associationCertificate);
    newagentsignup.append('reracertificate' , formData.reracertificate);
    newagentsignup.append('associationMember' , formData.associationMember);
    newagentsignup.append('password' , formData.password);
    newagentsignup.append('selectType' , formData.selectType);
   // newagentsignup.append('companyName' , formData.companyName);
    // newagentsignup.append('selectType' , formData.selectType);

    // const  {
    //   firstName , lastName , emailAddress , phoneNumber , reraId , associationCertificate , reracertificate , associationMember , password
    // } = formData

    // const newagentsignup = {
    //   firstName , lastName , emailAddress , phoneNumber , reraId , associationCertificate , reracertificate , associationMember , password
    // }
    //alert(newagentsignup.firstName)
  console.log(newagentsignup)
    
  try {
    const response = await fetch('https://www.mypropertypal.in/administrator/postsignup', {
      method: 'POST',
      body : newagentsignup,
    });
    
  
  if (response.ok){
    const data = await response.json();
    if (data.message){
      alert('User already exists');
    }
    else{
     // alert("hhhhhhhhh")
      navigate("/agent_login")
    }
  }
  else {
    throw new Error('Failed to sign up');
  }
  } catch (error) {
    console.error('Error:', error);
  }
  }

  
  
  setFormData({
    firstName : '', 
    lastName : '', 
    emailAddress : '',
    phoneNumber : '',
    reraId : '', 
    associationCertificate : null,
    reracertificate : null,
    password : '',
    confirmPassword : '',
    associationMember : '',
    selectType : '',
    companyName : '',
  })
  setAgreeTerms(false);
  
 //alert('/profile');
 
 //
}


  // const handleRemoveReraFile = () => {
  //   setreracertificate(null);
  // };

  // const handleRemoveAssociationFile = () => {
  //   setAssociationCertificate(null);
  // };
// onchange for removing file 
const handleRemoveFile = (name) => {
  if (name === 'reracertificate') {
    setFormData({
      ...formData,
      [name]: null,
    });
  } else if (name === 'associationcertificate') {
    setFormData({
      ...formData,
      [name]: null,
    });
  } else {
    setFormData({
      ...formData,
      [name]: '',
    });
  }
};

  const handleTermsClick = () => {
    setShowTermsPopup(true);
  };

  const closeTermsPopup = () => {
    setShowTermsPopup(false);
  };

  const handleCheckboxChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  return (
    <div
      className="Agent_signup"
      style={{
        backgroundImage: `url(${Image2})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    ><div >
       
      <form className="userlogin_form">
      <h1>Consultant Signup</h1>
        {showTermsPopup && <TermsPopup onClose={closeTermsPopup} />}

        <div style={{width: '100%'}}>

          
      <select
        id="selectType"
        className="input-field-2"
        value={formData.selectType}
        onChange={(e) => setFormData({...formData , selectType : e.target.value})}
      >
        <option value="" disabled hidden>Select Type</option>
        <option value="1">Individual</option>
        <option value="2">Company</option>
      </select>

      {formErrors.selectType && (
            <p className="error-message">{formErrors.selectType}</p>
          )}
    </div>

       {formData.selectType === "1" ? (
        <>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="input-field-2"
          />
          {formErrors.firstName && (
            <p className="error-message">{formErrors.firstName}</p>
          )}

          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="input-field-2"
          />
          {formErrors.lastName && (
            <p className="error-message">{formErrors.lastName}</p>
          )}
        </>
      ) : formData.selectType === "2" ? (
       <>
        <input
            type="text"
            placeholder="Company Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="input-field-2"
          />
        {formErrors.companyName && (
          <p className="error-message">{formErrors.companyName}</p>
        )}
        <input
            type="hidden"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="input-field-2"
          />
       </>
      ) : null}

        

        {/* <input
          type="text"
          placeholder="First Name"
          name='firstName' 
          value={formData.firstName} 
          onChange={handleChange} 
          className="input-field-2"
        />
        {formErrors.firstName && (
          <p className="error-message">{formErrors.firstName}</p>
        )}

        <input
          type="text"
          placeholder="Last Name"
          name='lastName' 
          value={formData.lastName} 
          onChange={handleChange}
          className="input-field-2"
        />
        {formErrors.lastName && (
          <p className="error-message">{formErrors.lastName}</p>
        )} */}

        <input
          type="email"
          placeholder="Email Address"
          name='emailAddress' 
          value={formData.emailAddress} 
          onChange={handleChange}
          className="input-field-2"
        />
        {formErrors.emailAddress && (
          <p className="error-message">{formErrors.emailAddress}</p>
        )}

        <input
          type="number"
          placeholder="Phone Number"
          value={formData.phoneNumber} 
          onChange={handleChange}
          name='phoneNumber'
          className="input-field-2"
        />
        {formErrors.phoneNumber && (
          <p className="error-message">{formErrors.phoneNumber}</p>
        )}

        <div className="file_upload">
          <input
            type="text"
            style={{ width: "70%" }}
            placeholder="RERA ID"
            name='reraId' 
            value={formData.reraId} 
            onChange={handleChange}
            className="input-field-2"
          />

          <label
            style={{
              textAlign: "center",
              marginLeft: "1%",
              border: "2px solid #6083F7",
              height: "15px",
              width: "29%",
              borderRadius: "0.5vw",
              fontSize: "0.7vw",
              padding: "18px 10px",
              overflow: "hidden",
              lineHeight: "6px",
              margin: "auto",
            }}
          >
            Upload RERA Certificate
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleReraFileChange}
              style={{ display: "none" }}
            />
          </label>
        </div>
        {formData.reracertificate && (
          <div
            style={{
              fontSize: "14px",
              
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <p>{formData.reracertificate.name}</p>
            <button
            onClick={handleRemoveFile}
              style={{
                padding: "1.5%",
                border: "none",
                borderRadius: "10px",
                backgroundColor: "#FFD9D8",
              }}
            >
              Remove
            </button>
          </div>
        )}
        {formErrors.reraId && (
          <p className="error-message">{formErrors.reraId}</p>
        )}
        <div className="file_upload">
          <input
            type="text"
            style={{ width: "70%" }}
            placeholder="Association Member"
            value={associationMember}
            onChange={(e) => setAssociationMember(e.target.value)}
            className="input-field-2"
          />

          <label
            style={{
              textAlign: "center",
              marginLeft: "1%",
              border: "2px solid #6083F7",
              height: "15px",
              width: "29%",
              borderRadius: "0.5vw",
              fontSize: "0.7vw",
              padding: "18px 10px",
              overflow: "hidden",
              lineHeight: "6px",
              margin: "auto",
            }}
          >
            Upload Certificate
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleAssociationFileChange}
              style={{ display: "none" }}
            />
          </label>
        </div>
        {formData.associationCertificate && (
          <div
            style={{
              fontSize: "14px",
              
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <p>{formData.associationCertificate.name}</p>
            <button
            onClick={handleRemoveFile}
              style={{
                padding: "1.5%",
                border: "none",
                borderRadius: "10px",
                backgroundColor: "#FFD9D8",
              }}
            >
              Remove
            </button>
          </div>
        )}

        <input
          type="password"
          placeholder="Password"
          name='password' 
          value={formData.password} 
          onChange={handleChange}
          className="input-field-2"
          required
        />
        {formErrors.password && (
          <p className="error-message">{formErrors.password}</p>
        )}

        <input
          type="password"
          placeholder="Confirm Password"
          name='confirmPassword' 
          value={formData.confirmPassword} 
          onChange={handleChange}
          className="input-field-2"
        />
        {formErrors.confirmPassword && (
          <p className="error-message">{formErrors.confirmPassword}</p>
        )}

        <div
          style={{
            textAlign: "left",
            width: "100%",
            padding: "10px 0px 0px 0px",
          }}
        >
          <input
            type="checkbox"
            id="agreeTerms"
            checked={agreeTerms}
            onChange={handleCheckboxChange}
            required
          />
          <label htmlFor="agreeTerms">
            I agree to the{" "}
            <span
              onClick={handleTermsClick}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                color: "#6083F7",
              }}
            >
              Terms and Conditions
            </span>
          </label>
        </div>

        <button
          onClick={handleSignUp}
          className="find_button"
          style={{ width: "100%", marginTop: "30px", marginBottom: "20px" }}
        >
          Sign Up
        </button>

        <p>
          Already a member yet?{" "}
          <NavLink to="/agent_login" className="signup-link">
            Login Now
          </NavLink>
        </p>
      </form></div>
    </div>
  );
};

export default Header;