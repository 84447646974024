// Contact.js
import React from 'react';
import {useState,useEffect} from 'react'
import './category_agents.css'
import bgdiv from './img/bgdiv.png'
import agent1 from './img/agent1.png'
import agent2 from './img/agent2.png'
import agent3 from './img/agent3.png'
import agent4 from './img/agent4.png'
import agent5 from './img/agent5.png'
import works1 from'./img/works1.png'
import works2 from './img/works2.png'
import { Link } from 'react-router-dom';
import ruppe from './img/ruppe.png'
import { useLocation } from 'react-router-dom';

const Location_agent = () => {
  const[type,setType]=useState('')
  const location = useLocation();
  var type1 = location.state?.consultationType;
  var city = location.state?.city;
  console.log(location)
  const [agentsdata,setagentsdata]=useState([]);
  console.log(type1,'type')
  useEffect(() => {
    console.log("on fetch data")
    const post={
        "cityname":city,
        "consid":type1
        }
    const fetchData = async () => {
     
      try {
        const response = await fetch('https://www.mypropertypal.in/administrator/findagents', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(post),
      });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        console.log(jsonData,'jsondata_location_agents')
        if(jsonData.length>0)
        setagentsdata(jsonData)
      
      } catch (error) {
        console.error('Error fetching data:', error);
        
      }
    };
    
   

    fetchData();
 
  }, [type1]);
  console.log(agentsdata)
    const data = [
        { id: 1, imageUrl: agent5, word1: 'Word1A', word2: 'Word1B' },
        { id: 2, imageUrl: agent4, word1: 'Word2A', word2: 'Word2B' },
        { id: 3, imageUrl: agent3, word1: 'Word3A', word2: 'Word3B' },
        { id: 4, imageUrl: agent2, word1: 'Word4A', word2: 'Word4B' },
        { id: 5, imageUrl: agent1, word1: 'Word5A', word2: 'Word5B' },
        { id: 6, imageUrl: agent5, word1: 'Word1A', word2: 'Word1B' },
        { id: 7, imageUrl: agent4, word1: 'Word2A', word2: 'Word2B' },
        { id: 8, imageUrl: agent3, word1: 'Word3A', word2: 'Word3B' },
        { id: 9, imageUrl: agent2, word1: 'Word4A', word2: 'Word4B' },
        { id: 10, imageUrl: agent1, word1: 'Word5A', word2: 'Word5B' },
      ];
      const works = [
        {
          id: 1,
          imageSrc: works1,
          matter: {
            heading: 'Discover Your Real Estate Path',
            subheading: 'Explore a range of advisory services tailored to your unique real estate needs. Sign up effortlessly to unlock exclusive access to personalized advice. Whether you’re buying, selling, investing, or seeking legal insights, MypropertyPal guides you every step of the way.',
          },
        },
        {
          id: 2,
          imageSrc: works2,
          matter: {
            heading: 'Discover Your Real Estate Path',
            subheading: 'Explore a range of advisory services tailored to your unique real estate needs. Sign up effortlessly to unlock exclusive access to personalized advice. Whether you’re buying, selling, investing, or seeking legal insights, MypropertyPal guides you every step of the way.',
          },
        },
        {
          id: 3,
          imageSrc: works1,
          matter: {
            heading: 'Discover Your Real Estate Path',
            subheading: 'Explore a range of advisory services tailored to your unique real estate needs. Sign up effortlessly to unlock exclusive access to personalized advice. Whether you’re buying, selling, investing, or seeking legal insights, MypropertyPal guides you every step of the way.',
          },
        },
        // Add more data as needed
      ];
      
    return (
        <div className='parent'>

<div className='agents_grid' style={{   backgroundImage: `url(${bgdiv})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', }}>
          <div className='wrapper-1'>
          <h2>Consult our Consultants  in  <span style={{ color: '#6083F7' }}>{city} </span> city</h2>

          {agentsdata.length === 0 ? (
    <p style={{display:'flex',alignContent:'center',justifyContent:'center'}}className="agents_heading">Loading ....</p>
  ) : 
<div className="top-agents-p-main">
            {agentsdata.map((item) => (
              <div key={item.agentId} className="top-agents-p-1">
                <div className="profile-img-1-1">
                <img src={`https://www.mypropertypal.in/administrator/uploads/${item.profilePic}`} alt={`Image ${item.id}`} className="img" />
                  </div>
                <h4>{item.agentfName} {item.agentlName}</h4>
          <h6>Gold Member</h6>
          <h5>{item.cityname}</h5>
          <h5 style={{color:'#6083F7'}}>{item.areaPreference}</h5>
          <p><img src={ruppe} /> {item.consultingFee}</p>
          {/* <p className='agent_consult' onClick={() => navigate(`/booking/${item.word1}`)}>Consult Now</p> */}
          <Link to= '/booking' state= {{ agent:item.agentId,fee:item.consultingFee,categoryid:type1 }} >
            <button>Consult Now</button>
          </Link>
              </div>
            ))}
          </div>
}
          </div>
   


          
{/* <div className="grid-container">
  {agentsdata.length === 0 ? (
    <p style={{display:'flex',alignContent:'center',justifyContent:'center'}}className="agents_heading">Loading ....</p>
  ) : (
    agentsdata.map((item) => (
      <div key={item.agentId} className="grid-item1">
        <div className="image-container">
          <img src={`https://www.mypropertypal.in/administrator/uploads/${item.profilePic}`} alt={`Image ${item.id}`} className="img" />
        </div>
        <div className="word-container">
          <p className="agent_name">{item.agentfName} {item.agentlName}</p>
          <p className="agent_member">Gold Member</p>
          <p className="agent_price">{item.cityname}</p>
          <p className="agent_member" style={{color:'#6083F7'}}>{item.areaPreference}</p>
          <p className='agent_price'><img src={ruppe} alt='ruppe'/> {item.consultingFee}</p>
         
          <Link to= '/booking' state= {{ agent:item.agentId,fee:item.consultingFee,categoryid:type1 }} >
            <p className='agent_consult'>Consult Now</p>
          </Link>
        </div>
      </div>
    ))
  )}
</div> */}
</div>
{/* agents grid */}
<div className='custom-line'>
  
   </div>
{/* <div className='worksdiv'>
<div className='works_heading'>
    <p className='works_heading1'>How it works</p>
</div>
<div className="works_grid-container">
      {works.map((item, index) => (
        <div className='works_grid-item'  key={item.id}>
            <div className='works_img_container'>
                
          <img  className='works_img' src={item.imageSrc} alt={`works${item.id}`} />
          </div>
          <div className="works_matter">
            <p className='works_topic'>{item.matter.heading}</p>
            <p className='works_info'>{item.matter.subheading}</p>
          </div>
        </div>
      ))}
    </div>
</div>
works div */}
        </div>
        // parents div
    );
};

export default Location_agent;
